.mainThemeDependentCss() {
	@fontSizeN: @fontSize;
	@bg: @background;
	@col: @color;
	@fontSizeSmaller: (@fontSize - 4px);
	@fontSizeBigger: (@fontSize + 6px);
	@fontSizeLarge: (@fontSize + 10px);
	@logo: @logoUrl;
	.fixed_menu_wrapper {
		.js-login-screen, .js-become-member {
			display: none;
		}
	}
	#special_version_controls {
		border: 1px solid @color;
		background: @background;
		margin-bottom: 6px;
		border-radius: 0 0 4px 4px;
		border-top: 0;
		width: 90%;
		max-width: 1200px;
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		z-index: 99999;
		padding: 20px;
		text-align: center;
		display: block;
	}
	.special_version_controls_group {
		display: inline-block;
		margin-right: 40px;
		margin-bottom: 30px;
	}
	.special_version_controls_label {
		display: inline-block;
		vertical-align: top;
		padding-top: 6px;
		font-size: 20px;
	}
	.special_version_controls_select {
		display: inline-block;
		vertical-align: top;
		margin-left: 10px;
	}
	.special_version_controls_point {
		display: block;
		vertical-align: top;
		position: relative;
		background: #fff;
		border: 1px solid #000;
		margin-right: -1px;
		cursor: pointer;
		height: 41px;
		width: 41px;
		text-align: center;
		line-height: 1;
		padding: 10px 12px 6px;
		font-size: 20px;
		float: left;
	}
	.special_version_controls_point:first-child {
		border-radius: 4px 0 0 4px;
	}
	.special_version_controls_point:last-child {
		border-radius: 0 4px 4px 0;
	}
	.special_version_controls_point.active:before {
		content: ' ';
		display: block;
		position: absolute;
		width: 100%;
		height: 1px;
		border-bottom: 2px dashed @color;
		left: 0;
		bottom: -7px;
	}
	.special_version_controls_select[data-parameter="size"] {
		.special_version_controls_point {
			color: @color;
			background-color: @background;
		}
		.special_version_controls_point[data-value="medium"] {
			font-size: 30px;
			padding: 4px 0 0;
		}
		.special_version_controls_point[data-value="large"] {
			font-size: 40px;
			padding: 0;
		}
	}
	.special_version_controls_select[data-parameter="color"] {
		.special_version_controls_point[data-value="blackonwhite"] {
			color: #000;
			background: #fff;
		}
		.special_version_controls_point[data-value="whiteonblack"] {
			color: #fff;
			background: #000;
		}
		.special_version_controls_point[data-value="blue"] {
			background: #9dd1ff;
			color: #195183;
		}
	}
	.special_version_controls_select[data-parameter="graphics"] {
		.special_version_controls_point[data-value="normal"] {
			background: #0000f3;
			color: #fff;
		}
		.special_version_controls_point[data-value="mono"] {
			background: #ddd;
		}
		.special_version_controls_point[data-value="disabled"] {
			color: #fff;
		}
	}
	a.menu__item.link.link_blue:first-of-type {
		margin-right: 0;
	}
	.header__menu .menu__item {
		margin-right: 25px;
	}

	//Body styles
	font-size: @fontSizeN;
	color: @col;
	background-color: @bg;
	.layout-wrapper {
		color: @col;
		background-color: @bg;
	}
	.infobar {
		height: auto;
		font-size: @fontSizeN;
		background: @bg;
		&:after {
			content: ' ';
			display: block;
			clear: both;
		}
	}
	.infobar__link, .infobar__link_academy {
		color: @col;
		font-size: @fontSizeSmaller;
	}
	a.infobar__link_academy:before {
		display: none;
	}
	a.menu__link {
		font-size: @fontSizeN;
		color: @col;
	}
	a {
		color: @col !important;
		text-decoration: underline !important;
	}
	.become-member {
		font-size: @fontSizeN;
	}
	#nav {
		border: none;
		margin: 40px 0;
	}
	.full-index {
		font-size: @fontSizeN;
		&:after {
			display: none;
		}
	}
	.search_switch {
		font-size: @fontSizeN;
		color: @col;
	}
	.quick-links {
		margin-left: 50px;
		width: 350px;
	}
	a.quick-links__item, a.closing-time {
		font-size: @fontSizeN;
	}
	a.closing-time:after {
		display: none;
	}
	.wisemen_block {
		display: none !important;
	}
	.vf_search_wrapper {
		width: 540px;
	}
	input#index_search, input#search {
		font-size: @fontSizeN;
	}
	.index_header {
		font-size: @fontSizeLarge;
	}
	.index_subscribe_block {
		height: auto;
		margin-bottom: 20px;
		.subscribe-brief {
			color: @col;
			font-size: @fontSizeSmaller;
			strong {
				font-size: @fontSizeN;
				color: @col;
				display: block;
				text-decoration: underline;
			}
		}
	}
	.departments_block {
		background: @bg;
		padding: 40px;
		box-shadow: none;
	}
	.departments_block .departments_block__wrapper .departments_block__subheader {
		color: @col;
		font-size: @fontSizeLarge;
	}
	.departments_block .departments_block__wrapper .departments_block__part a.departments_block__item {
		border: none;
		margin-bottom: 10px;
		span {
			color: @col;
			font-size: @fontSizeN;
			line-height: 1.35;
		}
	}
	.departments_block__decoration {
		display: none;
	}
	.fall_through_link {
		font-size: @fontSizeN;
		&:after {
			display: none;
		}
	}
	.nouvelle_desc a, .nouvelle_desc small {
		color: @col;
		font-size: @fontSizeSmaller;
	}
	.nouvelle_small_text, .nouvelle_title {
		font-size: @fontSizeN;
	}
	.index_nouvelle_main .nouvelle_title {
		font-size: @fontSizeBigger;
	}
	.index_pro_block .index_pro_content {
		background-color: @bg;
		.index_pro_content_title, .index_pro_content_text {
			font-size: @fontSizeN;
		}
		small {
			color: @col;
			font-size: @fontSizeSmaller;
		}
	}
	.index_news_wrapper {
		* {
			line-height: 1.35 !important;
		}
	}
	.collection_links a {
		font-size: @fontSizeSmaller;
	}
	.collection_info a {
		font-size: @fontSizeSmaller;
	}
	.index_collections_wrapper .collection_title {
		font-size: @fontSizeBigger;
	}
	.index_collections_wrapper .collection_about small {
		font-size: @fontSizeSmaller;
		color: @col !important;
	}
	.header__menu .menu__item {
		font-size: @fontSizeN;
	}
	.menu__item_active {
		border-bottom: 2px dashed @col;
	}
	#footer {
		color: @col;
		background: @bg;
		font-size: @fontSizeSmaller;
	}
	.footer_social_header {
		color: @col;
		font-size: @fontSizeN;
	}
	a.menu__link_footer {
		font-size: @fontSizeN;
	}
	.footer_header {
		font-size: @fontSizeN;
		color: @col;
	}
	.work_time p {
		font-size: @fontSizeSmaller;
	}
	.footer_column_left {
		max-width: 50%;
	}
	.footer_copy {
		border-top-color: @bg;
		color: @col;
		font-size: @fontSizeSmaller;
	}
	.footer_banner {
		img {
			display: none;
		}
		a {
			font-size: @fontSizeN;
		}
	}
	.events-item__image {
		background: @bg !important;
	}
	.event-type a, .events_cat a, .inner-event-type a, .new-type a {
		font-size: @fontSizeSmaller;
	}
	.events-item__place {
		font-size: @fontSizeN;
	}
	.events-item__header {
		font-size: @fontSizeBigger;
	}
	.events-item__time {
		font-size: @fontSizeLarge;
	}
	.event-type a, .events_cat a, .inner-event-type a, .new-type a,
	.events-item__place, .events-item__header, .events-item__time {
		color: @col;
	}
	.long-term-events {
		background: @bg;
	}
	.long-term-events__title, a.long-term-events__item-header {
		color: @col;
		font-size: @fontSizeN;
	}
	.event-date__day-of-the-month {
		font-size: @fontSizeBigger;
		small {
			font-size: @fontSizeN;
		}
	}
	.event-date_weekday {
		font-size: @fontSizeN;
		color: @col;
	}
	.event-date:before {
		display: none;
	}
	.btn__show-more {
		border-color: @col;
		color: @col;
		font-size: @fontSizeBigger;
	}
	.subscribe-header {
		font-size: @fontSizeBigger;
		color: @col;
	}
	.subscribe-brief {
		color: @col;
		font-size: @fontSizeN;
	}
	.subscribe_block .subscribe-btn {
		font-size: @fontSizeN;
		width: 240px;
		color: @col;
		background: transparent;
		border: 1px solid @col;
		padding: 0;
		line-height: (@fontSizeN * 2 + 5);
	}
	.events-by-date {
		* {
			line-height: 1.35 !important;
		}
	}
	.events_date_btn, .ui-selectmenu-button,
	.ui-state-focus.ui-selectmenu-button, .ui-state-hover.ui-selectmenu-button,
	label.checkbox, .ui-menu .ui-menu-item {
		font-size: @fontSizeN;
		color: @col;
		background: @bg;
	}
	.catalogue-search_background {
		background: none;
	}
	.catalogue-search_title {
		font-size: @fontSizeLarge;
		span {
			font-size: @fontSizeBigger;
		}
	}
	.catalogue_search_form {
		box-shadow: none;
		height: 65px;
		border: 2px solid @col;
		font-size: @fontSizeN;
	}
	.index_search_option, .vf_search_option {
		font-size: @fontSizeN;
	}
	.button__filled {
		border-radius: 0;
		border: 1px solid @col;
		font-size: @fontSizeN;
		background: @bg;
	}
	.catalogue-item {
		border: 2px solid @col;
		background: none !important;
	}
	.catalogue-item_title {
		font-size: @fontSizeLarge;
	}
	.catalogue-item_links a {
		font-size: @fontSizeN;
	}
	a.button {
		font-size: @fontSizeN;
	}
	.button__label {
		margin: 25px 0 0;
		box-shadow: none;
		border: 1px solid @col;
		background: none;
	}
	.about_block {
		border-color: @col;
		background: none;
	}
	.catalogue_title {
		color: @col;
		font-size: @fontSizeSmaller;
	}
	input, textarea {
		border: 2px solid @col !important;
		background: @bg !important;
		font-size: @fontSizeN !important;
		color: @col !important;
		border-radius: 0px !important;
	}
	#fixed_menu {
		background: @bg;
		box-shadow: none;
		border-bottom: 2px solid @col;
	}
	.fixed_menu_wrapper {
		background: none;
	}
	.news_block {
		box-shadow: none;
	}
	.news_about {
		padding-left: 10px;
		padding-right: 10px;
	}
	.news_tag {
		font-size: @fontSizeSmaller;
		color: @col;
	}
	.news_title {
		font-size: @fontSizeN;
	}
	.visitors_block, .info_block, .digest_block,
	.libfl_block {
		background: none;
		border: 2px solid @col;
		box-shadow: none;
	}
	.libfl_block {
		padding-left: 30px;
		padding-right: 40px;
		.libfl_block__part a:before {
			display: none;
		}
		.libfl_block__part a {
			font-size: @fontSizeBigger;
		}
		.libfl_block__description span {
			color: @col;
			font-size: @fontSizeSmaller;
		}
	}
	.info_block {
		.info_block__address {
			color: @col;
			font-size: @fontSizeLarge;
			span {
				color: @col;
				font-size: @fontSizeSmaller;
			}
		}
		a {
			font-size: @fontSizeN;
		}
		a.info_block__map {
			height: auto;
			background: none;
		}
	}
	.visitors_block {
		.visitors_block__title {
			color: @col;
			font-size: @fontSizeBigger;
			margin-bottom: 20px;
		}
		a {
			font-size: @fontSizeN;
			font-weight: normal;
		}
	}
	.digest_block {
		.digest_block__title {
			color: @col;
			font-size: @fontSizeBigger;
			margin-bottom: 20px;
		}
		a {
			font-size: @fontSizeN;
		}
	}
	#popup_wrapper {
		background: rgba(0,0,0,0.8);
	}
	.popup_box {
		background: @bg !important;
		color: @col !important;
		font-size: @fontSizeN;
		* {
			color: @col !important;
			line-height: 1.35 !important;
		}
	}
	.modal_parts {
		.modal_part {
			.modal_title {
				font-size: @fontSizeBigger;
			}
			.modal_subtitle {
				font-size: @fontSizeN;
				height: auto;
			}
		}
		ul.modal_list li, ol.modal_list li {
			font-size: @fontSizeN;
			&:before {
				color: @col;
			}
		}
	}
	.modal_cta {
		background: none;
		font-size: @fontSizeLarge;
	}
	.modal_welcome {
		span {
			font-size: @fontSizeLarge;
		}
		&:before {
			display: none;
		}
		a.btn {
			background: none;
			border-radius: 0;
			border: 2px solid @col;
			font-size: @fontSizeN;
			width: auto;
			height: auto;
			line-height: 1 !important;
		}
	}
	.modal_rules {
		a.link {
			font-size: @fontSizeSmaller
		}
	}
	#popup_reader.popup_box {
		.decoration_left, .decoration_right {
			display: none;
		}
	}
	.event-info {
		width: 600px;
		height: auto;
		background: @bg;
		color: @col;
	}
	.inner-event-type span {
		font-size: @fontSizeSmaller;
	}
	.event-info__title, .new-info__title {
		font-size: @fontSizeBigger;
	}
	.event-info__place {
		font-size: @fontSizeSmaller;
	}
	.event-info__shedule, .new-info__shedule {
		position: relative;
		width: 100%;
		bottom: auto;
		line-height: 1.35;
		margin-top: 20px;
	}
	.event-info__date {
		font-size: @fontSizeSmaller;
	}
	.event-info__time {
		font-size: @fontSizeBigger;
		color: @col;
	}
	.event-info__admission {
		width: 250px;
		* {
			color: @col;
		}
	}
	.event-info__admission--docs {
		font-size: @fontSizeSmaller;
		color: @col;
	}

	.block_wrapper {
		* {
			font-size: @fontSizeN;
			color: @col !important;
		}
	}
	.block_wrapper, .cutted_block, .image_left_block, .image_right_block, .old_block p, .text_block {
		font-size: @fontSizeN;
	}
	#news .block_wrapper:first-child .text_block {
		font-size: @fontSizeBigger;
	}
	.block_wrapper {
		h2 {
			font-size: @fontSizeLarge;
			line-height: 1.4;
		}
		h3 {
			font-size: @fontSizeBigger;
			line-height: 1.4;
		}
		h4 {
			font-size: @fontSizeN;
			line-height: 1.4;
		}
	}
	.image_block, .image_left_block, .image_right_block {
		span.set, .image_desc {
			font-size: @fontSizeSmaller;
		}
	}
	.button_link, .registration_link, .registration_link_instruction {
		font-size: @fontSizeSmaller;
		.button_with_link {
			font-size: @fontSizeSmaller;
			background: none;
			border: 2px solid @col;
			height: auto;
			line-height: 1;
			padding: 20px;
			width: auto;
		}
	}
	.registration_link {
		border-color: @col;
	}
	.list_ul {
		ul {
			font-size: @fontSizeN;
		}
	}
	.list_ol {
		ol {
			font-size: @fontSizeN;
		}
	}
	.cite_block {
		.cite {
			font-size: @fontSizeLarge;
		}
		.cite_by {
			font-size: @fontSizeN;
		}
	}
	.collections_block {
		.collection_about {
			a {
				font-size: @fontSizeBigger;
			}
		}
		.collection_title {
			font-size: @fontSizeLarge;
		}
		.collection_info {
			font-size: @fontSizeSmaller;
			a {
				font-size: @fontSizeSmaller;
			}
		}
	}
	.cutted_block {
		.cut_block_button {
			font-size: @fontSizeSmaller;
		}
	}
	.event_organizer, .event_additional_wrapper>span {
		font-size: @fontSizeSmaller;
	}
	.btn_social {
		font-size: @fontSizeSmaller;
		background-color: @bg !important;
		color: @col !important;
		border: 1px solid @col;
		height: auto;
		width: auto;
		padding: 5px 10px;
	}
	.bottom_block {
		border-color: @col;
	}
	.tagline {
		font-size: @fontSizeN;
		small {
			font-size: @fontSizeSmaller;
			color: @col;
		}
	}
	.page-views {
		font-size: @fontSizeSmaller;
		color: @col;
	}
	.article-description__header {
		font-size: @fontSizeLarge;
	}
	.facilities_banner a.facilities_banner__description {
		font-size: @fontSizeBigger;
	}
	.accordion dt a {
		font-size: @fontSizeN;
	}
	.accordion dd .dd-row .dd-head {
		color: @col;
	}
	.accordion dd .dd-row .dd-cell, .accordion dd .dd-row .dd-head {
		font-size: @fontSizeSmaller;
		color: @col;
	}
	.accordion span {
		font-size: @fontSizeSmaller;
		color: @col;
	}
	.article-nav-childs {
		border: 2px solid @col;
		background: none;
		font-size: @fontSizeSmaller;
	}
	.article-nav-childs__header {
		background: @bg;
		border-bottom: 2px solid @col;
		font-size: inherit;
		color: @col;
	}
	.inner_pages_breadcrumbs {
		font-size: @fontSizeSmaller;
		color: @col;
	}
	.contact_wrapper {
		background: none;
		border: 2px solid @col;
	}
	.contact_text_wrapper, .contact_text_wrapper {
		color: @col;
		font-size: @fontSizeN;
	}
	.contact_btn {
		background: @bg;
		color: @col !important;
		border: 1px solid @col;
		box-shadow: none;
		font-size: @fontSizeSmaller;
	}
	.note {
		font-size: @fontSizeSmaller;
	}
	.scheme_header {
		font-size: @fontSizeBigger;
		line-height: 1.4;
	}
	.section_switch_block {
		font-size: @fontSizeN;
		color: @col;
		text-decoration: underline;
	}
	.section_info_header {
		font-size: @fontSizeBigger;
	}
	.section_info_text {
		font-size: @fontSizeN;
	}
	.section_info_print {
		font-size: @fontSizeN;
	}
	.event_content, .page_content {
		box-shadow: none;
	}
	.scheme_switch_block {
		padding: 0;
	}
	a.departments__item {
		font-size: @fontSizeN;
		height: auto;
		margin-bottom: 20px;
	}
	.contact_cell span {
		font-size: @fontSizeSmaller !important;
	}
	.logo_wrapper {
		background: @logo top center no-repeat;
		img {
			opacity: 0;
			visibility: hidden;
		}
	}
	.fall_through_link {
		color: @col;
	}
	.events-filters, .events-filters__wrapper {
		background: @bg;
		border: none;
	}
	.icheckbox_minimal {
		border-color: @col;
	}
	.news_block {
		background-color: none;
	}
	.new-info {
		background: @bg;
	}
	.parking_note {
		float: none;
		margin-top: 100px;
		text-align: left;
		&:before {
			content: ' ';
			display: block;
			clear: both;
		}
	}
	.subway_label {
		background-color: @bg;
		border: 1px solid @col;
	}
	.event_content, .page_content {
		background: none;
	}
	.scheme_header, .scheme_switch_block, ol.sections_switcher li:before {
		color: @col;
	}
	.scheme_switch_block.active {
		background: @col;
		color: @bg;
	}
	.section_switch_block.active {
		background: @col;
		color: @bg;
	}
	.section_info.active {
		background: @bg;
	}
	.section_info_print {
		color: @col;
		text-decoration: underline;
	}
	.images_gallery .gallery_image {
		background: @bg;
	}
	.list_ul ul li:before {
		background: @col;
	}
	.catalogue_search_form, .vf_search_form {
		border: none;
		background: none;
	}
	.work_time a, a.work_time {
		border: none;
	}
	.long-term-events__item {
		border-color: @col;
	}
	.news_block {
		background: @bg;
		border: 1px solid @col;
		overflow: hidden;
	}
	.inner_pages_breadcrumbs {
		border-color: @col;
	}
	.contact_table .contact_row .contact_cell {
		border-color: @col;
	}
	.event_organizer:after {
		border-color: @col;
	}
	.vf_search_select {
		box-shadow: none;
		background: @bg;
		border: 1px solid @col;
		width: 300px;
	}
	.index_search_option, .vf_search_option {
		border-color: @col;
		color: @col;
	}
	.header {
		height: auto;
	}
	.index_subscribe_block .subscribe-brief {
		line-height: 1.35;
	}
	#footer {
		line-height: 1.35;
	}
	.footer_copy_text {
		line-height: 1.35;
	}
	.footer_social_wrapper .footer_list {
		line-height: 1.35;
	}
	.nouvelle_small_text {
		color: @col;
	}
	.top-banner {
		background-color: @bg;
		border: 2px solid @col;
		font-size: @fontSizeN;
	}
	.top-banner__close {
		background-size: 35px;
	}
	.flex-direction-nav a {
		width: 65px;
		height: 65px;
		background-size: 35px;
		background-color: @bg;
		border: 1px solid @col;
	}
	.flex-nav-prev {
		margin-right: 15px;
	}
	.flex-control-paging li a {
		width: 15px;
		height: 15px;
		background-color: transparent;
		border: 1px solid @col;
	}
	.flex-control-paging li a.flex-active {
		background-color: @col;
	}
	.departments_block .departments_block__wrapper .departments_block__part a.departments_block__item img {
		height: auto;
		width: 65px;
	}
	a.menu__link_footer img {
		width: 30px !important;
	}
	.social-btn_vk {
		background-size: 30px;
	}
	.social-btn_fb {
		background-size: 20px;
	}
	.social-btn_ig {
		background-size: 22px;
	}
	.social-btn_yt {
		background-size: 28px;
	}
	.pro_column {
		width: 33%;
	}
	.index_news {
		width: 65%;
	}
	.index_pro_block .index_pro_content {
		padding-left: 0;
	}
	.events-item_background .event-cycle {
		background-color: @bg;
		font-size: @fontSizeN;
	}
	.news_about .centenary_tag, .event-tag {
		color: @col;
		background-color: @bg;
		border: 1px solid @col;
		font-size: @fontSizeSmaller;
	}
	.side_social_wrapper>ul>li {
		width: 48px;
		height: 48px;
		border-color: @col;
	}
	.side_social_wrapper>ul>li>a>svg {
		fill: @col;
		width: 22px;
		height: 22px;
	}
	.partners_column a.partners_block {
		border: 2px solid @col;
		padding: 10px 15px;
	}
	.partners_column a.partners_block .partners_block_title {
		font-size: @fontSizeN;
		color: @col;
	}
	.test-wrapper {
		grid-template-columns: repeat(auto-fill,minmax(370px,1fr));
	}
	.long-term-events {
		width: 100%;
	}
	.news_wrapper.listing_block {
		display: grid;
		grid-template-columns: repeat(auto-fill,minmax(370px,1fr));
		grid-gap: 23px;
	}
	.news_block {
		float: none;
		margin: 0 !important;
		width: auto;
	}
	.news_image {
		width: 100%;
		background-position: center;
	}
	.news_title {
		line-height: 1.2;
	}
}

body.color_blackonwhite {
	.social-btn {
		filter: invert(1);
	}
	.event-info__admission--fee:after {
		filter: contrast(2);
	}
	.section_info_close, .search_switch:after, .footer_logo img,
	.fixed_logo {
		filter: grayscale(1);
	}
	.top-banner__close {
		filter: brightness(0);
	}
}
body.color_whiteonblack {
	.icheckbox_minimal.checked {
		filter: invert(1);
	}
	.event-info__admission--fee:after {
		filter: contrast(2) invert(1);
	}
	.etage_scheme_wrapper img, .info_btn {
		filter: invert(1);
	}
	.section_info_close, .search_switch:after, .footer_logo img,
	.fixed_logo {
		filter: grayscale(1) invert(1);
	}
	.top-banner__close {
		filter: brightness(0) invert(1);
	}
	.flex-direction-nav a {
		background-color: transparent !important;
		border: 1px solid #000 !important;
		filter: invert(1);
	}
}
body.color_blue {
	.menu__link_footer img {
		filter: brightness(0) invert(1);
	}
	.event-info__admission--fee:after {
		filter: brightness(0) invert(1);
	}
	.page-views:after {
		filter: brightness(0) invert(1);
	}
	.top-banner__close {
		filter: hue-rotate(155deg);
	}
}
body.size_medium, body.size_large {
	#fixed_menu, .side_social_wrapper {
		display: none;
	}
}
body.graphics_mono {
	.column_banner img, .nouvelle_main_photo, .collection_books img,
	.departments_block .departments_block__wrapper .departments_block__part a.departments_block__item img,
	.index_subscribe_block .subscribe-icon, .ui-selectmenu-button span.ui-icon, .event-flag, .btn__show-more:after, .btn__show-more:before,
	.side_social_wrapper>ul>li>a>svg, .news_image, .scheme_switch_block, .etage_scheme_wrapper,
	ul.modal_list li.noto:before, ul.modal_list li.yesto:before, .modal_welcome span:before, .gallery_image_wrapper,
	.block_wrapper img, .new-image__image-source, .cite_block:before, .image_block .fotorama__nav__frame.fotorama__active .fotorama__dot,
	.catalogue-item_collections-books img, .about_block img, .about_block svg, .collection_books_block.secondary_block .collection_book_image,
	.rounded_btn, .long-term-events__arrow_down, .long-term-events__arrow_up {
		filter: grayscale(1);
	}
}
body.graphics_disabled {
	.rounded_btn, .side_social_wrapper>ul>li>a>svg,
	.etage_scheme_wrapper {
		filter: grayscale(1);
	}
	.column_banner img, .nouvelle_main_photo, .collection_books img,
	.departments_block .departments_block__wrapper .departments_block__part a.departments_block__item img,
	.index_subscribe_block .subscribe-icon, .ui-selectmenu-button span.ui-icon, .event-flag, .btn__show-more:after, .btn__show-more:before,
	.news_image, .scheme_switch_block,
	ul.modal_list li.noto:before, ul.modal_list li.yesto:before, .modal_welcome span:before, .gallery_image_wrapper,
	.block_wrapper img, .new-image__image-source, .cite_block:before, .image_block .fotorama__nav__frame.fotorama__active .fotorama__dot,
	.catalogue-item_collections-books img, .about_block img, .about_block svg,
	.rounded_btn, .footer_logo img, .fixed_logo, .collection_books_block.secondary_block .collection_book_image,
	.long-term-events__arrow_down, .long-term-events__arrow_up {
		opacity: 0;
		visibility: hidden;
	}
}

body.size_small.color_blackonwhite {
	@fontSize: 24px;
	@color: #000;
	@background: #fff;
	@logoUrl: url('/images/svg/accent-logo-black.svg');
	.mainThemeDependentCss();
	padding-top: 120px;
}
body.size_small.color_whiteonblack {
	@fontSize: 24px;
	@color: #fff;
	@background: #000;
	@logoUrl: url('/images/svg/accent-logo-white.svg');
	.mainThemeDependentCss();
	padding-top: 120px;
}
body.size_small.color_blue {
	@fontSize: 24px;
	@color: #195183;
	@background: #9dd1ff;
	@logoUrl: url('/images/svg/accent-logo-blue.svg');
	.mainThemeDependentCss();
	padding-top: 120px;
}
body.size_medium.color_blackonwhite {
	@fontSize: 30px;
	@color: #000;
	@background: #fff;
	@logoUrl: url('/images/svg/accent-logo-black.svg');
	.mainThemeDependentCss();
	padding-top: 120px;
}
body.size_medium.color_whiteonblack {
	@fontSize: 30px;
	@color: #fff;
	@background: #000;
	@logoUrl: url('/images/svg/accent-logo-white.svg');
	.mainThemeDependentCss();
	padding-top: 120px;
}
body.size_medium.color_blue {
	@fontSize: 30px;
	@color: #195183;
	@background: #9dd1ff;
	@logoUrl: url('/images/svg/accent-logo-blue.svg');
	.mainThemeDependentCss();
	padding-top: 120px;
}
body.size_large.color_blackonwhite {
	@fontSize: 40px;
	@color: #000;
	@background: #fff;
	@logoUrl: url('/images/svg/accent-logo-black.svg');
	.mainThemeDependentCss();
	padding-top: 130px;
}
body.size_large.color_whiteonblack {
	@fontSize: 40px;
	@color: #fff;
	@background: #000;
	@logoUrl: url('/images/svg/accent-logo-white.svg');
	.mainThemeDependentCss();
	padding-top: 130px;
}
body.size_large.color_blue {
	@fontSize: 40px;
	@color: #195183;
	@background: #9dd1ff;
	@logoUrl: url('/images/svg/accent-logo-blue.svg');
	.mainThemeDependentCss();
	padding-top: 130px;
}