body.color_blackonwhite .social-btn {
  filter: invert(1);
}
body.color_blackonwhite .event-info__admission--fee:after {
  filter: contrast(2);
}
body.color_blackonwhite .section_info_close,
body.color_blackonwhite .search_switch:after,
body.color_blackonwhite .footer_logo img,
body.color_blackonwhite .fixed_logo {
  filter: grayscale(1);
}
body.color_blackonwhite .top-banner__close {
  filter: brightness(0);
}
body.color_whiteonblack .icheckbox_minimal.checked {
  filter: invert(1);
}
body.color_whiteonblack .event-info__admission--fee:after {
  filter: contrast(2) invert(1);
}
body.color_whiteonblack .etage_scheme_wrapper img,
body.color_whiteonblack .info_btn {
  filter: invert(1);
}
body.color_whiteonblack .section_info_close,
body.color_whiteonblack .search_switch:after,
body.color_whiteonblack .footer_logo img,
body.color_whiteonblack .fixed_logo {
  filter: grayscale(1) invert(1);
}
body.color_whiteonblack .top-banner__close {
  filter: brightness(0) invert(1);
}
body.color_whiteonblack .flex-direction-nav a {
  background-color: transparent !important;
  border: 1px solid #000 !important;
  filter: invert(1);
}
body.color_blue .menu__link_footer img {
  filter: brightness(0) invert(1);
}
body.color_blue .event-info__admission--fee:after {
  filter: brightness(0) invert(1);
}
body.color_blue .page-views:after {
  filter: brightness(0) invert(1);
}
body.color_blue .top-banner__close {
  filter: hue-rotate(155deg);
}
body.size_medium #fixed_menu,
body.size_large #fixed_menu,
body.size_medium .side_social_wrapper,
body.size_large .side_social_wrapper {
  display: none;
}
body.graphics_mono .column_banner img,
body.graphics_mono .nouvelle_main_photo,
body.graphics_mono .collection_books img,
body.graphics_mono .departments_block .departments_block__wrapper .departments_block__part a.departments_block__item img,
body.graphics_mono .index_subscribe_block .subscribe-icon,
body.graphics_mono .ui-selectmenu-button span.ui-icon,
body.graphics_mono .event-flag,
body.graphics_mono .btn__show-more:after,
body.graphics_mono .btn__show-more:before,
body.graphics_mono .side_social_wrapper > ul > li > a > svg,
body.graphics_mono .news_image,
body.graphics_mono .scheme_switch_block,
body.graphics_mono .etage_scheme_wrapper,
body.graphics_mono ul.modal_list li.noto:before,
body.graphics_mono ul.modal_list li.yesto:before,
body.graphics_mono .modal_welcome span:before,
body.graphics_mono .gallery_image_wrapper,
body.graphics_mono .block_wrapper img,
body.graphics_mono .new-image__image-source,
body.graphics_mono .cite_block:before,
body.graphics_mono .image_block .fotorama__nav__frame.fotorama__active .fotorama__dot,
body.graphics_mono .catalogue-item_collections-books img,
body.graphics_mono .about_block img,
body.graphics_mono .about_block svg,
body.graphics_mono .collection_books_block.secondary_block .collection_book_image,
body.graphics_mono .rounded_btn,
body.graphics_mono .long-term-events__arrow_down,
body.graphics_mono .long-term-events__arrow_up {
  filter: grayscale(1);
}
body.graphics_disabled .rounded_btn,
body.graphics_disabled .side_social_wrapper > ul > li > a > svg,
body.graphics_disabled .etage_scheme_wrapper {
  filter: grayscale(1);
}
body.graphics_disabled .column_banner img,
body.graphics_disabled .nouvelle_main_photo,
body.graphics_disabled .collection_books img,
body.graphics_disabled .departments_block .departments_block__wrapper .departments_block__part a.departments_block__item img,
body.graphics_disabled .index_subscribe_block .subscribe-icon,
body.graphics_disabled .ui-selectmenu-button span.ui-icon,
body.graphics_disabled .event-flag,
body.graphics_disabled .btn__show-more:after,
body.graphics_disabled .btn__show-more:before,
body.graphics_disabled .news_image,
body.graphics_disabled .scheme_switch_block,
body.graphics_disabled ul.modal_list li.noto:before,
body.graphics_disabled ul.modal_list li.yesto:before,
body.graphics_disabled .modal_welcome span:before,
body.graphics_disabled .gallery_image_wrapper,
body.graphics_disabled .block_wrapper img,
body.graphics_disabled .new-image__image-source,
body.graphics_disabled .cite_block:before,
body.graphics_disabled .image_block .fotorama__nav__frame.fotorama__active .fotorama__dot,
body.graphics_disabled .catalogue-item_collections-books img,
body.graphics_disabled .about_block img,
body.graphics_disabled .about_block svg,
body.graphics_disabled .rounded_btn,
body.graphics_disabled .footer_logo img,
body.graphics_disabled .fixed_logo,
body.graphics_disabled .collection_books_block.secondary_block .collection_book_image,
body.graphics_disabled .long-term-events__arrow_down,
body.graphics_disabled .long-term-events__arrow_up {
  opacity: 0;
  visibility: hidden;
}
body.size_small.color_blackonwhite {
  font-size: 24px;
  color: #000;
  background-color: #fff;
  padding-top: 120px;
}
body.size_small.color_blackonwhite .fixed_menu_wrapper .js-login-screen,
body.size_small.color_blackonwhite .fixed_menu_wrapper .js-become-member {
  display: none;
}
body.size_small.color_blackonwhite #special_version_controls {
  border: 1px solid #000;
  background: #fff;
  margin-bottom: 6px;
  border-radius: 0 0 4px 4px;
  border-top: 0;
  width: 90%;
  max-width: 1200px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99999;
  padding: 20px;
  text-align: center;
  display: block;
}
body.size_small.color_blackonwhite .special_version_controls_group {
  display: inline-block;
  margin-right: 40px;
  margin-bottom: 30px;
}
body.size_small.color_blackonwhite .special_version_controls_label {
  display: inline-block;
  vertical-align: top;
  padding-top: 6px;
  font-size: 20px;
}
body.size_small.color_blackonwhite .special_version_controls_select {
  display: inline-block;
  vertical-align: top;
  margin-left: 10px;
}
body.size_small.color_blackonwhite .special_version_controls_point {
  display: block;
  vertical-align: top;
  position: relative;
  background: #fff;
  border: 1px solid #000;
  margin-right: -1px;
  cursor: pointer;
  height: 41px;
  width: 41px;
  text-align: center;
  line-height: 1;
  padding: 10px 12px 6px;
  font-size: 20px;
  float: left;
}
body.size_small.color_blackonwhite .special_version_controls_point:first-child {
  border-radius: 4px 0 0 4px;
}
body.size_small.color_blackonwhite .special_version_controls_point:last-child {
  border-radius: 0 4px 4px 0;
}
body.size_small.color_blackonwhite .special_version_controls_point.active:before {
  content: ' ';
  display: block;
  position: absolute;
  width: 100%;
  height: 1px;
  border-bottom: 2px dashed #000;
  left: 0;
  bottom: -7px;
}
body.size_small.color_blackonwhite .special_version_controls_select[data-parameter="size"] .special_version_controls_point {
  color: #000;
  background-color: #fff;
}
body.size_small.color_blackonwhite .special_version_controls_select[data-parameter="size"] .special_version_controls_point[data-value="medium"] {
  font-size: 30px;
  padding: 4px 0 0;
}
body.size_small.color_blackonwhite .special_version_controls_select[data-parameter="size"] .special_version_controls_point[data-value="large"] {
  font-size: 40px;
  padding: 0;
}
body.size_small.color_blackonwhite .special_version_controls_select[data-parameter="color"] .special_version_controls_point[data-value="blackonwhite"] {
  color: #000;
  background: #fff;
}
body.size_small.color_blackonwhite .special_version_controls_select[data-parameter="color"] .special_version_controls_point[data-value="whiteonblack"] {
  color: #fff;
  background: #000;
}
body.size_small.color_blackonwhite .special_version_controls_select[data-parameter="color"] .special_version_controls_point[data-value="blue"] {
  background: #9dd1ff;
  color: #195183;
}
body.size_small.color_blackonwhite .special_version_controls_select[data-parameter="graphics"] .special_version_controls_point[data-value="normal"] {
  background: #0000f3;
  color: #fff;
}
body.size_small.color_blackonwhite .special_version_controls_select[data-parameter="graphics"] .special_version_controls_point[data-value="mono"] {
  background: #ddd;
}
body.size_small.color_blackonwhite .special_version_controls_select[data-parameter="graphics"] .special_version_controls_point[data-value="disabled"] {
  color: #fff;
}
body.size_small.color_blackonwhite a.menu__item.link.link_blue:first-of-type {
  margin-right: 0;
}
body.size_small.color_blackonwhite .header__menu .menu__item {
  margin-right: 25px;
}
body.size_small.color_blackonwhite .layout-wrapper {
  color: #000;
  background-color: #fff;
}
body.size_small.color_blackonwhite .infobar {
  height: auto;
  font-size: 24px;
  background: #fff;
}
body.size_small.color_blackonwhite .infobar:after {
  content: ' ';
  display: block;
  clear: both;
}
body.size_small.color_blackonwhite .infobar__link,
body.size_small.color_blackonwhite .infobar__link_academy {
  color: #000;
  font-size: 20px;
}
body.size_small.color_blackonwhite a.infobar__link_academy:before {
  display: none;
}
body.size_small.color_blackonwhite a.menu__link {
  font-size: 24px;
  color: #000;
}
body.size_small.color_blackonwhite a {
  color: #000 !important;
  text-decoration: underline !important;
}
body.size_small.color_blackonwhite .become-member {
  font-size: 24px;
}
body.size_small.color_blackonwhite #nav {
  border: none;
  margin: 40px 0;
}
body.size_small.color_blackonwhite .full-index {
  font-size: 24px;
}
body.size_small.color_blackonwhite .full-index:after {
  display: none;
}
body.size_small.color_blackonwhite .search_switch {
  font-size: 24px;
  color: #000;
}
body.size_small.color_blackonwhite .quick-links {
  margin-left: 50px;
  width: 350px;
}
body.size_small.color_blackonwhite a.quick-links__item,
body.size_small.color_blackonwhite a.closing-time {
  font-size: 24px;
}
body.size_small.color_blackonwhite a.closing-time:after {
  display: none;
}
body.size_small.color_blackonwhite .wisemen_block {
  display: none !important;
}
body.size_small.color_blackonwhite .vf_search_wrapper {
  width: 540px;
}
body.size_small.color_blackonwhite input#index_search,
body.size_small.color_blackonwhite input#search {
  font-size: 24px;
}
body.size_small.color_blackonwhite .index_header {
  font-size: 34px;
}
body.size_small.color_blackonwhite .index_subscribe_block {
  height: auto;
  margin-bottom: 20px;
}
body.size_small.color_blackonwhite .index_subscribe_block .subscribe-brief {
  color: #000;
  font-size: 20px;
}
body.size_small.color_blackonwhite .index_subscribe_block .subscribe-brief strong {
  font-size: 24px;
  color: #000;
  display: block;
  text-decoration: underline;
}
body.size_small.color_blackonwhite .departments_block {
  background: #fff;
  padding: 40px;
  box-shadow: none;
}
body.size_small.color_blackonwhite .departments_block .departments_block__wrapper .departments_block__subheader {
  color: #000;
  font-size: 34px;
}
body.size_small.color_blackonwhite .departments_block .departments_block__wrapper .departments_block__part a.departments_block__item {
  border: none;
  margin-bottom: 10px;
}
body.size_small.color_blackonwhite .departments_block .departments_block__wrapper .departments_block__part a.departments_block__item span {
  color: #000;
  font-size: 24px;
  line-height: 1.35;
}
body.size_small.color_blackonwhite .departments_block__decoration {
  display: none;
}
body.size_small.color_blackonwhite .fall_through_link {
  font-size: 24px;
}
body.size_small.color_blackonwhite .fall_through_link:after {
  display: none;
}
body.size_small.color_blackonwhite .nouvelle_desc a,
body.size_small.color_blackonwhite .nouvelle_desc small {
  color: #000;
  font-size: 20px;
}
body.size_small.color_blackonwhite .nouvelle_small_text,
body.size_small.color_blackonwhite .nouvelle_title {
  font-size: 24px;
}
body.size_small.color_blackonwhite .index_nouvelle_main .nouvelle_title {
  font-size: 30px;
}
body.size_small.color_blackonwhite .index_pro_block .index_pro_content {
  background-color: #fff;
}
body.size_small.color_blackonwhite .index_pro_block .index_pro_content .index_pro_content_title,
body.size_small.color_blackonwhite .index_pro_block .index_pro_content .index_pro_content_text {
  font-size: 24px;
}
body.size_small.color_blackonwhite .index_pro_block .index_pro_content small {
  color: #000;
  font-size: 20px;
}
body.size_small.color_blackonwhite .index_news_wrapper * {
  line-height: 1.35 !important;
}
body.size_small.color_blackonwhite .collection_links a {
  font-size: 20px;
}
body.size_small.color_blackonwhite .collection_info a {
  font-size: 20px;
}
body.size_small.color_blackonwhite .index_collections_wrapper .collection_title {
  font-size: 30px;
}
body.size_small.color_blackonwhite .index_collections_wrapper .collection_about small {
  font-size: 20px;
  color: #000 !important;
}
body.size_small.color_blackonwhite .header__menu .menu__item {
  font-size: 24px;
}
body.size_small.color_blackonwhite .menu__item_active {
  border-bottom: 2px dashed #000;
}
body.size_small.color_blackonwhite #footer {
  color: #000;
  background: #fff;
  font-size: 20px;
}
body.size_small.color_blackonwhite .footer_social_header {
  color: #000;
  font-size: 24px;
}
body.size_small.color_blackonwhite a.menu__link_footer {
  font-size: 24px;
}
body.size_small.color_blackonwhite .footer_header {
  font-size: 24px;
  color: #000;
}
body.size_small.color_blackonwhite .work_time p {
  font-size: 20px;
}
body.size_small.color_blackonwhite .footer_column_left {
  max-width: 50%;
}
body.size_small.color_blackonwhite .footer_copy {
  border-top-color: #fff;
  color: #000;
  font-size: 20px;
}
body.size_small.color_blackonwhite .footer_banner img {
  display: none;
}
body.size_small.color_blackonwhite .footer_banner a {
  font-size: 24px;
}
body.size_small.color_blackonwhite .events-item__image {
  background: #fff !important;
}
body.size_small.color_blackonwhite .event-type a,
body.size_small.color_blackonwhite .events_cat a,
body.size_small.color_blackonwhite .inner-event-type a,
body.size_small.color_blackonwhite .new-type a {
  font-size: 20px;
}
body.size_small.color_blackonwhite .events-item__place {
  font-size: 24px;
}
body.size_small.color_blackonwhite .events-item__header {
  font-size: 30px;
}
body.size_small.color_blackonwhite .events-item__time {
  font-size: 34px;
}
body.size_small.color_blackonwhite .event-type a,
body.size_small.color_blackonwhite .events_cat a,
body.size_small.color_blackonwhite .inner-event-type a,
body.size_small.color_blackonwhite .new-type a,
body.size_small.color_blackonwhite .events-item__place,
body.size_small.color_blackonwhite .events-item__header,
body.size_small.color_blackonwhite .events-item__time {
  color: #000;
}
body.size_small.color_blackonwhite .long-term-events {
  background: #fff;
}
body.size_small.color_blackonwhite .long-term-events__title,
body.size_small.color_blackonwhite a.long-term-events__item-header {
  color: #000;
  font-size: 24px;
}
body.size_small.color_blackonwhite .event-date__day-of-the-month {
  font-size: 30px;
}
body.size_small.color_blackonwhite .event-date__day-of-the-month small {
  font-size: 24px;
}
body.size_small.color_blackonwhite .event-date_weekday {
  font-size: 24px;
  color: #000;
}
body.size_small.color_blackonwhite .event-date:before {
  display: none;
}
body.size_small.color_blackonwhite .btn__show-more {
  border-color: #000;
  color: #000;
  font-size: 30px;
}
body.size_small.color_blackonwhite .subscribe-header {
  font-size: 30px;
  color: #000;
}
body.size_small.color_blackonwhite .subscribe-brief {
  color: #000;
  font-size: 24px;
}
body.size_small.color_blackonwhite .subscribe_block .subscribe-btn {
  font-size: 24px;
  width: 240px;
  color: #000;
  background: transparent;
  border: 1px solid #000;
  padding: 0;
  line-height: 53px;
}
body.size_small.color_blackonwhite .events-by-date * {
  line-height: 1.35 !important;
}
body.size_small.color_blackonwhite .events_date_btn,
body.size_small.color_blackonwhite .ui-selectmenu-button,
body.size_small.color_blackonwhite .ui-state-focus.ui-selectmenu-button,
body.size_small.color_blackonwhite .ui-state-hover.ui-selectmenu-button,
body.size_small.color_blackonwhite label.checkbox,
body.size_small.color_blackonwhite .ui-menu .ui-menu-item {
  font-size: 24px;
  color: #000;
  background: #fff;
}
body.size_small.color_blackonwhite .catalogue-search_background {
  background: none;
}
body.size_small.color_blackonwhite .catalogue-search_title {
  font-size: 34px;
}
body.size_small.color_blackonwhite .catalogue-search_title span {
  font-size: 30px;
}
body.size_small.color_blackonwhite .catalogue_search_form {
  box-shadow: none;
  height: 65px;
  border: 2px solid #000;
  font-size: 24px;
}
body.size_small.color_blackonwhite .index_search_option,
body.size_small.color_blackonwhite .vf_search_option {
  font-size: 24px;
}
body.size_small.color_blackonwhite .button__filled {
  border-radius: 0;
  border: 1px solid #000;
  font-size: 24px;
  background: #fff;
}
body.size_small.color_blackonwhite .catalogue-item {
  border: 2px solid #000;
  background: none !important;
}
body.size_small.color_blackonwhite .catalogue-item_title {
  font-size: 34px;
}
body.size_small.color_blackonwhite .catalogue-item_links a {
  font-size: 24px;
}
body.size_small.color_blackonwhite a.button {
  font-size: 24px;
}
body.size_small.color_blackonwhite .button__label {
  margin: 25px 0 0;
  box-shadow: none;
  border: 1px solid #000;
  background: none;
}
body.size_small.color_blackonwhite .about_block {
  border-color: #000;
  background: none;
}
body.size_small.color_blackonwhite .catalogue_title {
  color: #000;
  font-size: 20px;
}
body.size_small.color_blackonwhite input,
body.size_small.color_blackonwhite textarea {
  border: 2px solid #000 !important;
  background: #fff !important;
  font-size: 24px !important;
  color: #000 !important;
  border-radius: 0px !important;
}
body.size_small.color_blackonwhite #fixed_menu {
  background: #fff;
  box-shadow: none;
  border-bottom: 2px solid #000;
}
body.size_small.color_blackonwhite .fixed_menu_wrapper {
  background: none;
}
body.size_small.color_blackonwhite .news_block {
  box-shadow: none;
}
body.size_small.color_blackonwhite .news_about {
  padding-left: 10px;
  padding-right: 10px;
}
body.size_small.color_blackonwhite .news_tag {
  font-size: 20px;
  color: #000;
}
body.size_small.color_blackonwhite .news_title {
  font-size: 24px;
}
body.size_small.color_blackonwhite .visitors_block,
body.size_small.color_blackonwhite .info_block,
body.size_small.color_blackonwhite .digest_block,
body.size_small.color_blackonwhite .libfl_block {
  background: none;
  border: 2px solid #000;
  box-shadow: none;
}
body.size_small.color_blackonwhite .libfl_block {
  padding-left: 30px;
  padding-right: 40px;
}
body.size_small.color_blackonwhite .libfl_block .libfl_block__part a:before {
  display: none;
}
body.size_small.color_blackonwhite .libfl_block .libfl_block__part a {
  font-size: 30px;
}
body.size_small.color_blackonwhite .libfl_block .libfl_block__description span {
  color: #000;
  font-size: 20px;
}
body.size_small.color_blackonwhite .info_block .info_block__address {
  color: #000;
  font-size: 34px;
}
body.size_small.color_blackonwhite .info_block .info_block__address span {
  color: #000;
  font-size: 20px;
}
body.size_small.color_blackonwhite .info_block a {
  font-size: 24px;
}
body.size_small.color_blackonwhite .info_block a.info_block__map {
  height: auto;
  background: none;
}
body.size_small.color_blackonwhite .visitors_block .visitors_block__title {
  color: #000;
  font-size: 30px;
  margin-bottom: 20px;
}
body.size_small.color_blackonwhite .visitors_block a {
  font-size: 24px;
  font-weight: normal;
}
body.size_small.color_blackonwhite .digest_block .digest_block__title {
  color: #000;
  font-size: 30px;
  margin-bottom: 20px;
}
body.size_small.color_blackonwhite .digest_block a {
  font-size: 24px;
}
body.size_small.color_blackonwhite #popup_wrapper {
  background: rgba(0, 0, 0, 0.8);
}
body.size_small.color_blackonwhite .popup_box {
  background: #fff !important;
  color: #000 !important;
  font-size: 24px;
}
body.size_small.color_blackonwhite .popup_box * {
  color: #000 !important;
  line-height: 1.35 !important;
}
body.size_small.color_blackonwhite .modal_parts .modal_part .modal_title {
  font-size: 30px;
}
body.size_small.color_blackonwhite .modal_parts .modal_part .modal_subtitle {
  font-size: 24px;
  height: auto;
}
body.size_small.color_blackonwhite .modal_parts ul.modal_list li,
body.size_small.color_blackonwhite .modal_parts ol.modal_list li {
  font-size: 24px;
}
body.size_small.color_blackonwhite .modal_parts ul.modal_list li:before,
body.size_small.color_blackonwhite .modal_parts ol.modal_list li:before {
  color: #000;
}
body.size_small.color_blackonwhite .modal_cta {
  background: none;
  font-size: 34px;
}
body.size_small.color_blackonwhite .modal_welcome span {
  font-size: 34px;
}
body.size_small.color_blackonwhite .modal_welcome:before {
  display: none;
}
body.size_small.color_blackonwhite .modal_welcome a.btn {
  background: none;
  border-radius: 0;
  border: 2px solid #000;
  font-size: 24px;
  width: auto;
  height: auto;
  line-height: 1 !important;
}
body.size_small.color_blackonwhite .modal_rules a.link {
  font-size: 20px;
}
body.size_small.color_blackonwhite #popup_reader.popup_box .decoration_left,
body.size_small.color_blackonwhite #popup_reader.popup_box .decoration_right {
  display: none;
}
body.size_small.color_blackonwhite .event-info {
  width: 600px;
  height: auto;
  background: #fff;
  color: #000;
}
body.size_small.color_blackonwhite .inner-event-type span {
  font-size: 20px;
}
body.size_small.color_blackonwhite .event-info__title,
body.size_small.color_blackonwhite .new-info__title {
  font-size: 30px;
}
body.size_small.color_blackonwhite .event-info__place {
  font-size: 20px;
}
body.size_small.color_blackonwhite .event-info__shedule,
body.size_small.color_blackonwhite .new-info__shedule {
  position: relative;
  width: 100%;
  bottom: auto;
  line-height: 1.35;
  margin-top: 20px;
}
body.size_small.color_blackonwhite .event-info__date {
  font-size: 20px;
}
body.size_small.color_blackonwhite .event-info__time {
  font-size: 30px;
  color: #000;
}
body.size_small.color_blackonwhite .event-info__admission {
  width: 250px;
}
body.size_small.color_blackonwhite .event-info__admission * {
  color: #000;
}
body.size_small.color_blackonwhite .event-info__admission--docs {
  font-size: 20px;
  color: #000;
}
body.size_small.color_blackonwhite .block_wrapper * {
  font-size: 24px;
  color: #000 !important;
}
body.size_small.color_blackonwhite .block_wrapper,
body.size_small.color_blackonwhite .cutted_block,
body.size_small.color_blackonwhite .image_left_block,
body.size_small.color_blackonwhite .image_right_block,
body.size_small.color_blackonwhite .old_block p,
body.size_small.color_blackonwhite .text_block {
  font-size: 24px;
}
body.size_small.color_blackonwhite #news .block_wrapper:first-child .text_block {
  font-size: 30px;
}
body.size_small.color_blackonwhite .block_wrapper h2 {
  font-size: 34px;
  line-height: 1.4;
}
body.size_small.color_blackonwhite .block_wrapper h3 {
  font-size: 30px;
  line-height: 1.4;
}
body.size_small.color_blackonwhite .block_wrapper h4 {
  font-size: 24px;
  line-height: 1.4;
}
body.size_small.color_blackonwhite .image_block span.set,
body.size_small.color_blackonwhite .image_left_block span.set,
body.size_small.color_blackonwhite .image_right_block span.set,
body.size_small.color_blackonwhite .image_block .image_desc,
body.size_small.color_blackonwhite .image_left_block .image_desc,
body.size_small.color_blackonwhite .image_right_block .image_desc {
  font-size: 20px;
}
body.size_small.color_blackonwhite .button_link,
body.size_small.color_blackonwhite .registration_link,
body.size_small.color_blackonwhite .registration_link_instruction {
  font-size: 20px;
}
body.size_small.color_blackonwhite .button_link .button_with_link,
body.size_small.color_blackonwhite .registration_link .button_with_link,
body.size_small.color_blackonwhite .registration_link_instruction .button_with_link {
  font-size: 20px;
  background: none;
  border: 2px solid #000;
  height: auto;
  line-height: 1;
  padding: 20px;
  width: auto;
}
body.size_small.color_blackonwhite .registration_link {
  border-color: #000;
}
body.size_small.color_blackonwhite .list_ul ul {
  font-size: 24px;
}
body.size_small.color_blackonwhite .list_ol ol {
  font-size: 24px;
}
body.size_small.color_blackonwhite .cite_block .cite {
  font-size: 34px;
}
body.size_small.color_blackonwhite .cite_block .cite_by {
  font-size: 24px;
}
body.size_small.color_blackonwhite .collections_block .collection_about a {
  font-size: 30px;
}
body.size_small.color_blackonwhite .collections_block .collection_title {
  font-size: 34px;
}
body.size_small.color_blackonwhite .collections_block .collection_info {
  font-size: 20px;
}
body.size_small.color_blackonwhite .collections_block .collection_info a {
  font-size: 20px;
}
body.size_small.color_blackonwhite .cutted_block .cut_block_button {
  font-size: 20px;
}
body.size_small.color_blackonwhite .event_organizer,
body.size_small.color_blackonwhite .event_additional_wrapper > span {
  font-size: 20px;
}
body.size_small.color_blackonwhite .btn_social {
  font-size: 20px;
  background-color: #fff !important;
  color: #000 !important;
  border: 1px solid #000;
  height: auto;
  width: auto;
  padding: 5px 10px;
}
body.size_small.color_blackonwhite .bottom_block {
  border-color: #000;
}
body.size_small.color_blackonwhite .tagline {
  font-size: 24px;
}
body.size_small.color_blackonwhite .tagline small {
  font-size: 20px;
  color: #000;
}
body.size_small.color_blackonwhite .page-views {
  font-size: 20px;
  color: #000;
}
body.size_small.color_blackonwhite .article-description__header {
  font-size: 34px;
}
body.size_small.color_blackonwhite .facilities_banner a.facilities_banner__description {
  font-size: 30px;
}
body.size_small.color_blackonwhite .accordion dt a {
  font-size: 24px;
}
body.size_small.color_blackonwhite .accordion dd .dd-row .dd-head {
  color: #000;
}
body.size_small.color_blackonwhite .accordion dd .dd-row .dd-cell,
body.size_small.color_blackonwhite .accordion dd .dd-row .dd-head {
  font-size: 20px;
  color: #000;
}
body.size_small.color_blackonwhite .accordion span {
  font-size: 20px;
  color: #000;
}
body.size_small.color_blackonwhite .article-nav-childs {
  border: 2px solid #000;
  background: none;
  font-size: 20px;
}
body.size_small.color_blackonwhite .article-nav-childs__header {
  background: #fff;
  border-bottom: 2px solid #000;
  font-size: inherit;
  color: #000;
}
body.size_small.color_blackonwhite .inner_pages_breadcrumbs {
  font-size: 20px;
  color: #000;
}
body.size_small.color_blackonwhite .contact_wrapper {
  background: none;
  border: 2px solid #000;
}
body.size_small.color_blackonwhite .contact_text_wrapper,
body.size_small.color_blackonwhite .contact_text_wrapper {
  color: #000;
  font-size: 24px;
}
body.size_small.color_blackonwhite .contact_btn {
  background: #fff;
  color: #000 !important;
  border: 1px solid #000;
  box-shadow: none;
  font-size: 20px;
}
body.size_small.color_blackonwhite .note {
  font-size: 20px;
}
body.size_small.color_blackonwhite .scheme_header {
  font-size: 30px;
  line-height: 1.4;
}
body.size_small.color_blackonwhite .section_switch_block {
  font-size: 24px;
  color: #000;
  text-decoration: underline;
}
body.size_small.color_blackonwhite .section_info_header {
  font-size: 30px;
}
body.size_small.color_blackonwhite .section_info_text {
  font-size: 24px;
}
body.size_small.color_blackonwhite .section_info_print {
  font-size: 24px;
}
body.size_small.color_blackonwhite .event_content,
body.size_small.color_blackonwhite .page_content {
  box-shadow: none;
}
body.size_small.color_blackonwhite .scheme_switch_block {
  padding: 0;
}
body.size_small.color_blackonwhite a.departments__item {
  font-size: 24px;
  height: auto;
  margin-bottom: 20px;
}
body.size_small.color_blackonwhite .contact_cell span {
  font-size: 20px !important;
}
body.size_small.color_blackonwhite .logo_wrapper {
  background: url('/images/svg/accent-logo-black.svg') top center no-repeat;
}
body.size_small.color_blackonwhite .logo_wrapper img {
  opacity: 0;
  visibility: hidden;
}
body.size_small.color_blackonwhite .fall_through_link {
  color: #000;
}
body.size_small.color_blackonwhite .events-filters,
body.size_small.color_blackonwhite .events-filters__wrapper {
  background: #fff;
  border: none;
}
body.size_small.color_blackonwhite .icheckbox_minimal {
  border-color: #000;
}
body.size_small.color_blackonwhite .news_block {
  background-color: none;
}
body.size_small.color_blackonwhite .new-info {
  background: #fff;
}
body.size_small.color_blackonwhite .parking_note {
  float: none;
  margin-top: 100px;
  text-align: left;
}
body.size_small.color_blackonwhite .parking_note:before {
  content: ' ';
  display: block;
  clear: both;
}
body.size_small.color_blackonwhite .subway_label {
  background-color: #fff;
  border: 1px solid #000;
}
body.size_small.color_blackonwhite .event_content,
body.size_small.color_blackonwhite .page_content {
  background: none;
}
body.size_small.color_blackonwhite .scheme_header,
body.size_small.color_blackonwhite .scheme_switch_block,
body.size_small.color_blackonwhite ol.sections_switcher li:before {
  color: #000;
}
body.size_small.color_blackonwhite .scheme_switch_block.active {
  background: #000;
  color: #fff;
}
body.size_small.color_blackonwhite .section_switch_block.active {
  background: #000;
  color: #fff;
}
body.size_small.color_blackonwhite .section_info.active {
  background: #fff;
}
body.size_small.color_blackonwhite .section_info_print {
  color: #000;
  text-decoration: underline;
}
body.size_small.color_blackonwhite .images_gallery .gallery_image {
  background: #fff;
}
body.size_small.color_blackonwhite .list_ul ul li:before {
  background: #000;
}
body.size_small.color_blackonwhite .catalogue_search_form,
body.size_small.color_blackonwhite .vf_search_form {
  border: none;
  background: none;
}
body.size_small.color_blackonwhite .work_time a,
body.size_small.color_blackonwhite a.work_time {
  border: none;
}
body.size_small.color_blackonwhite .long-term-events__item {
  border-color: #000;
}
body.size_small.color_blackonwhite .news_block {
  background: #fff;
  border: 1px solid #000;
  overflow: hidden;
}
body.size_small.color_blackonwhite .inner_pages_breadcrumbs {
  border-color: #000;
}
body.size_small.color_blackonwhite .contact_table .contact_row .contact_cell {
  border-color: #000;
}
body.size_small.color_blackonwhite .event_organizer:after {
  border-color: #000;
}
body.size_small.color_blackonwhite .vf_search_select {
  box-shadow: none;
  background: #fff;
  border: 1px solid #000;
  width: 300px;
}
body.size_small.color_blackonwhite .index_search_option,
body.size_small.color_blackonwhite .vf_search_option {
  border-color: #000;
  color: #000;
}
body.size_small.color_blackonwhite .header {
  height: auto;
}
body.size_small.color_blackonwhite .index_subscribe_block .subscribe-brief {
  line-height: 1.35;
}
body.size_small.color_blackonwhite #footer {
  line-height: 1.35;
}
body.size_small.color_blackonwhite .footer_copy_text {
  line-height: 1.35;
}
body.size_small.color_blackonwhite .footer_social_wrapper .footer_list {
  line-height: 1.35;
}
body.size_small.color_blackonwhite .nouvelle_small_text {
  color: #000;
}
body.size_small.color_blackonwhite .top-banner {
  background-color: #fff;
  border: 2px solid #000;
  font-size: 24px;
}
body.size_small.color_blackonwhite .top-banner__close {
  background-size: 35px;
}
body.size_small.color_blackonwhite .flex-direction-nav a {
  width: 65px;
  height: 65px;
  background-size: 35px;
  background-color: #fff;
  border: 1px solid #000;
}
body.size_small.color_blackonwhite .flex-nav-prev {
  margin-right: 15px;
}
body.size_small.color_blackonwhite .flex-control-paging li a {
  width: 15px;
  height: 15px;
  background-color: transparent;
  border: 1px solid #000;
}
body.size_small.color_blackonwhite .flex-control-paging li a.flex-active {
  background-color: #000;
}
body.size_small.color_blackonwhite .departments_block .departments_block__wrapper .departments_block__part a.departments_block__item img {
  height: auto;
  width: 65px;
}
body.size_small.color_blackonwhite a.menu__link_footer img {
  width: 30px !important;
}
body.size_small.color_blackonwhite .social-btn_vk {
  background-size: 30px;
}
body.size_small.color_blackonwhite .social-btn_fb {
  background-size: 20px;
}
body.size_small.color_blackonwhite .social-btn_ig {
  background-size: 22px;
}
body.size_small.color_blackonwhite .social-btn_yt {
  background-size: 28px;
}
body.size_small.color_blackonwhite .pro_column {
  width: 33%;
}
body.size_small.color_blackonwhite .index_news {
  width: 65%;
}
body.size_small.color_blackonwhite .index_pro_block .index_pro_content {
  padding-left: 0;
}
body.size_small.color_blackonwhite .events-item_background .event-cycle {
  background-color: #fff;
  font-size: 24px;
}
body.size_small.color_blackonwhite .news_about .centenary_tag,
body.size_small.color_blackonwhite .event-tag {
  color: #000;
  background-color: #fff;
  border: 1px solid #000;
  font-size: 20px;
}
body.size_small.color_blackonwhite .side_social_wrapper > ul > li {
  width: 48px;
  height: 48px;
  border-color: #000;
}
body.size_small.color_blackonwhite .side_social_wrapper > ul > li > a > svg {
  fill: #000;
  width: 22px;
  height: 22px;
}
body.size_small.color_blackonwhite .partners_column a.partners_block {
  border: 2px solid #000;
  padding: 10px 15px;
}
body.size_small.color_blackonwhite .partners_column a.partners_block .partners_block_title {
  font-size: 24px;
  color: #000;
}
body.size_small.color_blackonwhite .test-wrapper {
  grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
}
body.size_small.color_blackonwhite .long-term-events {
  width: 100%;
}
body.size_small.color_blackonwhite .news_wrapper.listing_block {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
  grid-gap: 23px;
}
body.size_small.color_blackonwhite .news_block {
  float: none;
  margin: 0 !important;
  width: auto;
}
body.size_small.color_blackonwhite .news_image {
  width: 100%;
  background-position: center;
}
body.size_small.color_blackonwhite .news_title {
  line-height: 1.2;
}
body.size_small.color_whiteonblack {
  font-size: 24px;
  color: #fff;
  background-color: #000;
  padding-top: 120px;
}
body.size_small.color_whiteonblack .fixed_menu_wrapper .js-login-screen,
body.size_small.color_whiteonblack .fixed_menu_wrapper .js-become-member {
  display: none;
}
body.size_small.color_whiteonblack #special_version_controls {
  border: 1px solid #fff;
  background: #000;
  margin-bottom: 6px;
  border-radius: 0 0 4px 4px;
  border-top: 0;
  width: 90%;
  max-width: 1200px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99999;
  padding: 20px;
  text-align: center;
  display: block;
}
body.size_small.color_whiteonblack .special_version_controls_group {
  display: inline-block;
  margin-right: 40px;
  margin-bottom: 30px;
}
body.size_small.color_whiteonblack .special_version_controls_label {
  display: inline-block;
  vertical-align: top;
  padding-top: 6px;
  font-size: 20px;
}
body.size_small.color_whiteonblack .special_version_controls_select {
  display: inline-block;
  vertical-align: top;
  margin-left: 10px;
}
body.size_small.color_whiteonblack .special_version_controls_point {
  display: block;
  vertical-align: top;
  position: relative;
  background: #fff;
  border: 1px solid #000;
  margin-right: -1px;
  cursor: pointer;
  height: 41px;
  width: 41px;
  text-align: center;
  line-height: 1;
  padding: 10px 12px 6px;
  font-size: 20px;
  float: left;
}
body.size_small.color_whiteonblack .special_version_controls_point:first-child {
  border-radius: 4px 0 0 4px;
}
body.size_small.color_whiteonblack .special_version_controls_point:last-child {
  border-radius: 0 4px 4px 0;
}
body.size_small.color_whiteonblack .special_version_controls_point.active:before {
  content: ' ';
  display: block;
  position: absolute;
  width: 100%;
  height: 1px;
  border-bottom: 2px dashed #fff;
  left: 0;
  bottom: -7px;
}
body.size_small.color_whiteonblack .special_version_controls_select[data-parameter="size"] .special_version_controls_point {
  color: #fff;
  background-color: #000;
}
body.size_small.color_whiteonblack .special_version_controls_select[data-parameter="size"] .special_version_controls_point[data-value="medium"] {
  font-size: 30px;
  padding: 4px 0 0;
}
body.size_small.color_whiteonblack .special_version_controls_select[data-parameter="size"] .special_version_controls_point[data-value="large"] {
  font-size: 40px;
  padding: 0;
}
body.size_small.color_whiteonblack .special_version_controls_select[data-parameter="color"] .special_version_controls_point[data-value="blackonwhite"] {
  color: #000;
  background: #fff;
}
body.size_small.color_whiteonblack .special_version_controls_select[data-parameter="color"] .special_version_controls_point[data-value="whiteonblack"] {
  color: #fff;
  background: #000;
}
body.size_small.color_whiteonblack .special_version_controls_select[data-parameter="color"] .special_version_controls_point[data-value="blue"] {
  background: #9dd1ff;
  color: #195183;
}
body.size_small.color_whiteonblack .special_version_controls_select[data-parameter="graphics"] .special_version_controls_point[data-value="normal"] {
  background: #0000f3;
  color: #fff;
}
body.size_small.color_whiteonblack .special_version_controls_select[data-parameter="graphics"] .special_version_controls_point[data-value="mono"] {
  background: #ddd;
}
body.size_small.color_whiteonblack .special_version_controls_select[data-parameter="graphics"] .special_version_controls_point[data-value="disabled"] {
  color: #fff;
}
body.size_small.color_whiteonblack a.menu__item.link.link_blue:first-of-type {
  margin-right: 0;
}
body.size_small.color_whiteonblack .header__menu .menu__item {
  margin-right: 25px;
}
body.size_small.color_whiteonblack .layout-wrapper {
  color: #fff;
  background-color: #000;
}
body.size_small.color_whiteonblack .infobar {
  height: auto;
  font-size: 24px;
  background: #000;
}
body.size_small.color_whiteonblack .infobar:after {
  content: ' ';
  display: block;
  clear: both;
}
body.size_small.color_whiteonblack .infobar__link,
body.size_small.color_whiteonblack .infobar__link_academy {
  color: #fff;
  font-size: 20px;
}
body.size_small.color_whiteonblack a.infobar__link_academy:before {
  display: none;
}
body.size_small.color_whiteonblack a.menu__link {
  font-size: 24px;
  color: #fff;
}
body.size_small.color_whiteonblack a {
  color: #fff !important;
  text-decoration: underline !important;
}
body.size_small.color_whiteonblack .become-member {
  font-size: 24px;
}
body.size_small.color_whiteonblack #nav {
  border: none;
  margin: 40px 0;
}
body.size_small.color_whiteonblack .full-index {
  font-size: 24px;
}
body.size_small.color_whiteonblack .full-index:after {
  display: none;
}
body.size_small.color_whiteonblack .search_switch {
  font-size: 24px;
  color: #fff;
}
body.size_small.color_whiteonblack .quick-links {
  margin-left: 50px;
  width: 350px;
}
body.size_small.color_whiteonblack a.quick-links__item,
body.size_small.color_whiteonblack a.closing-time {
  font-size: 24px;
}
body.size_small.color_whiteonblack a.closing-time:after {
  display: none;
}
body.size_small.color_whiteonblack .wisemen_block {
  display: none !important;
}
body.size_small.color_whiteonblack .vf_search_wrapper {
  width: 540px;
}
body.size_small.color_whiteonblack input#index_search,
body.size_small.color_whiteonblack input#search {
  font-size: 24px;
}
body.size_small.color_whiteonblack .index_header {
  font-size: 34px;
}
body.size_small.color_whiteonblack .index_subscribe_block {
  height: auto;
  margin-bottom: 20px;
}
body.size_small.color_whiteonblack .index_subscribe_block .subscribe-brief {
  color: #fff;
  font-size: 20px;
}
body.size_small.color_whiteonblack .index_subscribe_block .subscribe-brief strong {
  font-size: 24px;
  color: #fff;
  display: block;
  text-decoration: underline;
}
body.size_small.color_whiteonblack .departments_block {
  background: #000;
  padding: 40px;
  box-shadow: none;
}
body.size_small.color_whiteonblack .departments_block .departments_block__wrapper .departments_block__subheader {
  color: #fff;
  font-size: 34px;
}
body.size_small.color_whiteonblack .departments_block .departments_block__wrapper .departments_block__part a.departments_block__item {
  border: none;
  margin-bottom: 10px;
}
body.size_small.color_whiteonblack .departments_block .departments_block__wrapper .departments_block__part a.departments_block__item span {
  color: #fff;
  font-size: 24px;
  line-height: 1.35;
}
body.size_small.color_whiteonblack .departments_block__decoration {
  display: none;
}
body.size_small.color_whiteonblack .fall_through_link {
  font-size: 24px;
}
body.size_small.color_whiteonblack .fall_through_link:after {
  display: none;
}
body.size_small.color_whiteonblack .nouvelle_desc a,
body.size_small.color_whiteonblack .nouvelle_desc small {
  color: #fff;
  font-size: 20px;
}
body.size_small.color_whiteonblack .nouvelle_small_text,
body.size_small.color_whiteonblack .nouvelle_title {
  font-size: 24px;
}
body.size_small.color_whiteonblack .index_nouvelle_main .nouvelle_title {
  font-size: 30px;
}
body.size_small.color_whiteonblack .index_pro_block .index_pro_content {
  background-color: #000;
}
body.size_small.color_whiteonblack .index_pro_block .index_pro_content .index_pro_content_title,
body.size_small.color_whiteonblack .index_pro_block .index_pro_content .index_pro_content_text {
  font-size: 24px;
}
body.size_small.color_whiteonblack .index_pro_block .index_pro_content small {
  color: #fff;
  font-size: 20px;
}
body.size_small.color_whiteonblack .index_news_wrapper * {
  line-height: 1.35 !important;
}
body.size_small.color_whiteonblack .collection_links a {
  font-size: 20px;
}
body.size_small.color_whiteonblack .collection_info a {
  font-size: 20px;
}
body.size_small.color_whiteonblack .index_collections_wrapper .collection_title {
  font-size: 30px;
}
body.size_small.color_whiteonblack .index_collections_wrapper .collection_about small {
  font-size: 20px;
  color: #fff !important;
}
body.size_small.color_whiteonblack .header__menu .menu__item {
  font-size: 24px;
}
body.size_small.color_whiteonblack .menu__item_active {
  border-bottom: 2px dashed #fff;
}
body.size_small.color_whiteonblack #footer {
  color: #fff;
  background: #000;
  font-size: 20px;
}
body.size_small.color_whiteonblack .footer_social_header {
  color: #fff;
  font-size: 24px;
}
body.size_small.color_whiteonblack a.menu__link_footer {
  font-size: 24px;
}
body.size_small.color_whiteonblack .footer_header {
  font-size: 24px;
  color: #fff;
}
body.size_small.color_whiteonblack .work_time p {
  font-size: 20px;
}
body.size_small.color_whiteonblack .footer_column_left {
  max-width: 50%;
}
body.size_small.color_whiteonblack .footer_copy {
  border-top-color: #000;
  color: #fff;
  font-size: 20px;
}
body.size_small.color_whiteonblack .footer_banner img {
  display: none;
}
body.size_small.color_whiteonblack .footer_banner a {
  font-size: 24px;
}
body.size_small.color_whiteonblack .events-item__image {
  background: #000 !important;
}
body.size_small.color_whiteonblack .event-type a,
body.size_small.color_whiteonblack .events_cat a,
body.size_small.color_whiteonblack .inner-event-type a,
body.size_small.color_whiteonblack .new-type a {
  font-size: 20px;
}
body.size_small.color_whiteonblack .events-item__place {
  font-size: 24px;
}
body.size_small.color_whiteonblack .events-item__header {
  font-size: 30px;
}
body.size_small.color_whiteonblack .events-item__time {
  font-size: 34px;
}
body.size_small.color_whiteonblack .event-type a,
body.size_small.color_whiteonblack .events_cat a,
body.size_small.color_whiteonblack .inner-event-type a,
body.size_small.color_whiteonblack .new-type a,
body.size_small.color_whiteonblack .events-item__place,
body.size_small.color_whiteonblack .events-item__header,
body.size_small.color_whiteonblack .events-item__time {
  color: #fff;
}
body.size_small.color_whiteonblack .long-term-events {
  background: #000;
}
body.size_small.color_whiteonblack .long-term-events__title,
body.size_small.color_whiteonblack a.long-term-events__item-header {
  color: #fff;
  font-size: 24px;
}
body.size_small.color_whiteonblack .event-date__day-of-the-month {
  font-size: 30px;
}
body.size_small.color_whiteonblack .event-date__day-of-the-month small {
  font-size: 24px;
}
body.size_small.color_whiteonblack .event-date_weekday {
  font-size: 24px;
  color: #fff;
}
body.size_small.color_whiteonblack .event-date:before {
  display: none;
}
body.size_small.color_whiteonblack .btn__show-more {
  border-color: #fff;
  color: #fff;
  font-size: 30px;
}
body.size_small.color_whiteonblack .subscribe-header {
  font-size: 30px;
  color: #fff;
}
body.size_small.color_whiteonblack .subscribe-brief {
  color: #fff;
  font-size: 24px;
}
body.size_small.color_whiteonblack .subscribe_block .subscribe-btn {
  font-size: 24px;
  width: 240px;
  color: #fff;
  background: transparent;
  border: 1px solid #fff;
  padding: 0;
  line-height: 53px;
}
body.size_small.color_whiteonblack .events-by-date * {
  line-height: 1.35 !important;
}
body.size_small.color_whiteonblack .events_date_btn,
body.size_small.color_whiteonblack .ui-selectmenu-button,
body.size_small.color_whiteonblack .ui-state-focus.ui-selectmenu-button,
body.size_small.color_whiteonblack .ui-state-hover.ui-selectmenu-button,
body.size_small.color_whiteonblack label.checkbox,
body.size_small.color_whiteonblack .ui-menu .ui-menu-item {
  font-size: 24px;
  color: #fff;
  background: #000;
}
body.size_small.color_whiteonblack .catalogue-search_background {
  background: none;
}
body.size_small.color_whiteonblack .catalogue-search_title {
  font-size: 34px;
}
body.size_small.color_whiteonblack .catalogue-search_title span {
  font-size: 30px;
}
body.size_small.color_whiteonblack .catalogue_search_form {
  box-shadow: none;
  height: 65px;
  border: 2px solid #fff;
  font-size: 24px;
}
body.size_small.color_whiteonblack .index_search_option,
body.size_small.color_whiteonblack .vf_search_option {
  font-size: 24px;
}
body.size_small.color_whiteonblack .button__filled {
  border-radius: 0;
  border: 1px solid #fff;
  font-size: 24px;
  background: #000;
}
body.size_small.color_whiteonblack .catalogue-item {
  border: 2px solid #fff;
  background: none !important;
}
body.size_small.color_whiteonblack .catalogue-item_title {
  font-size: 34px;
}
body.size_small.color_whiteonblack .catalogue-item_links a {
  font-size: 24px;
}
body.size_small.color_whiteonblack a.button {
  font-size: 24px;
}
body.size_small.color_whiteonblack .button__label {
  margin: 25px 0 0;
  box-shadow: none;
  border: 1px solid #fff;
  background: none;
}
body.size_small.color_whiteonblack .about_block {
  border-color: #fff;
  background: none;
}
body.size_small.color_whiteonblack .catalogue_title {
  color: #fff;
  font-size: 20px;
}
body.size_small.color_whiteonblack input,
body.size_small.color_whiteonblack textarea {
  border: 2px solid #fff !important;
  background: #000 !important;
  font-size: 24px !important;
  color: #fff !important;
  border-radius: 0px !important;
}
body.size_small.color_whiteonblack #fixed_menu {
  background: #000;
  box-shadow: none;
  border-bottom: 2px solid #fff;
}
body.size_small.color_whiteonblack .fixed_menu_wrapper {
  background: none;
}
body.size_small.color_whiteonblack .news_block {
  box-shadow: none;
}
body.size_small.color_whiteonblack .news_about {
  padding-left: 10px;
  padding-right: 10px;
}
body.size_small.color_whiteonblack .news_tag {
  font-size: 20px;
  color: #fff;
}
body.size_small.color_whiteonblack .news_title {
  font-size: 24px;
}
body.size_small.color_whiteonblack .visitors_block,
body.size_small.color_whiteonblack .info_block,
body.size_small.color_whiteonblack .digest_block,
body.size_small.color_whiteonblack .libfl_block {
  background: none;
  border: 2px solid #fff;
  box-shadow: none;
}
body.size_small.color_whiteonblack .libfl_block {
  padding-left: 30px;
  padding-right: 40px;
}
body.size_small.color_whiteonblack .libfl_block .libfl_block__part a:before {
  display: none;
}
body.size_small.color_whiteonblack .libfl_block .libfl_block__part a {
  font-size: 30px;
}
body.size_small.color_whiteonblack .libfl_block .libfl_block__description span {
  color: #fff;
  font-size: 20px;
}
body.size_small.color_whiteonblack .info_block .info_block__address {
  color: #fff;
  font-size: 34px;
}
body.size_small.color_whiteonblack .info_block .info_block__address span {
  color: #fff;
  font-size: 20px;
}
body.size_small.color_whiteonblack .info_block a {
  font-size: 24px;
}
body.size_small.color_whiteonblack .info_block a.info_block__map {
  height: auto;
  background: none;
}
body.size_small.color_whiteonblack .visitors_block .visitors_block__title {
  color: #fff;
  font-size: 30px;
  margin-bottom: 20px;
}
body.size_small.color_whiteonblack .visitors_block a {
  font-size: 24px;
  font-weight: normal;
}
body.size_small.color_whiteonblack .digest_block .digest_block__title {
  color: #fff;
  font-size: 30px;
  margin-bottom: 20px;
}
body.size_small.color_whiteonblack .digest_block a {
  font-size: 24px;
}
body.size_small.color_whiteonblack #popup_wrapper {
  background: rgba(0, 0, 0, 0.8);
}
body.size_small.color_whiteonblack .popup_box {
  background: #000 !important;
  color: #fff !important;
  font-size: 24px;
}
body.size_small.color_whiteonblack .popup_box * {
  color: #fff !important;
  line-height: 1.35 !important;
}
body.size_small.color_whiteonblack .modal_parts .modal_part .modal_title {
  font-size: 30px;
}
body.size_small.color_whiteonblack .modal_parts .modal_part .modal_subtitle {
  font-size: 24px;
  height: auto;
}
body.size_small.color_whiteonblack .modal_parts ul.modal_list li,
body.size_small.color_whiteonblack .modal_parts ol.modal_list li {
  font-size: 24px;
}
body.size_small.color_whiteonblack .modal_parts ul.modal_list li:before,
body.size_small.color_whiteonblack .modal_parts ol.modal_list li:before {
  color: #fff;
}
body.size_small.color_whiteonblack .modal_cta {
  background: none;
  font-size: 34px;
}
body.size_small.color_whiteonblack .modal_welcome span {
  font-size: 34px;
}
body.size_small.color_whiteonblack .modal_welcome:before {
  display: none;
}
body.size_small.color_whiteonblack .modal_welcome a.btn {
  background: none;
  border-radius: 0;
  border: 2px solid #fff;
  font-size: 24px;
  width: auto;
  height: auto;
  line-height: 1 !important;
}
body.size_small.color_whiteonblack .modal_rules a.link {
  font-size: 20px;
}
body.size_small.color_whiteonblack #popup_reader.popup_box .decoration_left,
body.size_small.color_whiteonblack #popup_reader.popup_box .decoration_right {
  display: none;
}
body.size_small.color_whiteonblack .event-info {
  width: 600px;
  height: auto;
  background: #000;
  color: #fff;
}
body.size_small.color_whiteonblack .inner-event-type span {
  font-size: 20px;
}
body.size_small.color_whiteonblack .event-info__title,
body.size_small.color_whiteonblack .new-info__title {
  font-size: 30px;
}
body.size_small.color_whiteonblack .event-info__place {
  font-size: 20px;
}
body.size_small.color_whiteonblack .event-info__shedule,
body.size_small.color_whiteonblack .new-info__shedule {
  position: relative;
  width: 100%;
  bottom: auto;
  line-height: 1.35;
  margin-top: 20px;
}
body.size_small.color_whiteonblack .event-info__date {
  font-size: 20px;
}
body.size_small.color_whiteonblack .event-info__time {
  font-size: 30px;
  color: #fff;
}
body.size_small.color_whiteonblack .event-info__admission {
  width: 250px;
}
body.size_small.color_whiteonblack .event-info__admission * {
  color: #fff;
}
body.size_small.color_whiteonblack .event-info__admission--docs {
  font-size: 20px;
  color: #fff;
}
body.size_small.color_whiteonblack .block_wrapper * {
  font-size: 24px;
  color: #fff !important;
}
body.size_small.color_whiteonblack .block_wrapper,
body.size_small.color_whiteonblack .cutted_block,
body.size_small.color_whiteonblack .image_left_block,
body.size_small.color_whiteonblack .image_right_block,
body.size_small.color_whiteonblack .old_block p,
body.size_small.color_whiteonblack .text_block {
  font-size: 24px;
}
body.size_small.color_whiteonblack #news .block_wrapper:first-child .text_block {
  font-size: 30px;
}
body.size_small.color_whiteonblack .block_wrapper h2 {
  font-size: 34px;
  line-height: 1.4;
}
body.size_small.color_whiteonblack .block_wrapper h3 {
  font-size: 30px;
  line-height: 1.4;
}
body.size_small.color_whiteonblack .block_wrapper h4 {
  font-size: 24px;
  line-height: 1.4;
}
body.size_small.color_whiteonblack .image_block span.set,
body.size_small.color_whiteonblack .image_left_block span.set,
body.size_small.color_whiteonblack .image_right_block span.set,
body.size_small.color_whiteonblack .image_block .image_desc,
body.size_small.color_whiteonblack .image_left_block .image_desc,
body.size_small.color_whiteonblack .image_right_block .image_desc {
  font-size: 20px;
}
body.size_small.color_whiteonblack .button_link,
body.size_small.color_whiteonblack .registration_link,
body.size_small.color_whiteonblack .registration_link_instruction {
  font-size: 20px;
}
body.size_small.color_whiteonblack .button_link .button_with_link,
body.size_small.color_whiteonblack .registration_link .button_with_link,
body.size_small.color_whiteonblack .registration_link_instruction .button_with_link {
  font-size: 20px;
  background: none;
  border: 2px solid #fff;
  height: auto;
  line-height: 1;
  padding: 20px;
  width: auto;
}
body.size_small.color_whiteonblack .registration_link {
  border-color: #fff;
}
body.size_small.color_whiteonblack .list_ul ul {
  font-size: 24px;
}
body.size_small.color_whiteonblack .list_ol ol {
  font-size: 24px;
}
body.size_small.color_whiteonblack .cite_block .cite {
  font-size: 34px;
}
body.size_small.color_whiteonblack .cite_block .cite_by {
  font-size: 24px;
}
body.size_small.color_whiteonblack .collections_block .collection_about a {
  font-size: 30px;
}
body.size_small.color_whiteonblack .collections_block .collection_title {
  font-size: 34px;
}
body.size_small.color_whiteonblack .collections_block .collection_info {
  font-size: 20px;
}
body.size_small.color_whiteonblack .collections_block .collection_info a {
  font-size: 20px;
}
body.size_small.color_whiteonblack .cutted_block .cut_block_button {
  font-size: 20px;
}
body.size_small.color_whiteonblack .event_organizer,
body.size_small.color_whiteonblack .event_additional_wrapper > span {
  font-size: 20px;
}
body.size_small.color_whiteonblack .btn_social {
  font-size: 20px;
  background-color: #000 !important;
  color: #fff !important;
  border: 1px solid #fff;
  height: auto;
  width: auto;
  padding: 5px 10px;
}
body.size_small.color_whiteonblack .bottom_block {
  border-color: #fff;
}
body.size_small.color_whiteonblack .tagline {
  font-size: 24px;
}
body.size_small.color_whiteonblack .tagline small {
  font-size: 20px;
  color: #fff;
}
body.size_small.color_whiteonblack .page-views {
  font-size: 20px;
  color: #fff;
}
body.size_small.color_whiteonblack .article-description__header {
  font-size: 34px;
}
body.size_small.color_whiteonblack .facilities_banner a.facilities_banner__description {
  font-size: 30px;
}
body.size_small.color_whiteonblack .accordion dt a {
  font-size: 24px;
}
body.size_small.color_whiteonblack .accordion dd .dd-row .dd-head {
  color: #fff;
}
body.size_small.color_whiteonblack .accordion dd .dd-row .dd-cell,
body.size_small.color_whiteonblack .accordion dd .dd-row .dd-head {
  font-size: 20px;
  color: #fff;
}
body.size_small.color_whiteonblack .accordion span {
  font-size: 20px;
  color: #fff;
}
body.size_small.color_whiteonblack .article-nav-childs {
  border: 2px solid #fff;
  background: none;
  font-size: 20px;
}
body.size_small.color_whiteonblack .article-nav-childs__header {
  background: #000;
  border-bottom: 2px solid #fff;
  font-size: inherit;
  color: #fff;
}
body.size_small.color_whiteonblack .inner_pages_breadcrumbs {
  font-size: 20px;
  color: #fff;
}
body.size_small.color_whiteonblack .contact_wrapper {
  background: none;
  border: 2px solid #fff;
}
body.size_small.color_whiteonblack .contact_text_wrapper,
body.size_small.color_whiteonblack .contact_text_wrapper {
  color: #fff;
  font-size: 24px;
}
body.size_small.color_whiteonblack .contact_btn {
  background: #000;
  color: #fff !important;
  border: 1px solid #fff;
  box-shadow: none;
  font-size: 20px;
}
body.size_small.color_whiteonblack .note {
  font-size: 20px;
}
body.size_small.color_whiteonblack .scheme_header {
  font-size: 30px;
  line-height: 1.4;
}
body.size_small.color_whiteonblack .section_switch_block {
  font-size: 24px;
  color: #fff;
  text-decoration: underline;
}
body.size_small.color_whiteonblack .section_info_header {
  font-size: 30px;
}
body.size_small.color_whiteonblack .section_info_text {
  font-size: 24px;
}
body.size_small.color_whiteonblack .section_info_print {
  font-size: 24px;
}
body.size_small.color_whiteonblack .event_content,
body.size_small.color_whiteonblack .page_content {
  box-shadow: none;
}
body.size_small.color_whiteonblack .scheme_switch_block {
  padding: 0;
}
body.size_small.color_whiteonblack a.departments__item {
  font-size: 24px;
  height: auto;
  margin-bottom: 20px;
}
body.size_small.color_whiteonblack .contact_cell span {
  font-size: 20px !important;
}
body.size_small.color_whiteonblack .logo_wrapper {
  background: url('/images/svg/accent-logo-white.svg') top center no-repeat;
}
body.size_small.color_whiteonblack .logo_wrapper img {
  opacity: 0;
  visibility: hidden;
}
body.size_small.color_whiteonblack .fall_through_link {
  color: #fff;
}
body.size_small.color_whiteonblack .events-filters,
body.size_small.color_whiteonblack .events-filters__wrapper {
  background: #000;
  border: none;
}
body.size_small.color_whiteonblack .icheckbox_minimal {
  border-color: #fff;
}
body.size_small.color_whiteonblack .news_block {
  background-color: none;
}
body.size_small.color_whiteonblack .new-info {
  background: #000;
}
body.size_small.color_whiteonblack .parking_note {
  float: none;
  margin-top: 100px;
  text-align: left;
}
body.size_small.color_whiteonblack .parking_note:before {
  content: ' ';
  display: block;
  clear: both;
}
body.size_small.color_whiteonblack .subway_label {
  background-color: #000;
  border: 1px solid #fff;
}
body.size_small.color_whiteonblack .event_content,
body.size_small.color_whiteonblack .page_content {
  background: none;
}
body.size_small.color_whiteonblack .scheme_header,
body.size_small.color_whiteonblack .scheme_switch_block,
body.size_small.color_whiteonblack ol.sections_switcher li:before {
  color: #fff;
}
body.size_small.color_whiteonblack .scheme_switch_block.active {
  background: #fff;
  color: #000;
}
body.size_small.color_whiteonblack .section_switch_block.active {
  background: #fff;
  color: #000;
}
body.size_small.color_whiteonblack .section_info.active {
  background: #000;
}
body.size_small.color_whiteonblack .section_info_print {
  color: #fff;
  text-decoration: underline;
}
body.size_small.color_whiteonblack .images_gallery .gallery_image {
  background: #000;
}
body.size_small.color_whiteonblack .list_ul ul li:before {
  background: #fff;
}
body.size_small.color_whiteonblack .catalogue_search_form,
body.size_small.color_whiteonblack .vf_search_form {
  border: none;
  background: none;
}
body.size_small.color_whiteonblack .work_time a,
body.size_small.color_whiteonblack a.work_time {
  border: none;
}
body.size_small.color_whiteonblack .long-term-events__item {
  border-color: #fff;
}
body.size_small.color_whiteonblack .news_block {
  background: #000;
  border: 1px solid #fff;
  overflow: hidden;
}
body.size_small.color_whiteonblack .inner_pages_breadcrumbs {
  border-color: #fff;
}
body.size_small.color_whiteonblack .contact_table .contact_row .contact_cell {
  border-color: #fff;
}
body.size_small.color_whiteonblack .event_organizer:after {
  border-color: #fff;
}
body.size_small.color_whiteonblack .vf_search_select {
  box-shadow: none;
  background: #000;
  border: 1px solid #fff;
  width: 300px;
}
body.size_small.color_whiteonblack .index_search_option,
body.size_small.color_whiteonblack .vf_search_option {
  border-color: #fff;
  color: #fff;
}
body.size_small.color_whiteonblack .header {
  height: auto;
}
body.size_small.color_whiteonblack .index_subscribe_block .subscribe-brief {
  line-height: 1.35;
}
body.size_small.color_whiteonblack #footer {
  line-height: 1.35;
}
body.size_small.color_whiteonblack .footer_copy_text {
  line-height: 1.35;
}
body.size_small.color_whiteonblack .footer_social_wrapper .footer_list {
  line-height: 1.35;
}
body.size_small.color_whiteonblack .nouvelle_small_text {
  color: #fff;
}
body.size_small.color_whiteonblack .top-banner {
  background-color: #000;
  border: 2px solid #fff;
  font-size: 24px;
}
body.size_small.color_whiteonblack .top-banner__close {
  background-size: 35px;
}
body.size_small.color_whiteonblack .flex-direction-nav a {
  width: 65px;
  height: 65px;
  background-size: 35px;
  background-color: #000;
  border: 1px solid #fff;
}
body.size_small.color_whiteonblack .flex-nav-prev {
  margin-right: 15px;
}
body.size_small.color_whiteonblack .flex-control-paging li a {
  width: 15px;
  height: 15px;
  background-color: transparent;
  border: 1px solid #fff;
}
body.size_small.color_whiteonblack .flex-control-paging li a.flex-active {
  background-color: #fff;
}
body.size_small.color_whiteonblack .departments_block .departments_block__wrapper .departments_block__part a.departments_block__item img {
  height: auto;
  width: 65px;
}
body.size_small.color_whiteonblack a.menu__link_footer img {
  width: 30px !important;
}
body.size_small.color_whiteonblack .social-btn_vk {
  background-size: 30px;
}
body.size_small.color_whiteonblack .social-btn_fb {
  background-size: 20px;
}
body.size_small.color_whiteonblack .social-btn_ig {
  background-size: 22px;
}
body.size_small.color_whiteonblack .social-btn_yt {
  background-size: 28px;
}
body.size_small.color_whiteonblack .pro_column {
  width: 33%;
}
body.size_small.color_whiteonblack .index_news {
  width: 65%;
}
body.size_small.color_whiteonblack .index_pro_block .index_pro_content {
  padding-left: 0;
}
body.size_small.color_whiteonblack .events-item_background .event-cycle {
  background-color: #000;
  font-size: 24px;
}
body.size_small.color_whiteonblack .news_about .centenary_tag,
body.size_small.color_whiteonblack .event-tag {
  color: #fff;
  background-color: #000;
  border: 1px solid #fff;
  font-size: 20px;
}
body.size_small.color_whiteonblack .side_social_wrapper > ul > li {
  width: 48px;
  height: 48px;
  border-color: #fff;
}
body.size_small.color_whiteonblack .side_social_wrapper > ul > li > a > svg {
  fill: #fff;
  width: 22px;
  height: 22px;
}
body.size_small.color_whiteonblack .partners_column a.partners_block {
  border: 2px solid #fff;
  padding: 10px 15px;
}
body.size_small.color_whiteonblack .partners_column a.partners_block .partners_block_title {
  font-size: 24px;
  color: #fff;
}
body.size_small.color_whiteonblack .test-wrapper {
  grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
}
body.size_small.color_whiteonblack .long-term-events {
  width: 100%;
}
body.size_small.color_whiteonblack .news_wrapper.listing_block {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
  grid-gap: 23px;
}
body.size_small.color_whiteonblack .news_block {
  float: none;
  margin: 0 !important;
  width: auto;
}
body.size_small.color_whiteonblack .news_image {
  width: 100%;
  background-position: center;
}
body.size_small.color_whiteonblack .news_title {
  line-height: 1.2;
}
body.size_small.color_blue {
  font-size: 24px;
  color: #195183;
  background-color: #9dd1ff;
  padding-top: 120px;
}
body.size_small.color_blue .fixed_menu_wrapper .js-login-screen,
body.size_small.color_blue .fixed_menu_wrapper .js-become-member {
  display: none;
}
body.size_small.color_blue #special_version_controls {
  border: 1px solid #195183;
  background: #9dd1ff;
  margin-bottom: 6px;
  border-radius: 0 0 4px 4px;
  border-top: 0;
  width: 90%;
  max-width: 1200px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99999;
  padding: 20px;
  text-align: center;
  display: block;
}
body.size_small.color_blue .special_version_controls_group {
  display: inline-block;
  margin-right: 40px;
  margin-bottom: 30px;
}
body.size_small.color_blue .special_version_controls_label {
  display: inline-block;
  vertical-align: top;
  padding-top: 6px;
  font-size: 20px;
}
body.size_small.color_blue .special_version_controls_select {
  display: inline-block;
  vertical-align: top;
  margin-left: 10px;
}
body.size_small.color_blue .special_version_controls_point {
  display: block;
  vertical-align: top;
  position: relative;
  background: #fff;
  border: 1px solid #000;
  margin-right: -1px;
  cursor: pointer;
  height: 41px;
  width: 41px;
  text-align: center;
  line-height: 1;
  padding: 10px 12px 6px;
  font-size: 20px;
  float: left;
}
body.size_small.color_blue .special_version_controls_point:first-child {
  border-radius: 4px 0 0 4px;
}
body.size_small.color_blue .special_version_controls_point:last-child {
  border-radius: 0 4px 4px 0;
}
body.size_small.color_blue .special_version_controls_point.active:before {
  content: ' ';
  display: block;
  position: absolute;
  width: 100%;
  height: 1px;
  border-bottom: 2px dashed #195183;
  left: 0;
  bottom: -7px;
}
body.size_small.color_blue .special_version_controls_select[data-parameter="size"] .special_version_controls_point {
  color: #195183;
  background-color: #9dd1ff;
}
body.size_small.color_blue .special_version_controls_select[data-parameter="size"] .special_version_controls_point[data-value="medium"] {
  font-size: 30px;
  padding: 4px 0 0;
}
body.size_small.color_blue .special_version_controls_select[data-parameter="size"] .special_version_controls_point[data-value="large"] {
  font-size: 40px;
  padding: 0;
}
body.size_small.color_blue .special_version_controls_select[data-parameter="color"] .special_version_controls_point[data-value="blackonwhite"] {
  color: #000;
  background: #fff;
}
body.size_small.color_blue .special_version_controls_select[data-parameter="color"] .special_version_controls_point[data-value="whiteonblack"] {
  color: #fff;
  background: #000;
}
body.size_small.color_blue .special_version_controls_select[data-parameter="color"] .special_version_controls_point[data-value="blue"] {
  background: #9dd1ff;
  color: #195183;
}
body.size_small.color_blue .special_version_controls_select[data-parameter="graphics"] .special_version_controls_point[data-value="normal"] {
  background: #0000f3;
  color: #fff;
}
body.size_small.color_blue .special_version_controls_select[data-parameter="graphics"] .special_version_controls_point[data-value="mono"] {
  background: #ddd;
}
body.size_small.color_blue .special_version_controls_select[data-parameter="graphics"] .special_version_controls_point[data-value="disabled"] {
  color: #fff;
}
body.size_small.color_blue a.menu__item.link.link_blue:first-of-type {
  margin-right: 0;
}
body.size_small.color_blue .header__menu .menu__item {
  margin-right: 25px;
}
body.size_small.color_blue .layout-wrapper {
  color: #195183;
  background-color: #9dd1ff;
}
body.size_small.color_blue .infobar {
  height: auto;
  font-size: 24px;
  background: #9dd1ff;
}
body.size_small.color_blue .infobar:after {
  content: ' ';
  display: block;
  clear: both;
}
body.size_small.color_blue .infobar__link,
body.size_small.color_blue .infobar__link_academy {
  color: #195183;
  font-size: 20px;
}
body.size_small.color_blue a.infobar__link_academy:before {
  display: none;
}
body.size_small.color_blue a.menu__link {
  font-size: 24px;
  color: #195183;
}
body.size_small.color_blue a {
  color: #195183 !important;
  text-decoration: underline !important;
}
body.size_small.color_blue .become-member {
  font-size: 24px;
}
body.size_small.color_blue #nav {
  border: none;
  margin: 40px 0;
}
body.size_small.color_blue .full-index {
  font-size: 24px;
}
body.size_small.color_blue .full-index:after {
  display: none;
}
body.size_small.color_blue .search_switch {
  font-size: 24px;
  color: #195183;
}
body.size_small.color_blue .quick-links {
  margin-left: 50px;
  width: 350px;
}
body.size_small.color_blue a.quick-links__item,
body.size_small.color_blue a.closing-time {
  font-size: 24px;
}
body.size_small.color_blue a.closing-time:after {
  display: none;
}
body.size_small.color_blue .wisemen_block {
  display: none !important;
}
body.size_small.color_blue .vf_search_wrapper {
  width: 540px;
}
body.size_small.color_blue input#index_search,
body.size_small.color_blue input#search {
  font-size: 24px;
}
body.size_small.color_blue .index_header {
  font-size: 34px;
}
body.size_small.color_blue .index_subscribe_block {
  height: auto;
  margin-bottom: 20px;
}
body.size_small.color_blue .index_subscribe_block .subscribe-brief {
  color: #195183;
  font-size: 20px;
}
body.size_small.color_blue .index_subscribe_block .subscribe-brief strong {
  font-size: 24px;
  color: #195183;
  display: block;
  text-decoration: underline;
}
body.size_small.color_blue .departments_block {
  background: #9dd1ff;
  padding: 40px;
  box-shadow: none;
}
body.size_small.color_blue .departments_block .departments_block__wrapper .departments_block__subheader {
  color: #195183;
  font-size: 34px;
}
body.size_small.color_blue .departments_block .departments_block__wrapper .departments_block__part a.departments_block__item {
  border: none;
  margin-bottom: 10px;
}
body.size_small.color_blue .departments_block .departments_block__wrapper .departments_block__part a.departments_block__item span {
  color: #195183;
  font-size: 24px;
  line-height: 1.35;
}
body.size_small.color_blue .departments_block__decoration {
  display: none;
}
body.size_small.color_blue .fall_through_link {
  font-size: 24px;
}
body.size_small.color_blue .fall_through_link:after {
  display: none;
}
body.size_small.color_blue .nouvelle_desc a,
body.size_small.color_blue .nouvelle_desc small {
  color: #195183;
  font-size: 20px;
}
body.size_small.color_blue .nouvelle_small_text,
body.size_small.color_blue .nouvelle_title {
  font-size: 24px;
}
body.size_small.color_blue .index_nouvelle_main .nouvelle_title {
  font-size: 30px;
}
body.size_small.color_blue .index_pro_block .index_pro_content {
  background-color: #9dd1ff;
}
body.size_small.color_blue .index_pro_block .index_pro_content .index_pro_content_title,
body.size_small.color_blue .index_pro_block .index_pro_content .index_pro_content_text {
  font-size: 24px;
}
body.size_small.color_blue .index_pro_block .index_pro_content small {
  color: #195183;
  font-size: 20px;
}
body.size_small.color_blue .index_news_wrapper * {
  line-height: 1.35 !important;
}
body.size_small.color_blue .collection_links a {
  font-size: 20px;
}
body.size_small.color_blue .collection_info a {
  font-size: 20px;
}
body.size_small.color_blue .index_collections_wrapper .collection_title {
  font-size: 30px;
}
body.size_small.color_blue .index_collections_wrapper .collection_about small {
  font-size: 20px;
  color: #195183 !important;
}
body.size_small.color_blue .header__menu .menu__item {
  font-size: 24px;
}
body.size_small.color_blue .menu__item_active {
  border-bottom: 2px dashed #195183;
}
body.size_small.color_blue #footer {
  color: #195183;
  background: #9dd1ff;
  font-size: 20px;
}
body.size_small.color_blue .footer_social_header {
  color: #195183;
  font-size: 24px;
}
body.size_small.color_blue a.menu__link_footer {
  font-size: 24px;
}
body.size_small.color_blue .footer_header {
  font-size: 24px;
  color: #195183;
}
body.size_small.color_blue .work_time p {
  font-size: 20px;
}
body.size_small.color_blue .footer_column_left {
  max-width: 50%;
}
body.size_small.color_blue .footer_copy {
  border-top-color: #9dd1ff;
  color: #195183;
  font-size: 20px;
}
body.size_small.color_blue .footer_banner img {
  display: none;
}
body.size_small.color_blue .footer_banner a {
  font-size: 24px;
}
body.size_small.color_blue .events-item__image {
  background: #9dd1ff !important;
}
body.size_small.color_blue .event-type a,
body.size_small.color_blue .events_cat a,
body.size_small.color_blue .inner-event-type a,
body.size_small.color_blue .new-type a {
  font-size: 20px;
}
body.size_small.color_blue .events-item__place {
  font-size: 24px;
}
body.size_small.color_blue .events-item__header {
  font-size: 30px;
}
body.size_small.color_blue .events-item__time {
  font-size: 34px;
}
body.size_small.color_blue .event-type a,
body.size_small.color_blue .events_cat a,
body.size_small.color_blue .inner-event-type a,
body.size_small.color_blue .new-type a,
body.size_small.color_blue .events-item__place,
body.size_small.color_blue .events-item__header,
body.size_small.color_blue .events-item__time {
  color: #195183;
}
body.size_small.color_blue .long-term-events {
  background: #9dd1ff;
}
body.size_small.color_blue .long-term-events__title,
body.size_small.color_blue a.long-term-events__item-header {
  color: #195183;
  font-size: 24px;
}
body.size_small.color_blue .event-date__day-of-the-month {
  font-size: 30px;
}
body.size_small.color_blue .event-date__day-of-the-month small {
  font-size: 24px;
}
body.size_small.color_blue .event-date_weekday {
  font-size: 24px;
  color: #195183;
}
body.size_small.color_blue .event-date:before {
  display: none;
}
body.size_small.color_blue .btn__show-more {
  border-color: #195183;
  color: #195183;
  font-size: 30px;
}
body.size_small.color_blue .subscribe-header {
  font-size: 30px;
  color: #195183;
}
body.size_small.color_blue .subscribe-brief {
  color: #195183;
  font-size: 24px;
}
body.size_small.color_blue .subscribe_block .subscribe-btn {
  font-size: 24px;
  width: 240px;
  color: #195183;
  background: transparent;
  border: 1px solid #195183;
  padding: 0;
  line-height: 53px;
}
body.size_small.color_blue .events-by-date * {
  line-height: 1.35 !important;
}
body.size_small.color_blue .events_date_btn,
body.size_small.color_blue .ui-selectmenu-button,
body.size_small.color_blue .ui-state-focus.ui-selectmenu-button,
body.size_small.color_blue .ui-state-hover.ui-selectmenu-button,
body.size_small.color_blue label.checkbox,
body.size_small.color_blue .ui-menu .ui-menu-item {
  font-size: 24px;
  color: #195183;
  background: #9dd1ff;
}
body.size_small.color_blue .catalogue-search_background {
  background: none;
}
body.size_small.color_blue .catalogue-search_title {
  font-size: 34px;
}
body.size_small.color_blue .catalogue-search_title span {
  font-size: 30px;
}
body.size_small.color_blue .catalogue_search_form {
  box-shadow: none;
  height: 65px;
  border: 2px solid #195183;
  font-size: 24px;
}
body.size_small.color_blue .index_search_option,
body.size_small.color_blue .vf_search_option {
  font-size: 24px;
}
body.size_small.color_blue .button__filled {
  border-radius: 0;
  border: 1px solid #195183;
  font-size: 24px;
  background: #9dd1ff;
}
body.size_small.color_blue .catalogue-item {
  border: 2px solid #195183;
  background: none !important;
}
body.size_small.color_blue .catalogue-item_title {
  font-size: 34px;
}
body.size_small.color_blue .catalogue-item_links a {
  font-size: 24px;
}
body.size_small.color_blue a.button {
  font-size: 24px;
}
body.size_small.color_blue .button__label {
  margin: 25px 0 0;
  box-shadow: none;
  border: 1px solid #195183;
  background: none;
}
body.size_small.color_blue .about_block {
  border-color: #195183;
  background: none;
}
body.size_small.color_blue .catalogue_title {
  color: #195183;
  font-size: 20px;
}
body.size_small.color_blue input,
body.size_small.color_blue textarea {
  border: 2px solid #195183 !important;
  background: #9dd1ff !important;
  font-size: 24px !important;
  color: #195183 !important;
  border-radius: 0px !important;
}
body.size_small.color_blue #fixed_menu {
  background: #9dd1ff;
  box-shadow: none;
  border-bottom: 2px solid #195183;
}
body.size_small.color_blue .fixed_menu_wrapper {
  background: none;
}
body.size_small.color_blue .news_block {
  box-shadow: none;
}
body.size_small.color_blue .news_about {
  padding-left: 10px;
  padding-right: 10px;
}
body.size_small.color_blue .news_tag {
  font-size: 20px;
  color: #195183;
}
body.size_small.color_blue .news_title {
  font-size: 24px;
}
body.size_small.color_blue .visitors_block,
body.size_small.color_blue .info_block,
body.size_small.color_blue .digest_block,
body.size_small.color_blue .libfl_block {
  background: none;
  border: 2px solid #195183;
  box-shadow: none;
}
body.size_small.color_blue .libfl_block {
  padding-left: 30px;
  padding-right: 40px;
}
body.size_small.color_blue .libfl_block .libfl_block__part a:before {
  display: none;
}
body.size_small.color_blue .libfl_block .libfl_block__part a {
  font-size: 30px;
}
body.size_small.color_blue .libfl_block .libfl_block__description span {
  color: #195183;
  font-size: 20px;
}
body.size_small.color_blue .info_block .info_block__address {
  color: #195183;
  font-size: 34px;
}
body.size_small.color_blue .info_block .info_block__address span {
  color: #195183;
  font-size: 20px;
}
body.size_small.color_blue .info_block a {
  font-size: 24px;
}
body.size_small.color_blue .info_block a.info_block__map {
  height: auto;
  background: none;
}
body.size_small.color_blue .visitors_block .visitors_block__title {
  color: #195183;
  font-size: 30px;
  margin-bottom: 20px;
}
body.size_small.color_blue .visitors_block a {
  font-size: 24px;
  font-weight: normal;
}
body.size_small.color_blue .digest_block .digest_block__title {
  color: #195183;
  font-size: 30px;
  margin-bottom: 20px;
}
body.size_small.color_blue .digest_block a {
  font-size: 24px;
}
body.size_small.color_blue #popup_wrapper {
  background: rgba(0, 0, 0, 0.8);
}
body.size_small.color_blue .popup_box {
  background: #9dd1ff !important;
  color: #195183 !important;
  font-size: 24px;
}
body.size_small.color_blue .popup_box * {
  color: #195183 !important;
  line-height: 1.35 !important;
}
body.size_small.color_blue .modal_parts .modal_part .modal_title {
  font-size: 30px;
}
body.size_small.color_blue .modal_parts .modal_part .modal_subtitle {
  font-size: 24px;
  height: auto;
}
body.size_small.color_blue .modal_parts ul.modal_list li,
body.size_small.color_blue .modal_parts ol.modal_list li {
  font-size: 24px;
}
body.size_small.color_blue .modal_parts ul.modal_list li:before,
body.size_small.color_blue .modal_parts ol.modal_list li:before {
  color: #195183;
}
body.size_small.color_blue .modal_cta {
  background: none;
  font-size: 34px;
}
body.size_small.color_blue .modal_welcome span {
  font-size: 34px;
}
body.size_small.color_blue .modal_welcome:before {
  display: none;
}
body.size_small.color_blue .modal_welcome a.btn {
  background: none;
  border-radius: 0;
  border: 2px solid #195183;
  font-size: 24px;
  width: auto;
  height: auto;
  line-height: 1 !important;
}
body.size_small.color_blue .modal_rules a.link {
  font-size: 20px;
}
body.size_small.color_blue #popup_reader.popup_box .decoration_left,
body.size_small.color_blue #popup_reader.popup_box .decoration_right {
  display: none;
}
body.size_small.color_blue .event-info {
  width: 600px;
  height: auto;
  background: #9dd1ff;
  color: #195183;
}
body.size_small.color_blue .inner-event-type span {
  font-size: 20px;
}
body.size_small.color_blue .event-info__title,
body.size_small.color_blue .new-info__title {
  font-size: 30px;
}
body.size_small.color_blue .event-info__place {
  font-size: 20px;
}
body.size_small.color_blue .event-info__shedule,
body.size_small.color_blue .new-info__shedule {
  position: relative;
  width: 100%;
  bottom: auto;
  line-height: 1.35;
  margin-top: 20px;
}
body.size_small.color_blue .event-info__date {
  font-size: 20px;
}
body.size_small.color_blue .event-info__time {
  font-size: 30px;
  color: #195183;
}
body.size_small.color_blue .event-info__admission {
  width: 250px;
}
body.size_small.color_blue .event-info__admission * {
  color: #195183;
}
body.size_small.color_blue .event-info__admission--docs {
  font-size: 20px;
  color: #195183;
}
body.size_small.color_blue .block_wrapper * {
  font-size: 24px;
  color: #195183 !important;
}
body.size_small.color_blue .block_wrapper,
body.size_small.color_blue .cutted_block,
body.size_small.color_blue .image_left_block,
body.size_small.color_blue .image_right_block,
body.size_small.color_blue .old_block p,
body.size_small.color_blue .text_block {
  font-size: 24px;
}
body.size_small.color_blue #news .block_wrapper:first-child .text_block {
  font-size: 30px;
}
body.size_small.color_blue .block_wrapper h2 {
  font-size: 34px;
  line-height: 1.4;
}
body.size_small.color_blue .block_wrapper h3 {
  font-size: 30px;
  line-height: 1.4;
}
body.size_small.color_blue .block_wrapper h4 {
  font-size: 24px;
  line-height: 1.4;
}
body.size_small.color_blue .image_block span.set,
body.size_small.color_blue .image_left_block span.set,
body.size_small.color_blue .image_right_block span.set,
body.size_small.color_blue .image_block .image_desc,
body.size_small.color_blue .image_left_block .image_desc,
body.size_small.color_blue .image_right_block .image_desc {
  font-size: 20px;
}
body.size_small.color_blue .button_link,
body.size_small.color_blue .registration_link,
body.size_small.color_blue .registration_link_instruction {
  font-size: 20px;
}
body.size_small.color_blue .button_link .button_with_link,
body.size_small.color_blue .registration_link .button_with_link,
body.size_small.color_blue .registration_link_instruction .button_with_link {
  font-size: 20px;
  background: none;
  border: 2px solid #195183;
  height: auto;
  line-height: 1;
  padding: 20px;
  width: auto;
}
body.size_small.color_blue .registration_link {
  border-color: #195183;
}
body.size_small.color_blue .list_ul ul {
  font-size: 24px;
}
body.size_small.color_blue .list_ol ol {
  font-size: 24px;
}
body.size_small.color_blue .cite_block .cite {
  font-size: 34px;
}
body.size_small.color_blue .cite_block .cite_by {
  font-size: 24px;
}
body.size_small.color_blue .collections_block .collection_about a {
  font-size: 30px;
}
body.size_small.color_blue .collections_block .collection_title {
  font-size: 34px;
}
body.size_small.color_blue .collections_block .collection_info {
  font-size: 20px;
}
body.size_small.color_blue .collections_block .collection_info a {
  font-size: 20px;
}
body.size_small.color_blue .cutted_block .cut_block_button {
  font-size: 20px;
}
body.size_small.color_blue .event_organizer,
body.size_small.color_blue .event_additional_wrapper > span {
  font-size: 20px;
}
body.size_small.color_blue .btn_social {
  font-size: 20px;
  background-color: #9dd1ff !important;
  color: #195183 !important;
  border: 1px solid #195183;
  height: auto;
  width: auto;
  padding: 5px 10px;
}
body.size_small.color_blue .bottom_block {
  border-color: #195183;
}
body.size_small.color_blue .tagline {
  font-size: 24px;
}
body.size_small.color_blue .tagline small {
  font-size: 20px;
  color: #195183;
}
body.size_small.color_blue .page-views {
  font-size: 20px;
  color: #195183;
}
body.size_small.color_blue .article-description__header {
  font-size: 34px;
}
body.size_small.color_blue .facilities_banner a.facilities_banner__description {
  font-size: 30px;
}
body.size_small.color_blue .accordion dt a {
  font-size: 24px;
}
body.size_small.color_blue .accordion dd .dd-row .dd-head {
  color: #195183;
}
body.size_small.color_blue .accordion dd .dd-row .dd-cell,
body.size_small.color_blue .accordion dd .dd-row .dd-head {
  font-size: 20px;
  color: #195183;
}
body.size_small.color_blue .accordion span {
  font-size: 20px;
  color: #195183;
}
body.size_small.color_blue .article-nav-childs {
  border: 2px solid #195183;
  background: none;
  font-size: 20px;
}
body.size_small.color_blue .article-nav-childs__header {
  background: #9dd1ff;
  border-bottom: 2px solid #195183;
  font-size: inherit;
  color: #195183;
}
body.size_small.color_blue .inner_pages_breadcrumbs {
  font-size: 20px;
  color: #195183;
}
body.size_small.color_blue .contact_wrapper {
  background: none;
  border: 2px solid #195183;
}
body.size_small.color_blue .contact_text_wrapper,
body.size_small.color_blue .contact_text_wrapper {
  color: #195183;
  font-size: 24px;
}
body.size_small.color_blue .contact_btn {
  background: #9dd1ff;
  color: #195183 !important;
  border: 1px solid #195183;
  box-shadow: none;
  font-size: 20px;
}
body.size_small.color_blue .note {
  font-size: 20px;
}
body.size_small.color_blue .scheme_header {
  font-size: 30px;
  line-height: 1.4;
}
body.size_small.color_blue .section_switch_block {
  font-size: 24px;
  color: #195183;
  text-decoration: underline;
}
body.size_small.color_blue .section_info_header {
  font-size: 30px;
}
body.size_small.color_blue .section_info_text {
  font-size: 24px;
}
body.size_small.color_blue .section_info_print {
  font-size: 24px;
}
body.size_small.color_blue .event_content,
body.size_small.color_blue .page_content {
  box-shadow: none;
}
body.size_small.color_blue .scheme_switch_block {
  padding: 0;
}
body.size_small.color_blue a.departments__item {
  font-size: 24px;
  height: auto;
  margin-bottom: 20px;
}
body.size_small.color_blue .contact_cell span {
  font-size: 20px !important;
}
body.size_small.color_blue .logo_wrapper {
  background: url('/images/svg/accent-logo-blue.svg') top center no-repeat;
}
body.size_small.color_blue .logo_wrapper img {
  opacity: 0;
  visibility: hidden;
}
body.size_small.color_blue .fall_through_link {
  color: #195183;
}
body.size_small.color_blue .events-filters,
body.size_small.color_blue .events-filters__wrapper {
  background: #9dd1ff;
  border: none;
}
body.size_small.color_blue .icheckbox_minimal {
  border-color: #195183;
}
body.size_small.color_blue .news_block {
  background-color: none;
}
body.size_small.color_blue .new-info {
  background: #9dd1ff;
}
body.size_small.color_blue .parking_note {
  float: none;
  margin-top: 100px;
  text-align: left;
}
body.size_small.color_blue .parking_note:before {
  content: ' ';
  display: block;
  clear: both;
}
body.size_small.color_blue .subway_label {
  background-color: #9dd1ff;
  border: 1px solid #195183;
}
body.size_small.color_blue .event_content,
body.size_small.color_blue .page_content {
  background: none;
}
body.size_small.color_blue .scheme_header,
body.size_small.color_blue .scheme_switch_block,
body.size_small.color_blue ol.sections_switcher li:before {
  color: #195183;
}
body.size_small.color_blue .scheme_switch_block.active {
  background: #195183;
  color: #9dd1ff;
}
body.size_small.color_blue .section_switch_block.active {
  background: #195183;
  color: #9dd1ff;
}
body.size_small.color_blue .section_info.active {
  background: #9dd1ff;
}
body.size_small.color_blue .section_info_print {
  color: #195183;
  text-decoration: underline;
}
body.size_small.color_blue .images_gallery .gallery_image {
  background: #9dd1ff;
}
body.size_small.color_blue .list_ul ul li:before {
  background: #195183;
}
body.size_small.color_blue .catalogue_search_form,
body.size_small.color_blue .vf_search_form {
  border: none;
  background: none;
}
body.size_small.color_blue .work_time a,
body.size_small.color_blue a.work_time {
  border: none;
}
body.size_small.color_blue .long-term-events__item {
  border-color: #195183;
}
body.size_small.color_blue .news_block {
  background: #9dd1ff;
  border: 1px solid #195183;
  overflow: hidden;
}
body.size_small.color_blue .inner_pages_breadcrumbs {
  border-color: #195183;
}
body.size_small.color_blue .contact_table .contact_row .contact_cell {
  border-color: #195183;
}
body.size_small.color_blue .event_organizer:after {
  border-color: #195183;
}
body.size_small.color_blue .vf_search_select {
  box-shadow: none;
  background: #9dd1ff;
  border: 1px solid #195183;
  width: 300px;
}
body.size_small.color_blue .index_search_option,
body.size_small.color_blue .vf_search_option {
  border-color: #195183;
  color: #195183;
}
body.size_small.color_blue .header {
  height: auto;
}
body.size_small.color_blue .index_subscribe_block .subscribe-brief {
  line-height: 1.35;
}
body.size_small.color_blue #footer {
  line-height: 1.35;
}
body.size_small.color_blue .footer_copy_text {
  line-height: 1.35;
}
body.size_small.color_blue .footer_social_wrapper .footer_list {
  line-height: 1.35;
}
body.size_small.color_blue .nouvelle_small_text {
  color: #195183;
}
body.size_small.color_blue .top-banner {
  background-color: #9dd1ff;
  border: 2px solid #195183;
  font-size: 24px;
}
body.size_small.color_blue .top-banner__close {
  background-size: 35px;
}
body.size_small.color_blue .flex-direction-nav a {
  width: 65px;
  height: 65px;
  background-size: 35px;
  background-color: #9dd1ff;
  border: 1px solid #195183;
}
body.size_small.color_blue .flex-nav-prev {
  margin-right: 15px;
}
body.size_small.color_blue .flex-control-paging li a {
  width: 15px;
  height: 15px;
  background-color: transparent;
  border: 1px solid #195183;
}
body.size_small.color_blue .flex-control-paging li a.flex-active {
  background-color: #195183;
}
body.size_small.color_blue .departments_block .departments_block__wrapper .departments_block__part a.departments_block__item img {
  height: auto;
  width: 65px;
}
body.size_small.color_blue a.menu__link_footer img {
  width: 30px !important;
}
body.size_small.color_blue .social-btn_vk {
  background-size: 30px;
}
body.size_small.color_blue .social-btn_fb {
  background-size: 20px;
}
body.size_small.color_blue .social-btn_ig {
  background-size: 22px;
}
body.size_small.color_blue .social-btn_yt {
  background-size: 28px;
}
body.size_small.color_blue .pro_column {
  width: 33%;
}
body.size_small.color_blue .index_news {
  width: 65%;
}
body.size_small.color_blue .index_pro_block .index_pro_content {
  padding-left: 0;
}
body.size_small.color_blue .events-item_background .event-cycle {
  background-color: #9dd1ff;
  font-size: 24px;
}
body.size_small.color_blue .news_about .centenary_tag,
body.size_small.color_blue .event-tag {
  color: #195183;
  background-color: #9dd1ff;
  border: 1px solid #195183;
  font-size: 20px;
}
body.size_small.color_blue .side_social_wrapper > ul > li {
  width: 48px;
  height: 48px;
  border-color: #195183;
}
body.size_small.color_blue .side_social_wrapper > ul > li > a > svg {
  fill: #195183;
  width: 22px;
  height: 22px;
}
body.size_small.color_blue .partners_column a.partners_block {
  border: 2px solid #195183;
  padding: 10px 15px;
}
body.size_small.color_blue .partners_column a.partners_block .partners_block_title {
  font-size: 24px;
  color: #195183;
}
body.size_small.color_blue .test-wrapper {
  grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
}
body.size_small.color_blue .long-term-events {
  width: 100%;
}
body.size_small.color_blue .news_wrapper.listing_block {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
  grid-gap: 23px;
}
body.size_small.color_blue .news_block {
  float: none;
  margin: 0 !important;
  width: auto;
}
body.size_small.color_blue .news_image {
  width: 100%;
  background-position: center;
}
body.size_small.color_blue .news_title {
  line-height: 1.2;
}
body.size_medium.color_blackonwhite {
  font-size: 30px;
  color: #000;
  background-color: #fff;
  padding-top: 120px;
}
body.size_medium.color_blackonwhite .fixed_menu_wrapper .js-login-screen,
body.size_medium.color_blackonwhite .fixed_menu_wrapper .js-become-member {
  display: none;
}
body.size_medium.color_blackonwhite #special_version_controls {
  border: 1px solid #000;
  background: #fff;
  margin-bottom: 6px;
  border-radius: 0 0 4px 4px;
  border-top: 0;
  width: 90%;
  max-width: 1200px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99999;
  padding: 20px;
  text-align: center;
  display: block;
}
body.size_medium.color_blackonwhite .special_version_controls_group {
  display: inline-block;
  margin-right: 40px;
  margin-bottom: 30px;
}
body.size_medium.color_blackonwhite .special_version_controls_label {
  display: inline-block;
  vertical-align: top;
  padding-top: 6px;
  font-size: 20px;
}
body.size_medium.color_blackonwhite .special_version_controls_select {
  display: inline-block;
  vertical-align: top;
  margin-left: 10px;
}
body.size_medium.color_blackonwhite .special_version_controls_point {
  display: block;
  vertical-align: top;
  position: relative;
  background: #fff;
  border: 1px solid #000;
  margin-right: -1px;
  cursor: pointer;
  height: 41px;
  width: 41px;
  text-align: center;
  line-height: 1;
  padding: 10px 12px 6px;
  font-size: 20px;
  float: left;
}
body.size_medium.color_blackonwhite .special_version_controls_point:first-child {
  border-radius: 4px 0 0 4px;
}
body.size_medium.color_blackonwhite .special_version_controls_point:last-child {
  border-radius: 0 4px 4px 0;
}
body.size_medium.color_blackonwhite .special_version_controls_point.active:before {
  content: ' ';
  display: block;
  position: absolute;
  width: 100%;
  height: 1px;
  border-bottom: 2px dashed #000;
  left: 0;
  bottom: -7px;
}
body.size_medium.color_blackonwhite .special_version_controls_select[data-parameter="size"] .special_version_controls_point {
  color: #000;
  background-color: #fff;
}
body.size_medium.color_blackonwhite .special_version_controls_select[data-parameter="size"] .special_version_controls_point[data-value="medium"] {
  font-size: 30px;
  padding: 4px 0 0;
}
body.size_medium.color_blackonwhite .special_version_controls_select[data-parameter="size"] .special_version_controls_point[data-value="large"] {
  font-size: 40px;
  padding: 0;
}
body.size_medium.color_blackonwhite .special_version_controls_select[data-parameter="color"] .special_version_controls_point[data-value="blackonwhite"] {
  color: #000;
  background: #fff;
}
body.size_medium.color_blackonwhite .special_version_controls_select[data-parameter="color"] .special_version_controls_point[data-value="whiteonblack"] {
  color: #fff;
  background: #000;
}
body.size_medium.color_blackonwhite .special_version_controls_select[data-parameter="color"] .special_version_controls_point[data-value="blue"] {
  background: #9dd1ff;
  color: #195183;
}
body.size_medium.color_blackonwhite .special_version_controls_select[data-parameter="graphics"] .special_version_controls_point[data-value="normal"] {
  background: #0000f3;
  color: #fff;
}
body.size_medium.color_blackonwhite .special_version_controls_select[data-parameter="graphics"] .special_version_controls_point[data-value="mono"] {
  background: #ddd;
}
body.size_medium.color_blackonwhite .special_version_controls_select[data-parameter="graphics"] .special_version_controls_point[data-value="disabled"] {
  color: #fff;
}
body.size_medium.color_blackonwhite a.menu__item.link.link_blue:first-of-type {
  margin-right: 0;
}
body.size_medium.color_blackonwhite .header__menu .menu__item {
  margin-right: 25px;
}
body.size_medium.color_blackonwhite .layout-wrapper {
  color: #000;
  background-color: #fff;
}
body.size_medium.color_blackonwhite .infobar {
  height: auto;
  font-size: 30px;
  background: #fff;
}
body.size_medium.color_blackonwhite .infobar:after {
  content: ' ';
  display: block;
  clear: both;
}
body.size_medium.color_blackonwhite .infobar__link,
body.size_medium.color_blackonwhite .infobar__link_academy {
  color: #000;
  font-size: 26px;
}
body.size_medium.color_blackonwhite a.infobar__link_academy:before {
  display: none;
}
body.size_medium.color_blackonwhite a.menu__link {
  font-size: 30px;
  color: #000;
}
body.size_medium.color_blackonwhite a {
  color: #000 !important;
  text-decoration: underline !important;
}
body.size_medium.color_blackonwhite .become-member {
  font-size: 30px;
}
body.size_medium.color_blackonwhite #nav {
  border: none;
  margin: 40px 0;
}
body.size_medium.color_blackonwhite .full-index {
  font-size: 30px;
}
body.size_medium.color_blackonwhite .full-index:after {
  display: none;
}
body.size_medium.color_blackonwhite .search_switch {
  font-size: 30px;
  color: #000;
}
body.size_medium.color_blackonwhite .quick-links {
  margin-left: 50px;
  width: 350px;
}
body.size_medium.color_blackonwhite a.quick-links__item,
body.size_medium.color_blackonwhite a.closing-time {
  font-size: 30px;
}
body.size_medium.color_blackonwhite a.closing-time:after {
  display: none;
}
body.size_medium.color_blackonwhite .wisemen_block {
  display: none !important;
}
body.size_medium.color_blackonwhite .vf_search_wrapper {
  width: 540px;
}
body.size_medium.color_blackonwhite input#index_search,
body.size_medium.color_blackonwhite input#search {
  font-size: 30px;
}
body.size_medium.color_blackonwhite .index_header {
  font-size: 40px;
}
body.size_medium.color_blackonwhite .index_subscribe_block {
  height: auto;
  margin-bottom: 20px;
}
body.size_medium.color_blackonwhite .index_subscribe_block .subscribe-brief {
  color: #000;
  font-size: 26px;
}
body.size_medium.color_blackonwhite .index_subscribe_block .subscribe-brief strong {
  font-size: 30px;
  color: #000;
  display: block;
  text-decoration: underline;
}
body.size_medium.color_blackonwhite .departments_block {
  background: #fff;
  padding: 40px;
  box-shadow: none;
}
body.size_medium.color_blackonwhite .departments_block .departments_block__wrapper .departments_block__subheader {
  color: #000;
  font-size: 40px;
}
body.size_medium.color_blackonwhite .departments_block .departments_block__wrapper .departments_block__part a.departments_block__item {
  border: none;
  margin-bottom: 10px;
}
body.size_medium.color_blackonwhite .departments_block .departments_block__wrapper .departments_block__part a.departments_block__item span {
  color: #000;
  font-size: 30px;
  line-height: 1.35;
}
body.size_medium.color_blackonwhite .departments_block__decoration {
  display: none;
}
body.size_medium.color_blackonwhite .fall_through_link {
  font-size: 30px;
}
body.size_medium.color_blackonwhite .fall_through_link:after {
  display: none;
}
body.size_medium.color_blackonwhite .nouvelle_desc a,
body.size_medium.color_blackonwhite .nouvelle_desc small {
  color: #000;
  font-size: 26px;
}
body.size_medium.color_blackonwhite .nouvelle_small_text,
body.size_medium.color_blackonwhite .nouvelle_title {
  font-size: 30px;
}
body.size_medium.color_blackonwhite .index_nouvelle_main .nouvelle_title {
  font-size: 36px;
}
body.size_medium.color_blackonwhite .index_pro_block .index_pro_content {
  background-color: #fff;
}
body.size_medium.color_blackonwhite .index_pro_block .index_pro_content .index_pro_content_title,
body.size_medium.color_blackonwhite .index_pro_block .index_pro_content .index_pro_content_text {
  font-size: 30px;
}
body.size_medium.color_blackonwhite .index_pro_block .index_pro_content small {
  color: #000;
  font-size: 26px;
}
body.size_medium.color_blackonwhite .index_news_wrapper * {
  line-height: 1.35 !important;
}
body.size_medium.color_blackonwhite .collection_links a {
  font-size: 26px;
}
body.size_medium.color_blackonwhite .collection_info a {
  font-size: 26px;
}
body.size_medium.color_blackonwhite .index_collections_wrapper .collection_title {
  font-size: 36px;
}
body.size_medium.color_blackonwhite .index_collections_wrapper .collection_about small {
  font-size: 26px;
  color: #000 !important;
}
body.size_medium.color_blackonwhite .header__menu .menu__item {
  font-size: 30px;
}
body.size_medium.color_blackonwhite .menu__item_active {
  border-bottom: 2px dashed #000;
}
body.size_medium.color_blackonwhite #footer {
  color: #000;
  background: #fff;
  font-size: 26px;
}
body.size_medium.color_blackonwhite .footer_social_header {
  color: #000;
  font-size: 30px;
}
body.size_medium.color_blackonwhite a.menu__link_footer {
  font-size: 30px;
}
body.size_medium.color_blackonwhite .footer_header {
  font-size: 30px;
  color: #000;
}
body.size_medium.color_blackonwhite .work_time p {
  font-size: 26px;
}
body.size_medium.color_blackonwhite .footer_column_left {
  max-width: 50%;
}
body.size_medium.color_blackonwhite .footer_copy {
  border-top-color: #fff;
  color: #000;
  font-size: 26px;
}
body.size_medium.color_blackonwhite .footer_banner img {
  display: none;
}
body.size_medium.color_blackonwhite .footer_banner a {
  font-size: 30px;
}
body.size_medium.color_blackonwhite .events-item__image {
  background: #fff !important;
}
body.size_medium.color_blackonwhite .event-type a,
body.size_medium.color_blackonwhite .events_cat a,
body.size_medium.color_blackonwhite .inner-event-type a,
body.size_medium.color_blackonwhite .new-type a {
  font-size: 26px;
}
body.size_medium.color_blackonwhite .events-item__place {
  font-size: 30px;
}
body.size_medium.color_blackonwhite .events-item__header {
  font-size: 36px;
}
body.size_medium.color_blackonwhite .events-item__time {
  font-size: 40px;
}
body.size_medium.color_blackonwhite .event-type a,
body.size_medium.color_blackonwhite .events_cat a,
body.size_medium.color_blackonwhite .inner-event-type a,
body.size_medium.color_blackonwhite .new-type a,
body.size_medium.color_blackonwhite .events-item__place,
body.size_medium.color_blackonwhite .events-item__header,
body.size_medium.color_blackonwhite .events-item__time {
  color: #000;
}
body.size_medium.color_blackonwhite .long-term-events {
  background: #fff;
}
body.size_medium.color_blackonwhite .long-term-events__title,
body.size_medium.color_blackonwhite a.long-term-events__item-header {
  color: #000;
  font-size: 30px;
}
body.size_medium.color_blackonwhite .event-date__day-of-the-month {
  font-size: 36px;
}
body.size_medium.color_blackonwhite .event-date__day-of-the-month small {
  font-size: 30px;
}
body.size_medium.color_blackonwhite .event-date_weekday {
  font-size: 30px;
  color: #000;
}
body.size_medium.color_blackonwhite .event-date:before {
  display: none;
}
body.size_medium.color_blackonwhite .btn__show-more {
  border-color: #000;
  color: #000;
  font-size: 36px;
}
body.size_medium.color_blackonwhite .subscribe-header {
  font-size: 36px;
  color: #000;
}
body.size_medium.color_blackonwhite .subscribe-brief {
  color: #000;
  font-size: 30px;
}
body.size_medium.color_blackonwhite .subscribe_block .subscribe-btn {
  font-size: 30px;
  width: 240px;
  color: #000;
  background: transparent;
  border: 1px solid #000;
  padding: 0;
  line-height: 65px;
}
body.size_medium.color_blackonwhite .events-by-date * {
  line-height: 1.35 !important;
}
body.size_medium.color_blackonwhite .events_date_btn,
body.size_medium.color_blackonwhite .ui-selectmenu-button,
body.size_medium.color_blackonwhite .ui-state-focus.ui-selectmenu-button,
body.size_medium.color_blackonwhite .ui-state-hover.ui-selectmenu-button,
body.size_medium.color_blackonwhite label.checkbox,
body.size_medium.color_blackonwhite .ui-menu .ui-menu-item {
  font-size: 30px;
  color: #000;
  background: #fff;
}
body.size_medium.color_blackonwhite .catalogue-search_background {
  background: none;
}
body.size_medium.color_blackonwhite .catalogue-search_title {
  font-size: 40px;
}
body.size_medium.color_blackonwhite .catalogue-search_title span {
  font-size: 36px;
}
body.size_medium.color_blackonwhite .catalogue_search_form {
  box-shadow: none;
  height: 65px;
  border: 2px solid #000;
  font-size: 30px;
}
body.size_medium.color_blackonwhite .index_search_option,
body.size_medium.color_blackonwhite .vf_search_option {
  font-size: 30px;
}
body.size_medium.color_blackonwhite .button__filled {
  border-radius: 0;
  border: 1px solid #000;
  font-size: 30px;
  background: #fff;
}
body.size_medium.color_blackonwhite .catalogue-item {
  border: 2px solid #000;
  background: none !important;
}
body.size_medium.color_blackonwhite .catalogue-item_title {
  font-size: 40px;
}
body.size_medium.color_blackonwhite .catalogue-item_links a {
  font-size: 30px;
}
body.size_medium.color_blackonwhite a.button {
  font-size: 30px;
}
body.size_medium.color_blackonwhite .button__label {
  margin: 25px 0 0;
  box-shadow: none;
  border: 1px solid #000;
  background: none;
}
body.size_medium.color_blackonwhite .about_block {
  border-color: #000;
  background: none;
}
body.size_medium.color_blackonwhite .catalogue_title {
  color: #000;
  font-size: 26px;
}
body.size_medium.color_blackonwhite input,
body.size_medium.color_blackonwhite textarea {
  border: 2px solid #000 !important;
  background: #fff !important;
  font-size: 30px !important;
  color: #000 !important;
  border-radius: 0px !important;
}
body.size_medium.color_blackonwhite #fixed_menu {
  background: #fff;
  box-shadow: none;
  border-bottom: 2px solid #000;
}
body.size_medium.color_blackonwhite .fixed_menu_wrapper {
  background: none;
}
body.size_medium.color_blackonwhite .news_block {
  box-shadow: none;
}
body.size_medium.color_blackonwhite .news_about {
  padding-left: 10px;
  padding-right: 10px;
}
body.size_medium.color_blackonwhite .news_tag {
  font-size: 26px;
  color: #000;
}
body.size_medium.color_blackonwhite .news_title {
  font-size: 30px;
}
body.size_medium.color_blackonwhite .visitors_block,
body.size_medium.color_blackonwhite .info_block,
body.size_medium.color_blackonwhite .digest_block,
body.size_medium.color_blackonwhite .libfl_block {
  background: none;
  border: 2px solid #000;
  box-shadow: none;
}
body.size_medium.color_blackonwhite .libfl_block {
  padding-left: 30px;
  padding-right: 40px;
}
body.size_medium.color_blackonwhite .libfl_block .libfl_block__part a:before {
  display: none;
}
body.size_medium.color_blackonwhite .libfl_block .libfl_block__part a {
  font-size: 36px;
}
body.size_medium.color_blackonwhite .libfl_block .libfl_block__description span {
  color: #000;
  font-size: 26px;
}
body.size_medium.color_blackonwhite .info_block .info_block__address {
  color: #000;
  font-size: 40px;
}
body.size_medium.color_blackonwhite .info_block .info_block__address span {
  color: #000;
  font-size: 26px;
}
body.size_medium.color_blackonwhite .info_block a {
  font-size: 30px;
}
body.size_medium.color_blackonwhite .info_block a.info_block__map {
  height: auto;
  background: none;
}
body.size_medium.color_blackonwhite .visitors_block .visitors_block__title {
  color: #000;
  font-size: 36px;
  margin-bottom: 20px;
}
body.size_medium.color_blackonwhite .visitors_block a {
  font-size: 30px;
  font-weight: normal;
}
body.size_medium.color_blackonwhite .digest_block .digest_block__title {
  color: #000;
  font-size: 36px;
  margin-bottom: 20px;
}
body.size_medium.color_blackonwhite .digest_block a {
  font-size: 30px;
}
body.size_medium.color_blackonwhite #popup_wrapper {
  background: rgba(0, 0, 0, 0.8);
}
body.size_medium.color_blackonwhite .popup_box {
  background: #fff !important;
  color: #000 !important;
  font-size: 30px;
}
body.size_medium.color_blackonwhite .popup_box * {
  color: #000 !important;
  line-height: 1.35 !important;
}
body.size_medium.color_blackonwhite .modal_parts .modal_part .modal_title {
  font-size: 36px;
}
body.size_medium.color_blackonwhite .modal_parts .modal_part .modal_subtitle {
  font-size: 30px;
  height: auto;
}
body.size_medium.color_blackonwhite .modal_parts ul.modal_list li,
body.size_medium.color_blackonwhite .modal_parts ol.modal_list li {
  font-size: 30px;
}
body.size_medium.color_blackonwhite .modal_parts ul.modal_list li:before,
body.size_medium.color_blackonwhite .modal_parts ol.modal_list li:before {
  color: #000;
}
body.size_medium.color_blackonwhite .modal_cta {
  background: none;
  font-size: 40px;
}
body.size_medium.color_blackonwhite .modal_welcome span {
  font-size: 40px;
}
body.size_medium.color_blackonwhite .modal_welcome:before {
  display: none;
}
body.size_medium.color_blackonwhite .modal_welcome a.btn {
  background: none;
  border-radius: 0;
  border: 2px solid #000;
  font-size: 30px;
  width: auto;
  height: auto;
  line-height: 1 !important;
}
body.size_medium.color_blackonwhite .modal_rules a.link {
  font-size: 26px;
}
body.size_medium.color_blackonwhite #popup_reader.popup_box .decoration_left,
body.size_medium.color_blackonwhite #popup_reader.popup_box .decoration_right {
  display: none;
}
body.size_medium.color_blackonwhite .event-info {
  width: 600px;
  height: auto;
  background: #fff;
  color: #000;
}
body.size_medium.color_blackonwhite .inner-event-type span {
  font-size: 26px;
}
body.size_medium.color_blackonwhite .event-info__title,
body.size_medium.color_blackonwhite .new-info__title {
  font-size: 36px;
}
body.size_medium.color_blackonwhite .event-info__place {
  font-size: 26px;
}
body.size_medium.color_blackonwhite .event-info__shedule,
body.size_medium.color_blackonwhite .new-info__shedule {
  position: relative;
  width: 100%;
  bottom: auto;
  line-height: 1.35;
  margin-top: 20px;
}
body.size_medium.color_blackonwhite .event-info__date {
  font-size: 26px;
}
body.size_medium.color_blackonwhite .event-info__time {
  font-size: 36px;
  color: #000;
}
body.size_medium.color_blackonwhite .event-info__admission {
  width: 250px;
}
body.size_medium.color_blackonwhite .event-info__admission * {
  color: #000;
}
body.size_medium.color_blackonwhite .event-info__admission--docs {
  font-size: 26px;
  color: #000;
}
body.size_medium.color_blackonwhite .block_wrapper * {
  font-size: 30px;
  color: #000 !important;
}
body.size_medium.color_blackonwhite .block_wrapper,
body.size_medium.color_blackonwhite .cutted_block,
body.size_medium.color_blackonwhite .image_left_block,
body.size_medium.color_blackonwhite .image_right_block,
body.size_medium.color_blackonwhite .old_block p,
body.size_medium.color_blackonwhite .text_block {
  font-size: 30px;
}
body.size_medium.color_blackonwhite #news .block_wrapper:first-child .text_block {
  font-size: 36px;
}
body.size_medium.color_blackonwhite .block_wrapper h2 {
  font-size: 40px;
  line-height: 1.4;
}
body.size_medium.color_blackonwhite .block_wrapper h3 {
  font-size: 36px;
  line-height: 1.4;
}
body.size_medium.color_blackonwhite .block_wrapper h4 {
  font-size: 30px;
  line-height: 1.4;
}
body.size_medium.color_blackonwhite .image_block span.set,
body.size_medium.color_blackonwhite .image_left_block span.set,
body.size_medium.color_blackonwhite .image_right_block span.set,
body.size_medium.color_blackonwhite .image_block .image_desc,
body.size_medium.color_blackonwhite .image_left_block .image_desc,
body.size_medium.color_blackonwhite .image_right_block .image_desc {
  font-size: 26px;
}
body.size_medium.color_blackonwhite .button_link,
body.size_medium.color_blackonwhite .registration_link,
body.size_medium.color_blackonwhite .registration_link_instruction {
  font-size: 26px;
}
body.size_medium.color_blackonwhite .button_link .button_with_link,
body.size_medium.color_blackonwhite .registration_link .button_with_link,
body.size_medium.color_blackonwhite .registration_link_instruction .button_with_link {
  font-size: 26px;
  background: none;
  border: 2px solid #000;
  height: auto;
  line-height: 1;
  padding: 20px;
  width: auto;
}
body.size_medium.color_blackonwhite .registration_link {
  border-color: #000;
}
body.size_medium.color_blackonwhite .list_ul ul {
  font-size: 30px;
}
body.size_medium.color_blackonwhite .list_ol ol {
  font-size: 30px;
}
body.size_medium.color_blackonwhite .cite_block .cite {
  font-size: 40px;
}
body.size_medium.color_blackonwhite .cite_block .cite_by {
  font-size: 30px;
}
body.size_medium.color_blackonwhite .collections_block .collection_about a {
  font-size: 36px;
}
body.size_medium.color_blackonwhite .collections_block .collection_title {
  font-size: 40px;
}
body.size_medium.color_blackonwhite .collections_block .collection_info {
  font-size: 26px;
}
body.size_medium.color_blackonwhite .collections_block .collection_info a {
  font-size: 26px;
}
body.size_medium.color_blackonwhite .cutted_block .cut_block_button {
  font-size: 26px;
}
body.size_medium.color_blackonwhite .event_organizer,
body.size_medium.color_blackonwhite .event_additional_wrapper > span {
  font-size: 26px;
}
body.size_medium.color_blackonwhite .btn_social {
  font-size: 26px;
  background-color: #fff !important;
  color: #000 !important;
  border: 1px solid #000;
  height: auto;
  width: auto;
  padding: 5px 10px;
}
body.size_medium.color_blackonwhite .bottom_block {
  border-color: #000;
}
body.size_medium.color_blackonwhite .tagline {
  font-size: 30px;
}
body.size_medium.color_blackonwhite .tagline small {
  font-size: 26px;
  color: #000;
}
body.size_medium.color_blackonwhite .page-views {
  font-size: 26px;
  color: #000;
}
body.size_medium.color_blackonwhite .article-description__header {
  font-size: 40px;
}
body.size_medium.color_blackonwhite .facilities_banner a.facilities_banner__description {
  font-size: 36px;
}
body.size_medium.color_blackonwhite .accordion dt a {
  font-size: 30px;
}
body.size_medium.color_blackonwhite .accordion dd .dd-row .dd-head {
  color: #000;
}
body.size_medium.color_blackonwhite .accordion dd .dd-row .dd-cell,
body.size_medium.color_blackonwhite .accordion dd .dd-row .dd-head {
  font-size: 26px;
  color: #000;
}
body.size_medium.color_blackonwhite .accordion span {
  font-size: 26px;
  color: #000;
}
body.size_medium.color_blackonwhite .article-nav-childs {
  border: 2px solid #000;
  background: none;
  font-size: 26px;
}
body.size_medium.color_blackonwhite .article-nav-childs__header {
  background: #fff;
  border-bottom: 2px solid #000;
  font-size: inherit;
  color: #000;
}
body.size_medium.color_blackonwhite .inner_pages_breadcrumbs {
  font-size: 26px;
  color: #000;
}
body.size_medium.color_blackonwhite .contact_wrapper {
  background: none;
  border: 2px solid #000;
}
body.size_medium.color_blackonwhite .contact_text_wrapper,
body.size_medium.color_blackonwhite .contact_text_wrapper {
  color: #000;
  font-size: 30px;
}
body.size_medium.color_blackonwhite .contact_btn {
  background: #fff;
  color: #000 !important;
  border: 1px solid #000;
  box-shadow: none;
  font-size: 26px;
}
body.size_medium.color_blackonwhite .note {
  font-size: 26px;
}
body.size_medium.color_blackonwhite .scheme_header {
  font-size: 36px;
  line-height: 1.4;
}
body.size_medium.color_blackonwhite .section_switch_block {
  font-size: 30px;
  color: #000;
  text-decoration: underline;
}
body.size_medium.color_blackonwhite .section_info_header {
  font-size: 36px;
}
body.size_medium.color_blackonwhite .section_info_text {
  font-size: 30px;
}
body.size_medium.color_blackonwhite .section_info_print {
  font-size: 30px;
}
body.size_medium.color_blackonwhite .event_content,
body.size_medium.color_blackonwhite .page_content {
  box-shadow: none;
}
body.size_medium.color_blackonwhite .scheme_switch_block {
  padding: 0;
}
body.size_medium.color_blackonwhite a.departments__item {
  font-size: 30px;
  height: auto;
  margin-bottom: 20px;
}
body.size_medium.color_blackonwhite .contact_cell span {
  font-size: 26px !important;
}
body.size_medium.color_blackonwhite .logo_wrapper {
  background: url('/images/svg/accent-logo-black.svg') top center no-repeat;
}
body.size_medium.color_blackonwhite .logo_wrapper img {
  opacity: 0;
  visibility: hidden;
}
body.size_medium.color_blackonwhite .fall_through_link {
  color: #000;
}
body.size_medium.color_blackonwhite .events-filters,
body.size_medium.color_blackonwhite .events-filters__wrapper {
  background: #fff;
  border: none;
}
body.size_medium.color_blackonwhite .icheckbox_minimal {
  border-color: #000;
}
body.size_medium.color_blackonwhite .news_block {
  background-color: none;
}
body.size_medium.color_blackonwhite .new-info {
  background: #fff;
}
body.size_medium.color_blackonwhite .parking_note {
  float: none;
  margin-top: 100px;
  text-align: left;
}
body.size_medium.color_blackonwhite .parking_note:before {
  content: ' ';
  display: block;
  clear: both;
}
body.size_medium.color_blackonwhite .subway_label {
  background-color: #fff;
  border: 1px solid #000;
}
body.size_medium.color_blackonwhite .event_content,
body.size_medium.color_blackonwhite .page_content {
  background: none;
}
body.size_medium.color_blackonwhite .scheme_header,
body.size_medium.color_blackonwhite .scheme_switch_block,
body.size_medium.color_blackonwhite ol.sections_switcher li:before {
  color: #000;
}
body.size_medium.color_blackonwhite .scheme_switch_block.active {
  background: #000;
  color: #fff;
}
body.size_medium.color_blackonwhite .section_switch_block.active {
  background: #000;
  color: #fff;
}
body.size_medium.color_blackonwhite .section_info.active {
  background: #fff;
}
body.size_medium.color_blackonwhite .section_info_print {
  color: #000;
  text-decoration: underline;
}
body.size_medium.color_blackonwhite .images_gallery .gallery_image {
  background: #fff;
}
body.size_medium.color_blackonwhite .list_ul ul li:before {
  background: #000;
}
body.size_medium.color_blackonwhite .catalogue_search_form,
body.size_medium.color_blackonwhite .vf_search_form {
  border: none;
  background: none;
}
body.size_medium.color_blackonwhite .work_time a,
body.size_medium.color_blackonwhite a.work_time {
  border: none;
}
body.size_medium.color_blackonwhite .long-term-events__item {
  border-color: #000;
}
body.size_medium.color_blackonwhite .news_block {
  background: #fff;
  border: 1px solid #000;
  overflow: hidden;
}
body.size_medium.color_blackonwhite .inner_pages_breadcrumbs {
  border-color: #000;
}
body.size_medium.color_blackonwhite .contact_table .contact_row .contact_cell {
  border-color: #000;
}
body.size_medium.color_blackonwhite .event_organizer:after {
  border-color: #000;
}
body.size_medium.color_blackonwhite .vf_search_select {
  box-shadow: none;
  background: #fff;
  border: 1px solid #000;
  width: 300px;
}
body.size_medium.color_blackonwhite .index_search_option,
body.size_medium.color_blackonwhite .vf_search_option {
  border-color: #000;
  color: #000;
}
body.size_medium.color_blackonwhite .header {
  height: auto;
}
body.size_medium.color_blackonwhite .index_subscribe_block .subscribe-brief {
  line-height: 1.35;
}
body.size_medium.color_blackonwhite #footer {
  line-height: 1.35;
}
body.size_medium.color_blackonwhite .footer_copy_text {
  line-height: 1.35;
}
body.size_medium.color_blackonwhite .footer_social_wrapper .footer_list {
  line-height: 1.35;
}
body.size_medium.color_blackonwhite .nouvelle_small_text {
  color: #000;
}
body.size_medium.color_blackonwhite .top-banner {
  background-color: #fff;
  border: 2px solid #000;
  font-size: 30px;
}
body.size_medium.color_blackonwhite .top-banner__close {
  background-size: 35px;
}
body.size_medium.color_blackonwhite .flex-direction-nav a {
  width: 65px;
  height: 65px;
  background-size: 35px;
  background-color: #fff;
  border: 1px solid #000;
}
body.size_medium.color_blackonwhite .flex-nav-prev {
  margin-right: 15px;
}
body.size_medium.color_blackonwhite .flex-control-paging li a {
  width: 15px;
  height: 15px;
  background-color: transparent;
  border: 1px solid #000;
}
body.size_medium.color_blackonwhite .flex-control-paging li a.flex-active {
  background-color: #000;
}
body.size_medium.color_blackonwhite .departments_block .departments_block__wrapper .departments_block__part a.departments_block__item img {
  height: auto;
  width: 65px;
}
body.size_medium.color_blackonwhite a.menu__link_footer img {
  width: 30px !important;
}
body.size_medium.color_blackonwhite .social-btn_vk {
  background-size: 30px;
}
body.size_medium.color_blackonwhite .social-btn_fb {
  background-size: 20px;
}
body.size_medium.color_blackonwhite .social-btn_ig {
  background-size: 22px;
}
body.size_medium.color_blackonwhite .social-btn_yt {
  background-size: 28px;
}
body.size_medium.color_blackonwhite .pro_column {
  width: 33%;
}
body.size_medium.color_blackonwhite .index_news {
  width: 65%;
}
body.size_medium.color_blackonwhite .index_pro_block .index_pro_content {
  padding-left: 0;
}
body.size_medium.color_blackonwhite .events-item_background .event-cycle {
  background-color: #fff;
  font-size: 30px;
}
body.size_medium.color_blackonwhite .news_about .centenary_tag,
body.size_medium.color_blackonwhite .event-tag {
  color: #000;
  background-color: #fff;
  border: 1px solid #000;
  font-size: 26px;
}
body.size_medium.color_blackonwhite .side_social_wrapper > ul > li {
  width: 48px;
  height: 48px;
  border-color: #000;
}
body.size_medium.color_blackonwhite .side_social_wrapper > ul > li > a > svg {
  fill: #000;
  width: 22px;
  height: 22px;
}
body.size_medium.color_blackonwhite .partners_column a.partners_block {
  border: 2px solid #000;
  padding: 10px 15px;
}
body.size_medium.color_blackonwhite .partners_column a.partners_block .partners_block_title {
  font-size: 30px;
  color: #000;
}
body.size_medium.color_blackonwhite .test-wrapper {
  grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
}
body.size_medium.color_blackonwhite .long-term-events {
  width: 100%;
}
body.size_medium.color_blackonwhite .news_wrapper.listing_block {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
  grid-gap: 23px;
}
body.size_medium.color_blackonwhite .news_block {
  float: none;
  margin: 0 !important;
  width: auto;
}
body.size_medium.color_blackonwhite .news_image {
  width: 100%;
  background-position: center;
}
body.size_medium.color_blackonwhite .news_title {
  line-height: 1.2;
}
body.size_medium.color_whiteonblack {
  font-size: 30px;
  color: #fff;
  background-color: #000;
  padding-top: 120px;
}
body.size_medium.color_whiteonblack .fixed_menu_wrapper .js-login-screen,
body.size_medium.color_whiteonblack .fixed_menu_wrapper .js-become-member {
  display: none;
}
body.size_medium.color_whiteonblack #special_version_controls {
  border: 1px solid #fff;
  background: #000;
  margin-bottom: 6px;
  border-radius: 0 0 4px 4px;
  border-top: 0;
  width: 90%;
  max-width: 1200px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99999;
  padding: 20px;
  text-align: center;
  display: block;
}
body.size_medium.color_whiteonblack .special_version_controls_group {
  display: inline-block;
  margin-right: 40px;
  margin-bottom: 30px;
}
body.size_medium.color_whiteonblack .special_version_controls_label {
  display: inline-block;
  vertical-align: top;
  padding-top: 6px;
  font-size: 20px;
}
body.size_medium.color_whiteonblack .special_version_controls_select {
  display: inline-block;
  vertical-align: top;
  margin-left: 10px;
}
body.size_medium.color_whiteonblack .special_version_controls_point {
  display: block;
  vertical-align: top;
  position: relative;
  background: #fff;
  border: 1px solid #000;
  margin-right: -1px;
  cursor: pointer;
  height: 41px;
  width: 41px;
  text-align: center;
  line-height: 1;
  padding: 10px 12px 6px;
  font-size: 20px;
  float: left;
}
body.size_medium.color_whiteonblack .special_version_controls_point:first-child {
  border-radius: 4px 0 0 4px;
}
body.size_medium.color_whiteonblack .special_version_controls_point:last-child {
  border-radius: 0 4px 4px 0;
}
body.size_medium.color_whiteonblack .special_version_controls_point.active:before {
  content: ' ';
  display: block;
  position: absolute;
  width: 100%;
  height: 1px;
  border-bottom: 2px dashed #fff;
  left: 0;
  bottom: -7px;
}
body.size_medium.color_whiteonblack .special_version_controls_select[data-parameter="size"] .special_version_controls_point {
  color: #fff;
  background-color: #000;
}
body.size_medium.color_whiteonblack .special_version_controls_select[data-parameter="size"] .special_version_controls_point[data-value="medium"] {
  font-size: 30px;
  padding: 4px 0 0;
}
body.size_medium.color_whiteonblack .special_version_controls_select[data-parameter="size"] .special_version_controls_point[data-value="large"] {
  font-size: 40px;
  padding: 0;
}
body.size_medium.color_whiteonblack .special_version_controls_select[data-parameter="color"] .special_version_controls_point[data-value="blackonwhite"] {
  color: #000;
  background: #fff;
}
body.size_medium.color_whiteonblack .special_version_controls_select[data-parameter="color"] .special_version_controls_point[data-value="whiteonblack"] {
  color: #fff;
  background: #000;
}
body.size_medium.color_whiteonblack .special_version_controls_select[data-parameter="color"] .special_version_controls_point[data-value="blue"] {
  background: #9dd1ff;
  color: #195183;
}
body.size_medium.color_whiteonblack .special_version_controls_select[data-parameter="graphics"] .special_version_controls_point[data-value="normal"] {
  background: #0000f3;
  color: #fff;
}
body.size_medium.color_whiteonblack .special_version_controls_select[data-parameter="graphics"] .special_version_controls_point[data-value="mono"] {
  background: #ddd;
}
body.size_medium.color_whiteonblack .special_version_controls_select[data-parameter="graphics"] .special_version_controls_point[data-value="disabled"] {
  color: #fff;
}
body.size_medium.color_whiteonblack a.menu__item.link.link_blue:first-of-type {
  margin-right: 0;
}
body.size_medium.color_whiteonblack .header__menu .menu__item {
  margin-right: 25px;
}
body.size_medium.color_whiteonblack .layout-wrapper {
  color: #fff;
  background-color: #000;
}
body.size_medium.color_whiteonblack .infobar {
  height: auto;
  font-size: 30px;
  background: #000;
}
body.size_medium.color_whiteonblack .infobar:after {
  content: ' ';
  display: block;
  clear: both;
}
body.size_medium.color_whiteonblack .infobar__link,
body.size_medium.color_whiteonblack .infobar__link_academy {
  color: #fff;
  font-size: 26px;
}
body.size_medium.color_whiteonblack a.infobar__link_academy:before {
  display: none;
}
body.size_medium.color_whiteonblack a.menu__link {
  font-size: 30px;
  color: #fff;
}
body.size_medium.color_whiteonblack a {
  color: #fff !important;
  text-decoration: underline !important;
}
body.size_medium.color_whiteonblack .become-member {
  font-size: 30px;
}
body.size_medium.color_whiteonblack #nav {
  border: none;
  margin: 40px 0;
}
body.size_medium.color_whiteonblack .full-index {
  font-size: 30px;
}
body.size_medium.color_whiteonblack .full-index:after {
  display: none;
}
body.size_medium.color_whiteonblack .search_switch {
  font-size: 30px;
  color: #fff;
}
body.size_medium.color_whiteonblack .quick-links {
  margin-left: 50px;
  width: 350px;
}
body.size_medium.color_whiteonblack a.quick-links__item,
body.size_medium.color_whiteonblack a.closing-time {
  font-size: 30px;
}
body.size_medium.color_whiteonblack a.closing-time:after {
  display: none;
}
body.size_medium.color_whiteonblack .wisemen_block {
  display: none !important;
}
body.size_medium.color_whiteonblack .vf_search_wrapper {
  width: 540px;
}
body.size_medium.color_whiteonblack input#index_search,
body.size_medium.color_whiteonblack input#search {
  font-size: 30px;
}
body.size_medium.color_whiteonblack .index_header {
  font-size: 40px;
}
body.size_medium.color_whiteonblack .index_subscribe_block {
  height: auto;
  margin-bottom: 20px;
}
body.size_medium.color_whiteonblack .index_subscribe_block .subscribe-brief {
  color: #fff;
  font-size: 26px;
}
body.size_medium.color_whiteonblack .index_subscribe_block .subscribe-brief strong {
  font-size: 30px;
  color: #fff;
  display: block;
  text-decoration: underline;
}
body.size_medium.color_whiteonblack .departments_block {
  background: #000;
  padding: 40px;
  box-shadow: none;
}
body.size_medium.color_whiteonblack .departments_block .departments_block__wrapper .departments_block__subheader {
  color: #fff;
  font-size: 40px;
}
body.size_medium.color_whiteonblack .departments_block .departments_block__wrapper .departments_block__part a.departments_block__item {
  border: none;
  margin-bottom: 10px;
}
body.size_medium.color_whiteonblack .departments_block .departments_block__wrapper .departments_block__part a.departments_block__item span {
  color: #fff;
  font-size: 30px;
  line-height: 1.35;
}
body.size_medium.color_whiteonblack .departments_block__decoration {
  display: none;
}
body.size_medium.color_whiteonblack .fall_through_link {
  font-size: 30px;
}
body.size_medium.color_whiteonblack .fall_through_link:after {
  display: none;
}
body.size_medium.color_whiteonblack .nouvelle_desc a,
body.size_medium.color_whiteonblack .nouvelle_desc small {
  color: #fff;
  font-size: 26px;
}
body.size_medium.color_whiteonblack .nouvelle_small_text,
body.size_medium.color_whiteonblack .nouvelle_title {
  font-size: 30px;
}
body.size_medium.color_whiteonblack .index_nouvelle_main .nouvelle_title {
  font-size: 36px;
}
body.size_medium.color_whiteonblack .index_pro_block .index_pro_content {
  background-color: #000;
}
body.size_medium.color_whiteonblack .index_pro_block .index_pro_content .index_pro_content_title,
body.size_medium.color_whiteonblack .index_pro_block .index_pro_content .index_pro_content_text {
  font-size: 30px;
}
body.size_medium.color_whiteonblack .index_pro_block .index_pro_content small {
  color: #fff;
  font-size: 26px;
}
body.size_medium.color_whiteonblack .index_news_wrapper * {
  line-height: 1.35 !important;
}
body.size_medium.color_whiteonblack .collection_links a {
  font-size: 26px;
}
body.size_medium.color_whiteonblack .collection_info a {
  font-size: 26px;
}
body.size_medium.color_whiteonblack .index_collections_wrapper .collection_title {
  font-size: 36px;
}
body.size_medium.color_whiteonblack .index_collections_wrapper .collection_about small {
  font-size: 26px;
  color: #fff !important;
}
body.size_medium.color_whiteonblack .header__menu .menu__item {
  font-size: 30px;
}
body.size_medium.color_whiteonblack .menu__item_active {
  border-bottom: 2px dashed #fff;
}
body.size_medium.color_whiteonblack #footer {
  color: #fff;
  background: #000;
  font-size: 26px;
}
body.size_medium.color_whiteonblack .footer_social_header {
  color: #fff;
  font-size: 30px;
}
body.size_medium.color_whiteonblack a.menu__link_footer {
  font-size: 30px;
}
body.size_medium.color_whiteonblack .footer_header {
  font-size: 30px;
  color: #fff;
}
body.size_medium.color_whiteonblack .work_time p {
  font-size: 26px;
}
body.size_medium.color_whiteonblack .footer_column_left {
  max-width: 50%;
}
body.size_medium.color_whiteonblack .footer_copy {
  border-top-color: #000;
  color: #fff;
  font-size: 26px;
}
body.size_medium.color_whiteonblack .footer_banner img {
  display: none;
}
body.size_medium.color_whiteonblack .footer_banner a {
  font-size: 30px;
}
body.size_medium.color_whiteonblack .events-item__image {
  background: #000 !important;
}
body.size_medium.color_whiteonblack .event-type a,
body.size_medium.color_whiteonblack .events_cat a,
body.size_medium.color_whiteonblack .inner-event-type a,
body.size_medium.color_whiteonblack .new-type a {
  font-size: 26px;
}
body.size_medium.color_whiteonblack .events-item__place {
  font-size: 30px;
}
body.size_medium.color_whiteonblack .events-item__header {
  font-size: 36px;
}
body.size_medium.color_whiteonblack .events-item__time {
  font-size: 40px;
}
body.size_medium.color_whiteonblack .event-type a,
body.size_medium.color_whiteonblack .events_cat a,
body.size_medium.color_whiteonblack .inner-event-type a,
body.size_medium.color_whiteonblack .new-type a,
body.size_medium.color_whiteonblack .events-item__place,
body.size_medium.color_whiteonblack .events-item__header,
body.size_medium.color_whiteonblack .events-item__time {
  color: #fff;
}
body.size_medium.color_whiteonblack .long-term-events {
  background: #000;
}
body.size_medium.color_whiteonblack .long-term-events__title,
body.size_medium.color_whiteonblack a.long-term-events__item-header {
  color: #fff;
  font-size: 30px;
}
body.size_medium.color_whiteonblack .event-date__day-of-the-month {
  font-size: 36px;
}
body.size_medium.color_whiteonblack .event-date__day-of-the-month small {
  font-size: 30px;
}
body.size_medium.color_whiteonblack .event-date_weekday {
  font-size: 30px;
  color: #fff;
}
body.size_medium.color_whiteonblack .event-date:before {
  display: none;
}
body.size_medium.color_whiteonblack .btn__show-more {
  border-color: #fff;
  color: #fff;
  font-size: 36px;
}
body.size_medium.color_whiteonblack .subscribe-header {
  font-size: 36px;
  color: #fff;
}
body.size_medium.color_whiteonblack .subscribe-brief {
  color: #fff;
  font-size: 30px;
}
body.size_medium.color_whiteonblack .subscribe_block .subscribe-btn {
  font-size: 30px;
  width: 240px;
  color: #fff;
  background: transparent;
  border: 1px solid #fff;
  padding: 0;
  line-height: 65px;
}
body.size_medium.color_whiteonblack .events-by-date * {
  line-height: 1.35 !important;
}
body.size_medium.color_whiteonblack .events_date_btn,
body.size_medium.color_whiteonblack .ui-selectmenu-button,
body.size_medium.color_whiteonblack .ui-state-focus.ui-selectmenu-button,
body.size_medium.color_whiteonblack .ui-state-hover.ui-selectmenu-button,
body.size_medium.color_whiteonblack label.checkbox,
body.size_medium.color_whiteonblack .ui-menu .ui-menu-item {
  font-size: 30px;
  color: #fff;
  background: #000;
}
body.size_medium.color_whiteonblack .catalogue-search_background {
  background: none;
}
body.size_medium.color_whiteonblack .catalogue-search_title {
  font-size: 40px;
}
body.size_medium.color_whiteonblack .catalogue-search_title span {
  font-size: 36px;
}
body.size_medium.color_whiteonblack .catalogue_search_form {
  box-shadow: none;
  height: 65px;
  border: 2px solid #fff;
  font-size: 30px;
}
body.size_medium.color_whiteonblack .index_search_option,
body.size_medium.color_whiteonblack .vf_search_option {
  font-size: 30px;
}
body.size_medium.color_whiteonblack .button__filled {
  border-radius: 0;
  border: 1px solid #fff;
  font-size: 30px;
  background: #000;
}
body.size_medium.color_whiteonblack .catalogue-item {
  border: 2px solid #fff;
  background: none !important;
}
body.size_medium.color_whiteonblack .catalogue-item_title {
  font-size: 40px;
}
body.size_medium.color_whiteonblack .catalogue-item_links a {
  font-size: 30px;
}
body.size_medium.color_whiteonblack a.button {
  font-size: 30px;
}
body.size_medium.color_whiteonblack .button__label {
  margin: 25px 0 0;
  box-shadow: none;
  border: 1px solid #fff;
  background: none;
}
body.size_medium.color_whiteonblack .about_block {
  border-color: #fff;
  background: none;
}
body.size_medium.color_whiteonblack .catalogue_title {
  color: #fff;
  font-size: 26px;
}
body.size_medium.color_whiteonblack input,
body.size_medium.color_whiteonblack textarea {
  border: 2px solid #fff !important;
  background: #000 !important;
  font-size: 30px !important;
  color: #fff !important;
  border-radius: 0px !important;
}
body.size_medium.color_whiteonblack #fixed_menu {
  background: #000;
  box-shadow: none;
  border-bottom: 2px solid #fff;
}
body.size_medium.color_whiteonblack .fixed_menu_wrapper {
  background: none;
}
body.size_medium.color_whiteonblack .news_block {
  box-shadow: none;
}
body.size_medium.color_whiteonblack .news_about {
  padding-left: 10px;
  padding-right: 10px;
}
body.size_medium.color_whiteonblack .news_tag {
  font-size: 26px;
  color: #fff;
}
body.size_medium.color_whiteonblack .news_title {
  font-size: 30px;
}
body.size_medium.color_whiteonblack .visitors_block,
body.size_medium.color_whiteonblack .info_block,
body.size_medium.color_whiteonblack .digest_block,
body.size_medium.color_whiteonblack .libfl_block {
  background: none;
  border: 2px solid #fff;
  box-shadow: none;
}
body.size_medium.color_whiteonblack .libfl_block {
  padding-left: 30px;
  padding-right: 40px;
}
body.size_medium.color_whiteonblack .libfl_block .libfl_block__part a:before {
  display: none;
}
body.size_medium.color_whiteonblack .libfl_block .libfl_block__part a {
  font-size: 36px;
}
body.size_medium.color_whiteonblack .libfl_block .libfl_block__description span {
  color: #fff;
  font-size: 26px;
}
body.size_medium.color_whiteonblack .info_block .info_block__address {
  color: #fff;
  font-size: 40px;
}
body.size_medium.color_whiteonblack .info_block .info_block__address span {
  color: #fff;
  font-size: 26px;
}
body.size_medium.color_whiteonblack .info_block a {
  font-size: 30px;
}
body.size_medium.color_whiteonblack .info_block a.info_block__map {
  height: auto;
  background: none;
}
body.size_medium.color_whiteonblack .visitors_block .visitors_block__title {
  color: #fff;
  font-size: 36px;
  margin-bottom: 20px;
}
body.size_medium.color_whiteonblack .visitors_block a {
  font-size: 30px;
  font-weight: normal;
}
body.size_medium.color_whiteonblack .digest_block .digest_block__title {
  color: #fff;
  font-size: 36px;
  margin-bottom: 20px;
}
body.size_medium.color_whiteonblack .digest_block a {
  font-size: 30px;
}
body.size_medium.color_whiteonblack #popup_wrapper {
  background: rgba(0, 0, 0, 0.8);
}
body.size_medium.color_whiteonblack .popup_box {
  background: #000 !important;
  color: #fff !important;
  font-size: 30px;
}
body.size_medium.color_whiteonblack .popup_box * {
  color: #fff !important;
  line-height: 1.35 !important;
}
body.size_medium.color_whiteonblack .modal_parts .modal_part .modal_title {
  font-size: 36px;
}
body.size_medium.color_whiteonblack .modal_parts .modal_part .modal_subtitle {
  font-size: 30px;
  height: auto;
}
body.size_medium.color_whiteonblack .modal_parts ul.modal_list li,
body.size_medium.color_whiteonblack .modal_parts ol.modal_list li {
  font-size: 30px;
}
body.size_medium.color_whiteonblack .modal_parts ul.modal_list li:before,
body.size_medium.color_whiteonblack .modal_parts ol.modal_list li:before {
  color: #fff;
}
body.size_medium.color_whiteonblack .modal_cta {
  background: none;
  font-size: 40px;
}
body.size_medium.color_whiteonblack .modal_welcome span {
  font-size: 40px;
}
body.size_medium.color_whiteonblack .modal_welcome:before {
  display: none;
}
body.size_medium.color_whiteonblack .modal_welcome a.btn {
  background: none;
  border-radius: 0;
  border: 2px solid #fff;
  font-size: 30px;
  width: auto;
  height: auto;
  line-height: 1 !important;
}
body.size_medium.color_whiteonblack .modal_rules a.link {
  font-size: 26px;
}
body.size_medium.color_whiteonblack #popup_reader.popup_box .decoration_left,
body.size_medium.color_whiteonblack #popup_reader.popup_box .decoration_right {
  display: none;
}
body.size_medium.color_whiteonblack .event-info {
  width: 600px;
  height: auto;
  background: #000;
  color: #fff;
}
body.size_medium.color_whiteonblack .inner-event-type span {
  font-size: 26px;
}
body.size_medium.color_whiteonblack .event-info__title,
body.size_medium.color_whiteonblack .new-info__title {
  font-size: 36px;
}
body.size_medium.color_whiteonblack .event-info__place {
  font-size: 26px;
}
body.size_medium.color_whiteonblack .event-info__shedule,
body.size_medium.color_whiteonblack .new-info__shedule {
  position: relative;
  width: 100%;
  bottom: auto;
  line-height: 1.35;
  margin-top: 20px;
}
body.size_medium.color_whiteonblack .event-info__date {
  font-size: 26px;
}
body.size_medium.color_whiteonblack .event-info__time {
  font-size: 36px;
  color: #fff;
}
body.size_medium.color_whiteonblack .event-info__admission {
  width: 250px;
}
body.size_medium.color_whiteonblack .event-info__admission * {
  color: #fff;
}
body.size_medium.color_whiteonblack .event-info__admission--docs {
  font-size: 26px;
  color: #fff;
}
body.size_medium.color_whiteonblack .block_wrapper * {
  font-size: 30px;
  color: #fff !important;
}
body.size_medium.color_whiteonblack .block_wrapper,
body.size_medium.color_whiteonblack .cutted_block,
body.size_medium.color_whiteonblack .image_left_block,
body.size_medium.color_whiteonblack .image_right_block,
body.size_medium.color_whiteonblack .old_block p,
body.size_medium.color_whiteonblack .text_block {
  font-size: 30px;
}
body.size_medium.color_whiteonblack #news .block_wrapper:first-child .text_block {
  font-size: 36px;
}
body.size_medium.color_whiteonblack .block_wrapper h2 {
  font-size: 40px;
  line-height: 1.4;
}
body.size_medium.color_whiteonblack .block_wrapper h3 {
  font-size: 36px;
  line-height: 1.4;
}
body.size_medium.color_whiteonblack .block_wrapper h4 {
  font-size: 30px;
  line-height: 1.4;
}
body.size_medium.color_whiteonblack .image_block span.set,
body.size_medium.color_whiteonblack .image_left_block span.set,
body.size_medium.color_whiteonblack .image_right_block span.set,
body.size_medium.color_whiteonblack .image_block .image_desc,
body.size_medium.color_whiteonblack .image_left_block .image_desc,
body.size_medium.color_whiteonblack .image_right_block .image_desc {
  font-size: 26px;
}
body.size_medium.color_whiteonblack .button_link,
body.size_medium.color_whiteonblack .registration_link,
body.size_medium.color_whiteonblack .registration_link_instruction {
  font-size: 26px;
}
body.size_medium.color_whiteonblack .button_link .button_with_link,
body.size_medium.color_whiteonblack .registration_link .button_with_link,
body.size_medium.color_whiteonblack .registration_link_instruction .button_with_link {
  font-size: 26px;
  background: none;
  border: 2px solid #fff;
  height: auto;
  line-height: 1;
  padding: 20px;
  width: auto;
}
body.size_medium.color_whiteonblack .registration_link {
  border-color: #fff;
}
body.size_medium.color_whiteonblack .list_ul ul {
  font-size: 30px;
}
body.size_medium.color_whiteonblack .list_ol ol {
  font-size: 30px;
}
body.size_medium.color_whiteonblack .cite_block .cite {
  font-size: 40px;
}
body.size_medium.color_whiteonblack .cite_block .cite_by {
  font-size: 30px;
}
body.size_medium.color_whiteonblack .collections_block .collection_about a {
  font-size: 36px;
}
body.size_medium.color_whiteonblack .collections_block .collection_title {
  font-size: 40px;
}
body.size_medium.color_whiteonblack .collections_block .collection_info {
  font-size: 26px;
}
body.size_medium.color_whiteonblack .collections_block .collection_info a {
  font-size: 26px;
}
body.size_medium.color_whiteonblack .cutted_block .cut_block_button {
  font-size: 26px;
}
body.size_medium.color_whiteonblack .event_organizer,
body.size_medium.color_whiteonblack .event_additional_wrapper > span {
  font-size: 26px;
}
body.size_medium.color_whiteonblack .btn_social {
  font-size: 26px;
  background-color: #000 !important;
  color: #fff !important;
  border: 1px solid #fff;
  height: auto;
  width: auto;
  padding: 5px 10px;
}
body.size_medium.color_whiteonblack .bottom_block {
  border-color: #fff;
}
body.size_medium.color_whiteonblack .tagline {
  font-size: 30px;
}
body.size_medium.color_whiteonblack .tagline small {
  font-size: 26px;
  color: #fff;
}
body.size_medium.color_whiteonblack .page-views {
  font-size: 26px;
  color: #fff;
}
body.size_medium.color_whiteonblack .article-description__header {
  font-size: 40px;
}
body.size_medium.color_whiteonblack .facilities_banner a.facilities_banner__description {
  font-size: 36px;
}
body.size_medium.color_whiteonblack .accordion dt a {
  font-size: 30px;
}
body.size_medium.color_whiteonblack .accordion dd .dd-row .dd-head {
  color: #fff;
}
body.size_medium.color_whiteonblack .accordion dd .dd-row .dd-cell,
body.size_medium.color_whiteonblack .accordion dd .dd-row .dd-head {
  font-size: 26px;
  color: #fff;
}
body.size_medium.color_whiteonblack .accordion span {
  font-size: 26px;
  color: #fff;
}
body.size_medium.color_whiteonblack .article-nav-childs {
  border: 2px solid #fff;
  background: none;
  font-size: 26px;
}
body.size_medium.color_whiteonblack .article-nav-childs__header {
  background: #000;
  border-bottom: 2px solid #fff;
  font-size: inherit;
  color: #fff;
}
body.size_medium.color_whiteonblack .inner_pages_breadcrumbs {
  font-size: 26px;
  color: #fff;
}
body.size_medium.color_whiteonblack .contact_wrapper {
  background: none;
  border: 2px solid #fff;
}
body.size_medium.color_whiteonblack .contact_text_wrapper,
body.size_medium.color_whiteonblack .contact_text_wrapper {
  color: #fff;
  font-size: 30px;
}
body.size_medium.color_whiteonblack .contact_btn {
  background: #000;
  color: #fff !important;
  border: 1px solid #fff;
  box-shadow: none;
  font-size: 26px;
}
body.size_medium.color_whiteonblack .note {
  font-size: 26px;
}
body.size_medium.color_whiteonblack .scheme_header {
  font-size: 36px;
  line-height: 1.4;
}
body.size_medium.color_whiteonblack .section_switch_block {
  font-size: 30px;
  color: #fff;
  text-decoration: underline;
}
body.size_medium.color_whiteonblack .section_info_header {
  font-size: 36px;
}
body.size_medium.color_whiteonblack .section_info_text {
  font-size: 30px;
}
body.size_medium.color_whiteonblack .section_info_print {
  font-size: 30px;
}
body.size_medium.color_whiteonblack .event_content,
body.size_medium.color_whiteonblack .page_content {
  box-shadow: none;
}
body.size_medium.color_whiteonblack .scheme_switch_block {
  padding: 0;
}
body.size_medium.color_whiteonblack a.departments__item {
  font-size: 30px;
  height: auto;
  margin-bottom: 20px;
}
body.size_medium.color_whiteonblack .contact_cell span {
  font-size: 26px !important;
}
body.size_medium.color_whiteonblack .logo_wrapper {
  background: url('/images/svg/accent-logo-white.svg') top center no-repeat;
}
body.size_medium.color_whiteonblack .logo_wrapper img {
  opacity: 0;
  visibility: hidden;
}
body.size_medium.color_whiteonblack .fall_through_link {
  color: #fff;
}
body.size_medium.color_whiteonblack .events-filters,
body.size_medium.color_whiteonblack .events-filters__wrapper {
  background: #000;
  border: none;
}
body.size_medium.color_whiteonblack .icheckbox_minimal {
  border-color: #fff;
}
body.size_medium.color_whiteonblack .news_block {
  background-color: none;
}
body.size_medium.color_whiteonblack .new-info {
  background: #000;
}
body.size_medium.color_whiteonblack .parking_note {
  float: none;
  margin-top: 100px;
  text-align: left;
}
body.size_medium.color_whiteonblack .parking_note:before {
  content: ' ';
  display: block;
  clear: both;
}
body.size_medium.color_whiteonblack .subway_label {
  background-color: #000;
  border: 1px solid #fff;
}
body.size_medium.color_whiteonblack .event_content,
body.size_medium.color_whiteonblack .page_content {
  background: none;
}
body.size_medium.color_whiteonblack .scheme_header,
body.size_medium.color_whiteonblack .scheme_switch_block,
body.size_medium.color_whiteonblack ol.sections_switcher li:before {
  color: #fff;
}
body.size_medium.color_whiteonblack .scheme_switch_block.active {
  background: #fff;
  color: #000;
}
body.size_medium.color_whiteonblack .section_switch_block.active {
  background: #fff;
  color: #000;
}
body.size_medium.color_whiteonblack .section_info.active {
  background: #000;
}
body.size_medium.color_whiteonblack .section_info_print {
  color: #fff;
  text-decoration: underline;
}
body.size_medium.color_whiteonblack .images_gallery .gallery_image {
  background: #000;
}
body.size_medium.color_whiteonblack .list_ul ul li:before {
  background: #fff;
}
body.size_medium.color_whiteonblack .catalogue_search_form,
body.size_medium.color_whiteonblack .vf_search_form {
  border: none;
  background: none;
}
body.size_medium.color_whiteonblack .work_time a,
body.size_medium.color_whiteonblack a.work_time {
  border: none;
}
body.size_medium.color_whiteonblack .long-term-events__item {
  border-color: #fff;
}
body.size_medium.color_whiteonblack .news_block {
  background: #000;
  border: 1px solid #fff;
  overflow: hidden;
}
body.size_medium.color_whiteonblack .inner_pages_breadcrumbs {
  border-color: #fff;
}
body.size_medium.color_whiteonblack .contact_table .contact_row .contact_cell {
  border-color: #fff;
}
body.size_medium.color_whiteonblack .event_organizer:after {
  border-color: #fff;
}
body.size_medium.color_whiteonblack .vf_search_select {
  box-shadow: none;
  background: #000;
  border: 1px solid #fff;
  width: 300px;
}
body.size_medium.color_whiteonblack .index_search_option,
body.size_medium.color_whiteonblack .vf_search_option {
  border-color: #fff;
  color: #fff;
}
body.size_medium.color_whiteonblack .header {
  height: auto;
}
body.size_medium.color_whiteonblack .index_subscribe_block .subscribe-brief {
  line-height: 1.35;
}
body.size_medium.color_whiteonblack #footer {
  line-height: 1.35;
}
body.size_medium.color_whiteonblack .footer_copy_text {
  line-height: 1.35;
}
body.size_medium.color_whiteonblack .footer_social_wrapper .footer_list {
  line-height: 1.35;
}
body.size_medium.color_whiteonblack .nouvelle_small_text {
  color: #fff;
}
body.size_medium.color_whiteonblack .top-banner {
  background-color: #000;
  border: 2px solid #fff;
  font-size: 30px;
}
body.size_medium.color_whiteonblack .top-banner__close {
  background-size: 35px;
}
body.size_medium.color_whiteonblack .flex-direction-nav a {
  width: 65px;
  height: 65px;
  background-size: 35px;
  background-color: #000;
  border: 1px solid #fff;
}
body.size_medium.color_whiteonblack .flex-nav-prev {
  margin-right: 15px;
}
body.size_medium.color_whiteonblack .flex-control-paging li a {
  width: 15px;
  height: 15px;
  background-color: transparent;
  border: 1px solid #fff;
}
body.size_medium.color_whiteonblack .flex-control-paging li a.flex-active {
  background-color: #fff;
}
body.size_medium.color_whiteonblack .departments_block .departments_block__wrapper .departments_block__part a.departments_block__item img {
  height: auto;
  width: 65px;
}
body.size_medium.color_whiteonblack a.menu__link_footer img {
  width: 30px !important;
}
body.size_medium.color_whiteonblack .social-btn_vk {
  background-size: 30px;
}
body.size_medium.color_whiteonblack .social-btn_fb {
  background-size: 20px;
}
body.size_medium.color_whiteonblack .social-btn_ig {
  background-size: 22px;
}
body.size_medium.color_whiteonblack .social-btn_yt {
  background-size: 28px;
}
body.size_medium.color_whiteonblack .pro_column {
  width: 33%;
}
body.size_medium.color_whiteonblack .index_news {
  width: 65%;
}
body.size_medium.color_whiteonblack .index_pro_block .index_pro_content {
  padding-left: 0;
}
body.size_medium.color_whiteonblack .events-item_background .event-cycle {
  background-color: #000;
  font-size: 30px;
}
body.size_medium.color_whiteonblack .news_about .centenary_tag,
body.size_medium.color_whiteonblack .event-tag {
  color: #fff;
  background-color: #000;
  border: 1px solid #fff;
  font-size: 26px;
}
body.size_medium.color_whiteonblack .side_social_wrapper > ul > li {
  width: 48px;
  height: 48px;
  border-color: #fff;
}
body.size_medium.color_whiteonblack .side_social_wrapper > ul > li > a > svg {
  fill: #fff;
  width: 22px;
  height: 22px;
}
body.size_medium.color_whiteonblack .partners_column a.partners_block {
  border: 2px solid #fff;
  padding: 10px 15px;
}
body.size_medium.color_whiteonblack .partners_column a.partners_block .partners_block_title {
  font-size: 30px;
  color: #fff;
}
body.size_medium.color_whiteonblack .test-wrapper {
  grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
}
body.size_medium.color_whiteonblack .long-term-events {
  width: 100%;
}
body.size_medium.color_whiteonblack .news_wrapper.listing_block {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
  grid-gap: 23px;
}
body.size_medium.color_whiteonblack .news_block {
  float: none;
  margin: 0 !important;
  width: auto;
}
body.size_medium.color_whiteonblack .news_image {
  width: 100%;
  background-position: center;
}
body.size_medium.color_whiteonblack .news_title {
  line-height: 1.2;
}
body.size_medium.color_blue {
  font-size: 30px;
  color: #195183;
  background-color: #9dd1ff;
  padding-top: 120px;
}
body.size_medium.color_blue .fixed_menu_wrapper .js-login-screen,
body.size_medium.color_blue .fixed_menu_wrapper .js-become-member {
  display: none;
}
body.size_medium.color_blue #special_version_controls {
  border: 1px solid #195183;
  background: #9dd1ff;
  margin-bottom: 6px;
  border-radius: 0 0 4px 4px;
  border-top: 0;
  width: 90%;
  max-width: 1200px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99999;
  padding: 20px;
  text-align: center;
  display: block;
}
body.size_medium.color_blue .special_version_controls_group {
  display: inline-block;
  margin-right: 40px;
  margin-bottom: 30px;
}
body.size_medium.color_blue .special_version_controls_label {
  display: inline-block;
  vertical-align: top;
  padding-top: 6px;
  font-size: 20px;
}
body.size_medium.color_blue .special_version_controls_select {
  display: inline-block;
  vertical-align: top;
  margin-left: 10px;
}
body.size_medium.color_blue .special_version_controls_point {
  display: block;
  vertical-align: top;
  position: relative;
  background: #fff;
  border: 1px solid #000;
  margin-right: -1px;
  cursor: pointer;
  height: 41px;
  width: 41px;
  text-align: center;
  line-height: 1;
  padding: 10px 12px 6px;
  font-size: 20px;
  float: left;
}
body.size_medium.color_blue .special_version_controls_point:first-child {
  border-radius: 4px 0 0 4px;
}
body.size_medium.color_blue .special_version_controls_point:last-child {
  border-radius: 0 4px 4px 0;
}
body.size_medium.color_blue .special_version_controls_point.active:before {
  content: ' ';
  display: block;
  position: absolute;
  width: 100%;
  height: 1px;
  border-bottom: 2px dashed #195183;
  left: 0;
  bottom: -7px;
}
body.size_medium.color_blue .special_version_controls_select[data-parameter="size"] .special_version_controls_point {
  color: #195183;
  background-color: #9dd1ff;
}
body.size_medium.color_blue .special_version_controls_select[data-parameter="size"] .special_version_controls_point[data-value="medium"] {
  font-size: 30px;
  padding: 4px 0 0;
}
body.size_medium.color_blue .special_version_controls_select[data-parameter="size"] .special_version_controls_point[data-value="large"] {
  font-size: 40px;
  padding: 0;
}
body.size_medium.color_blue .special_version_controls_select[data-parameter="color"] .special_version_controls_point[data-value="blackonwhite"] {
  color: #000;
  background: #fff;
}
body.size_medium.color_blue .special_version_controls_select[data-parameter="color"] .special_version_controls_point[data-value="whiteonblack"] {
  color: #fff;
  background: #000;
}
body.size_medium.color_blue .special_version_controls_select[data-parameter="color"] .special_version_controls_point[data-value="blue"] {
  background: #9dd1ff;
  color: #195183;
}
body.size_medium.color_blue .special_version_controls_select[data-parameter="graphics"] .special_version_controls_point[data-value="normal"] {
  background: #0000f3;
  color: #fff;
}
body.size_medium.color_blue .special_version_controls_select[data-parameter="graphics"] .special_version_controls_point[data-value="mono"] {
  background: #ddd;
}
body.size_medium.color_blue .special_version_controls_select[data-parameter="graphics"] .special_version_controls_point[data-value="disabled"] {
  color: #fff;
}
body.size_medium.color_blue a.menu__item.link.link_blue:first-of-type {
  margin-right: 0;
}
body.size_medium.color_blue .header__menu .menu__item {
  margin-right: 25px;
}
body.size_medium.color_blue .layout-wrapper {
  color: #195183;
  background-color: #9dd1ff;
}
body.size_medium.color_blue .infobar {
  height: auto;
  font-size: 30px;
  background: #9dd1ff;
}
body.size_medium.color_blue .infobar:after {
  content: ' ';
  display: block;
  clear: both;
}
body.size_medium.color_blue .infobar__link,
body.size_medium.color_blue .infobar__link_academy {
  color: #195183;
  font-size: 26px;
}
body.size_medium.color_blue a.infobar__link_academy:before {
  display: none;
}
body.size_medium.color_blue a.menu__link {
  font-size: 30px;
  color: #195183;
}
body.size_medium.color_blue a {
  color: #195183 !important;
  text-decoration: underline !important;
}
body.size_medium.color_blue .become-member {
  font-size: 30px;
}
body.size_medium.color_blue #nav {
  border: none;
  margin: 40px 0;
}
body.size_medium.color_blue .full-index {
  font-size: 30px;
}
body.size_medium.color_blue .full-index:after {
  display: none;
}
body.size_medium.color_blue .search_switch {
  font-size: 30px;
  color: #195183;
}
body.size_medium.color_blue .quick-links {
  margin-left: 50px;
  width: 350px;
}
body.size_medium.color_blue a.quick-links__item,
body.size_medium.color_blue a.closing-time {
  font-size: 30px;
}
body.size_medium.color_blue a.closing-time:after {
  display: none;
}
body.size_medium.color_blue .wisemen_block {
  display: none !important;
}
body.size_medium.color_blue .vf_search_wrapper {
  width: 540px;
}
body.size_medium.color_blue input#index_search,
body.size_medium.color_blue input#search {
  font-size: 30px;
}
body.size_medium.color_blue .index_header {
  font-size: 40px;
}
body.size_medium.color_blue .index_subscribe_block {
  height: auto;
  margin-bottom: 20px;
}
body.size_medium.color_blue .index_subscribe_block .subscribe-brief {
  color: #195183;
  font-size: 26px;
}
body.size_medium.color_blue .index_subscribe_block .subscribe-brief strong {
  font-size: 30px;
  color: #195183;
  display: block;
  text-decoration: underline;
}
body.size_medium.color_blue .departments_block {
  background: #9dd1ff;
  padding: 40px;
  box-shadow: none;
}
body.size_medium.color_blue .departments_block .departments_block__wrapper .departments_block__subheader {
  color: #195183;
  font-size: 40px;
}
body.size_medium.color_blue .departments_block .departments_block__wrapper .departments_block__part a.departments_block__item {
  border: none;
  margin-bottom: 10px;
}
body.size_medium.color_blue .departments_block .departments_block__wrapper .departments_block__part a.departments_block__item span {
  color: #195183;
  font-size: 30px;
  line-height: 1.35;
}
body.size_medium.color_blue .departments_block__decoration {
  display: none;
}
body.size_medium.color_blue .fall_through_link {
  font-size: 30px;
}
body.size_medium.color_blue .fall_through_link:after {
  display: none;
}
body.size_medium.color_blue .nouvelle_desc a,
body.size_medium.color_blue .nouvelle_desc small {
  color: #195183;
  font-size: 26px;
}
body.size_medium.color_blue .nouvelle_small_text,
body.size_medium.color_blue .nouvelle_title {
  font-size: 30px;
}
body.size_medium.color_blue .index_nouvelle_main .nouvelle_title {
  font-size: 36px;
}
body.size_medium.color_blue .index_pro_block .index_pro_content {
  background-color: #9dd1ff;
}
body.size_medium.color_blue .index_pro_block .index_pro_content .index_pro_content_title,
body.size_medium.color_blue .index_pro_block .index_pro_content .index_pro_content_text {
  font-size: 30px;
}
body.size_medium.color_blue .index_pro_block .index_pro_content small {
  color: #195183;
  font-size: 26px;
}
body.size_medium.color_blue .index_news_wrapper * {
  line-height: 1.35 !important;
}
body.size_medium.color_blue .collection_links a {
  font-size: 26px;
}
body.size_medium.color_blue .collection_info a {
  font-size: 26px;
}
body.size_medium.color_blue .index_collections_wrapper .collection_title {
  font-size: 36px;
}
body.size_medium.color_blue .index_collections_wrapper .collection_about small {
  font-size: 26px;
  color: #195183 !important;
}
body.size_medium.color_blue .header__menu .menu__item {
  font-size: 30px;
}
body.size_medium.color_blue .menu__item_active {
  border-bottom: 2px dashed #195183;
}
body.size_medium.color_blue #footer {
  color: #195183;
  background: #9dd1ff;
  font-size: 26px;
}
body.size_medium.color_blue .footer_social_header {
  color: #195183;
  font-size: 30px;
}
body.size_medium.color_blue a.menu__link_footer {
  font-size: 30px;
}
body.size_medium.color_blue .footer_header {
  font-size: 30px;
  color: #195183;
}
body.size_medium.color_blue .work_time p {
  font-size: 26px;
}
body.size_medium.color_blue .footer_column_left {
  max-width: 50%;
}
body.size_medium.color_blue .footer_copy {
  border-top-color: #9dd1ff;
  color: #195183;
  font-size: 26px;
}
body.size_medium.color_blue .footer_banner img {
  display: none;
}
body.size_medium.color_blue .footer_banner a {
  font-size: 30px;
}
body.size_medium.color_blue .events-item__image {
  background: #9dd1ff !important;
}
body.size_medium.color_blue .event-type a,
body.size_medium.color_blue .events_cat a,
body.size_medium.color_blue .inner-event-type a,
body.size_medium.color_blue .new-type a {
  font-size: 26px;
}
body.size_medium.color_blue .events-item__place {
  font-size: 30px;
}
body.size_medium.color_blue .events-item__header {
  font-size: 36px;
}
body.size_medium.color_blue .events-item__time {
  font-size: 40px;
}
body.size_medium.color_blue .event-type a,
body.size_medium.color_blue .events_cat a,
body.size_medium.color_blue .inner-event-type a,
body.size_medium.color_blue .new-type a,
body.size_medium.color_blue .events-item__place,
body.size_medium.color_blue .events-item__header,
body.size_medium.color_blue .events-item__time {
  color: #195183;
}
body.size_medium.color_blue .long-term-events {
  background: #9dd1ff;
}
body.size_medium.color_blue .long-term-events__title,
body.size_medium.color_blue a.long-term-events__item-header {
  color: #195183;
  font-size: 30px;
}
body.size_medium.color_blue .event-date__day-of-the-month {
  font-size: 36px;
}
body.size_medium.color_blue .event-date__day-of-the-month small {
  font-size: 30px;
}
body.size_medium.color_blue .event-date_weekday {
  font-size: 30px;
  color: #195183;
}
body.size_medium.color_blue .event-date:before {
  display: none;
}
body.size_medium.color_blue .btn__show-more {
  border-color: #195183;
  color: #195183;
  font-size: 36px;
}
body.size_medium.color_blue .subscribe-header {
  font-size: 36px;
  color: #195183;
}
body.size_medium.color_blue .subscribe-brief {
  color: #195183;
  font-size: 30px;
}
body.size_medium.color_blue .subscribe_block .subscribe-btn {
  font-size: 30px;
  width: 240px;
  color: #195183;
  background: transparent;
  border: 1px solid #195183;
  padding: 0;
  line-height: 65px;
}
body.size_medium.color_blue .events-by-date * {
  line-height: 1.35 !important;
}
body.size_medium.color_blue .events_date_btn,
body.size_medium.color_blue .ui-selectmenu-button,
body.size_medium.color_blue .ui-state-focus.ui-selectmenu-button,
body.size_medium.color_blue .ui-state-hover.ui-selectmenu-button,
body.size_medium.color_blue label.checkbox,
body.size_medium.color_blue .ui-menu .ui-menu-item {
  font-size: 30px;
  color: #195183;
  background: #9dd1ff;
}
body.size_medium.color_blue .catalogue-search_background {
  background: none;
}
body.size_medium.color_blue .catalogue-search_title {
  font-size: 40px;
}
body.size_medium.color_blue .catalogue-search_title span {
  font-size: 36px;
}
body.size_medium.color_blue .catalogue_search_form {
  box-shadow: none;
  height: 65px;
  border: 2px solid #195183;
  font-size: 30px;
}
body.size_medium.color_blue .index_search_option,
body.size_medium.color_blue .vf_search_option {
  font-size: 30px;
}
body.size_medium.color_blue .button__filled {
  border-radius: 0;
  border: 1px solid #195183;
  font-size: 30px;
  background: #9dd1ff;
}
body.size_medium.color_blue .catalogue-item {
  border: 2px solid #195183;
  background: none !important;
}
body.size_medium.color_blue .catalogue-item_title {
  font-size: 40px;
}
body.size_medium.color_blue .catalogue-item_links a {
  font-size: 30px;
}
body.size_medium.color_blue a.button {
  font-size: 30px;
}
body.size_medium.color_blue .button__label {
  margin: 25px 0 0;
  box-shadow: none;
  border: 1px solid #195183;
  background: none;
}
body.size_medium.color_blue .about_block {
  border-color: #195183;
  background: none;
}
body.size_medium.color_blue .catalogue_title {
  color: #195183;
  font-size: 26px;
}
body.size_medium.color_blue input,
body.size_medium.color_blue textarea {
  border: 2px solid #195183 !important;
  background: #9dd1ff !important;
  font-size: 30px !important;
  color: #195183 !important;
  border-radius: 0px !important;
}
body.size_medium.color_blue #fixed_menu {
  background: #9dd1ff;
  box-shadow: none;
  border-bottom: 2px solid #195183;
}
body.size_medium.color_blue .fixed_menu_wrapper {
  background: none;
}
body.size_medium.color_blue .news_block {
  box-shadow: none;
}
body.size_medium.color_blue .news_about {
  padding-left: 10px;
  padding-right: 10px;
}
body.size_medium.color_blue .news_tag {
  font-size: 26px;
  color: #195183;
}
body.size_medium.color_blue .news_title {
  font-size: 30px;
}
body.size_medium.color_blue .visitors_block,
body.size_medium.color_blue .info_block,
body.size_medium.color_blue .digest_block,
body.size_medium.color_blue .libfl_block {
  background: none;
  border: 2px solid #195183;
  box-shadow: none;
}
body.size_medium.color_blue .libfl_block {
  padding-left: 30px;
  padding-right: 40px;
}
body.size_medium.color_blue .libfl_block .libfl_block__part a:before {
  display: none;
}
body.size_medium.color_blue .libfl_block .libfl_block__part a {
  font-size: 36px;
}
body.size_medium.color_blue .libfl_block .libfl_block__description span {
  color: #195183;
  font-size: 26px;
}
body.size_medium.color_blue .info_block .info_block__address {
  color: #195183;
  font-size: 40px;
}
body.size_medium.color_blue .info_block .info_block__address span {
  color: #195183;
  font-size: 26px;
}
body.size_medium.color_blue .info_block a {
  font-size: 30px;
}
body.size_medium.color_blue .info_block a.info_block__map {
  height: auto;
  background: none;
}
body.size_medium.color_blue .visitors_block .visitors_block__title {
  color: #195183;
  font-size: 36px;
  margin-bottom: 20px;
}
body.size_medium.color_blue .visitors_block a {
  font-size: 30px;
  font-weight: normal;
}
body.size_medium.color_blue .digest_block .digest_block__title {
  color: #195183;
  font-size: 36px;
  margin-bottom: 20px;
}
body.size_medium.color_blue .digest_block a {
  font-size: 30px;
}
body.size_medium.color_blue #popup_wrapper {
  background: rgba(0, 0, 0, 0.8);
}
body.size_medium.color_blue .popup_box {
  background: #9dd1ff !important;
  color: #195183 !important;
  font-size: 30px;
}
body.size_medium.color_blue .popup_box * {
  color: #195183 !important;
  line-height: 1.35 !important;
}
body.size_medium.color_blue .modal_parts .modal_part .modal_title {
  font-size: 36px;
}
body.size_medium.color_blue .modal_parts .modal_part .modal_subtitle {
  font-size: 30px;
  height: auto;
}
body.size_medium.color_blue .modal_parts ul.modal_list li,
body.size_medium.color_blue .modal_parts ol.modal_list li {
  font-size: 30px;
}
body.size_medium.color_blue .modal_parts ul.modal_list li:before,
body.size_medium.color_blue .modal_parts ol.modal_list li:before {
  color: #195183;
}
body.size_medium.color_blue .modal_cta {
  background: none;
  font-size: 40px;
}
body.size_medium.color_blue .modal_welcome span {
  font-size: 40px;
}
body.size_medium.color_blue .modal_welcome:before {
  display: none;
}
body.size_medium.color_blue .modal_welcome a.btn {
  background: none;
  border-radius: 0;
  border: 2px solid #195183;
  font-size: 30px;
  width: auto;
  height: auto;
  line-height: 1 !important;
}
body.size_medium.color_blue .modal_rules a.link {
  font-size: 26px;
}
body.size_medium.color_blue #popup_reader.popup_box .decoration_left,
body.size_medium.color_blue #popup_reader.popup_box .decoration_right {
  display: none;
}
body.size_medium.color_blue .event-info {
  width: 600px;
  height: auto;
  background: #9dd1ff;
  color: #195183;
}
body.size_medium.color_blue .inner-event-type span {
  font-size: 26px;
}
body.size_medium.color_blue .event-info__title,
body.size_medium.color_blue .new-info__title {
  font-size: 36px;
}
body.size_medium.color_blue .event-info__place {
  font-size: 26px;
}
body.size_medium.color_blue .event-info__shedule,
body.size_medium.color_blue .new-info__shedule {
  position: relative;
  width: 100%;
  bottom: auto;
  line-height: 1.35;
  margin-top: 20px;
}
body.size_medium.color_blue .event-info__date {
  font-size: 26px;
}
body.size_medium.color_blue .event-info__time {
  font-size: 36px;
  color: #195183;
}
body.size_medium.color_blue .event-info__admission {
  width: 250px;
}
body.size_medium.color_blue .event-info__admission * {
  color: #195183;
}
body.size_medium.color_blue .event-info__admission--docs {
  font-size: 26px;
  color: #195183;
}
body.size_medium.color_blue .block_wrapper * {
  font-size: 30px;
  color: #195183 !important;
}
body.size_medium.color_blue .block_wrapper,
body.size_medium.color_blue .cutted_block,
body.size_medium.color_blue .image_left_block,
body.size_medium.color_blue .image_right_block,
body.size_medium.color_blue .old_block p,
body.size_medium.color_blue .text_block {
  font-size: 30px;
}
body.size_medium.color_blue #news .block_wrapper:first-child .text_block {
  font-size: 36px;
}
body.size_medium.color_blue .block_wrapper h2 {
  font-size: 40px;
  line-height: 1.4;
}
body.size_medium.color_blue .block_wrapper h3 {
  font-size: 36px;
  line-height: 1.4;
}
body.size_medium.color_blue .block_wrapper h4 {
  font-size: 30px;
  line-height: 1.4;
}
body.size_medium.color_blue .image_block span.set,
body.size_medium.color_blue .image_left_block span.set,
body.size_medium.color_blue .image_right_block span.set,
body.size_medium.color_blue .image_block .image_desc,
body.size_medium.color_blue .image_left_block .image_desc,
body.size_medium.color_blue .image_right_block .image_desc {
  font-size: 26px;
}
body.size_medium.color_blue .button_link,
body.size_medium.color_blue .registration_link,
body.size_medium.color_blue .registration_link_instruction {
  font-size: 26px;
}
body.size_medium.color_blue .button_link .button_with_link,
body.size_medium.color_blue .registration_link .button_with_link,
body.size_medium.color_blue .registration_link_instruction .button_with_link {
  font-size: 26px;
  background: none;
  border: 2px solid #195183;
  height: auto;
  line-height: 1;
  padding: 20px;
  width: auto;
}
body.size_medium.color_blue .registration_link {
  border-color: #195183;
}
body.size_medium.color_blue .list_ul ul {
  font-size: 30px;
}
body.size_medium.color_blue .list_ol ol {
  font-size: 30px;
}
body.size_medium.color_blue .cite_block .cite {
  font-size: 40px;
}
body.size_medium.color_blue .cite_block .cite_by {
  font-size: 30px;
}
body.size_medium.color_blue .collections_block .collection_about a {
  font-size: 36px;
}
body.size_medium.color_blue .collections_block .collection_title {
  font-size: 40px;
}
body.size_medium.color_blue .collections_block .collection_info {
  font-size: 26px;
}
body.size_medium.color_blue .collections_block .collection_info a {
  font-size: 26px;
}
body.size_medium.color_blue .cutted_block .cut_block_button {
  font-size: 26px;
}
body.size_medium.color_blue .event_organizer,
body.size_medium.color_blue .event_additional_wrapper > span {
  font-size: 26px;
}
body.size_medium.color_blue .btn_social {
  font-size: 26px;
  background-color: #9dd1ff !important;
  color: #195183 !important;
  border: 1px solid #195183;
  height: auto;
  width: auto;
  padding: 5px 10px;
}
body.size_medium.color_blue .bottom_block {
  border-color: #195183;
}
body.size_medium.color_blue .tagline {
  font-size: 30px;
}
body.size_medium.color_blue .tagline small {
  font-size: 26px;
  color: #195183;
}
body.size_medium.color_blue .page-views {
  font-size: 26px;
  color: #195183;
}
body.size_medium.color_blue .article-description__header {
  font-size: 40px;
}
body.size_medium.color_blue .facilities_banner a.facilities_banner__description {
  font-size: 36px;
}
body.size_medium.color_blue .accordion dt a {
  font-size: 30px;
}
body.size_medium.color_blue .accordion dd .dd-row .dd-head {
  color: #195183;
}
body.size_medium.color_blue .accordion dd .dd-row .dd-cell,
body.size_medium.color_blue .accordion dd .dd-row .dd-head {
  font-size: 26px;
  color: #195183;
}
body.size_medium.color_blue .accordion span {
  font-size: 26px;
  color: #195183;
}
body.size_medium.color_blue .article-nav-childs {
  border: 2px solid #195183;
  background: none;
  font-size: 26px;
}
body.size_medium.color_blue .article-nav-childs__header {
  background: #9dd1ff;
  border-bottom: 2px solid #195183;
  font-size: inherit;
  color: #195183;
}
body.size_medium.color_blue .inner_pages_breadcrumbs {
  font-size: 26px;
  color: #195183;
}
body.size_medium.color_blue .contact_wrapper {
  background: none;
  border: 2px solid #195183;
}
body.size_medium.color_blue .contact_text_wrapper,
body.size_medium.color_blue .contact_text_wrapper {
  color: #195183;
  font-size: 30px;
}
body.size_medium.color_blue .contact_btn {
  background: #9dd1ff;
  color: #195183 !important;
  border: 1px solid #195183;
  box-shadow: none;
  font-size: 26px;
}
body.size_medium.color_blue .note {
  font-size: 26px;
}
body.size_medium.color_blue .scheme_header {
  font-size: 36px;
  line-height: 1.4;
}
body.size_medium.color_blue .section_switch_block {
  font-size: 30px;
  color: #195183;
  text-decoration: underline;
}
body.size_medium.color_blue .section_info_header {
  font-size: 36px;
}
body.size_medium.color_blue .section_info_text {
  font-size: 30px;
}
body.size_medium.color_blue .section_info_print {
  font-size: 30px;
}
body.size_medium.color_blue .event_content,
body.size_medium.color_blue .page_content {
  box-shadow: none;
}
body.size_medium.color_blue .scheme_switch_block {
  padding: 0;
}
body.size_medium.color_blue a.departments__item {
  font-size: 30px;
  height: auto;
  margin-bottom: 20px;
}
body.size_medium.color_blue .contact_cell span {
  font-size: 26px !important;
}
body.size_medium.color_blue .logo_wrapper {
  background: url('/images/svg/accent-logo-blue.svg') top center no-repeat;
}
body.size_medium.color_blue .logo_wrapper img {
  opacity: 0;
  visibility: hidden;
}
body.size_medium.color_blue .fall_through_link {
  color: #195183;
}
body.size_medium.color_blue .events-filters,
body.size_medium.color_blue .events-filters__wrapper {
  background: #9dd1ff;
  border: none;
}
body.size_medium.color_blue .icheckbox_minimal {
  border-color: #195183;
}
body.size_medium.color_blue .news_block {
  background-color: none;
}
body.size_medium.color_blue .new-info {
  background: #9dd1ff;
}
body.size_medium.color_blue .parking_note {
  float: none;
  margin-top: 100px;
  text-align: left;
}
body.size_medium.color_blue .parking_note:before {
  content: ' ';
  display: block;
  clear: both;
}
body.size_medium.color_blue .subway_label {
  background-color: #9dd1ff;
  border: 1px solid #195183;
}
body.size_medium.color_blue .event_content,
body.size_medium.color_blue .page_content {
  background: none;
}
body.size_medium.color_blue .scheme_header,
body.size_medium.color_blue .scheme_switch_block,
body.size_medium.color_blue ol.sections_switcher li:before {
  color: #195183;
}
body.size_medium.color_blue .scheme_switch_block.active {
  background: #195183;
  color: #9dd1ff;
}
body.size_medium.color_blue .section_switch_block.active {
  background: #195183;
  color: #9dd1ff;
}
body.size_medium.color_blue .section_info.active {
  background: #9dd1ff;
}
body.size_medium.color_blue .section_info_print {
  color: #195183;
  text-decoration: underline;
}
body.size_medium.color_blue .images_gallery .gallery_image {
  background: #9dd1ff;
}
body.size_medium.color_blue .list_ul ul li:before {
  background: #195183;
}
body.size_medium.color_blue .catalogue_search_form,
body.size_medium.color_blue .vf_search_form {
  border: none;
  background: none;
}
body.size_medium.color_blue .work_time a,
body.size_medium.color_blue a.work_time {
  border: none;
}
body.size_medium.color_blue .long-term-events__item {
  border-color: #195183;
}
body.size_medium.color_blue .news_block {
  background: #9dd1ff;
  border: 1px solid #195183;
  overflow: hidden;
}
body.size_medium.color_blue .inner_pages_breadcrumbs {
  border-color: #195183;
}
body.size_medium.color_blue .contact_table .contact_row .contact_cell {
  border-color: #195183;
}
body.size_medium.color_blue .event_organizer:after {
  border-color: #195183;
}
body.size_medium.color_blue .vf_search_select {
  box-shadow: none;
  background: #9dd1ff;
  border: 1px solid #195183;
  width: 300px;
}
body.size_medium.color_blue .index_search_option,
body.size_medium.color_blue .vf_search_option {
  border-color: #195183;
  color: #195183;
}
body.size_medium.color_blue .header {
  height: auto;
}
body.size_medium.color_blue .index_subscribe_block .subscribe-brief {
  line-height: 1.35;
}
body.size_medium.color_blue #footer {
  line-height: 1.35;
}
body.size_medium.color_blue .footer_copy_text {
  line-height: 1.35;
}
body.size_medium.color_blue .footer_social_wrapper .footer_list {
  line-height: 1.35;
}
body.size_medium.color_blue .nouvelle_small_text {
  color: #195183;
}
body.size_medium.color_blue .top-banner {
  background-color: #9dd1ff;
  border: 2px solid #195183;
  font-size: 30px;
}
body.size_medium.color_blue .top-banner__close {
  background-size: 35px;
}
body.size_medium.color_blue .flex-direction-nav a {
  width: 65px;
  height: 65px;
  background-size: 35px;
  background-color: #9dd1ff;
  border: 1px solid #195183;
}
body.size_medium.color_blue .flex-nav-prev {
  margin-right: 15px;
}
body.size_medium.color_blue .flex-control-paging li a {
  width: 15px;
  height: 15px;
  background-color: transparent;
  border: 1px solid #195183;
}
body.size_medium.color_blue .flex-control-paging li a.flex-active {
  background-color: #195183;
}
body.size_medium.color_blue .departments_block .departments_block__wrapper .departments_block__part a.departments_block__item img {
  height: auto;
  width: 65px;
}
body.size_medium.color_blue a.menu__link_footer img {
  width: 30px !important;
}
body.size_medium.color_blue .social-btn_vk {
  background-size: 30px;
}
body.size_medium.color_blue .social-btn_fb {
  background-size: 20px;
}
body.size_medium.color_blue .social-btn_ig {
  background-size: 22px;
}
body.size_medium.color_blue .social-btn_yt {
  background-size: 28px;
}
body.size_medium.color_blue .pro_column {
  width: 33%;
}
body.size_medium.color_blue .index_news {
  width: 65%;
}
body.size_medium.color_blue .index_pro_block .index_pro_content {
  padding-left: 0;
}
body.size_medium.color_blue .events-item_background .event-cycle {
  background-color: #9dd1ff;
  font-size: 30px;
}
body.size_medium.color_blue .news_about .centenary_tag,
body.size_medium.color_blue .event-tag {
  color: #195183;
  background-color: #9dd1ff;
  border: 1px solid #195183;
  font-size: 26px;
}
body.size_medium.color_blue .side_social_wrapper > ul > li {
  width: 48px;
  height: 48px;
  border-color: #195183;
}
body.size_medium.color_blue .side_social_wrapper > ul > li > a > svg {
  fill: #195183;
  width: 22px;
  height: 22px;
}
body.size_medium.color_blue .partners_column a.partners_block {
  border: 2px solid #195183;
  padding: 10px 15px;
}
body.size_medium.color_blue .partners_column a.partners_block .partners_block_title {
  font-size: 30px;
  color: #195183;
}
body.size_medium.color_blue .test-wrapper {
  grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
}
body.size_medium.color_blue .long-term-events {
  width: 100%;
}
body.size_medium.color_blue .news_wrapper.listing_block {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
  grid-gap: 23px;
}
body.size_medium.color_blue .news_block {
  float: none;
  margin: 0 !important;
  width: auto;
}
body.size_medium.color_blue .news_image {
  width: 100%;
  background-position: center;
}
body.size_medium.color_blue .news_title {
  line-height: 1.2;
}
body.size_large.color_blackonwhite {
  font-size: 40px;
  color: #000;
  background-color: #fff;
  padding-top: 130px;
}
body.size_large.color_blackonwhite .fixed_menu_wrapper .js-login-screen,
body.size_large.color_blackonwhite .fixed_menu_wrapper .js-become-member {
  display: none;
}
body.size_large.color_blackonwhite #special_version_controls {
  border: 1px solid #000;
  background: #fff;
  margin-bottom: 6px;
  border-radius: 0 0 4px 4px;
  border-top: 0;
  width: 90%;
  max-width: 1200px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99999;
  padding: 20px;
  text-align: center;
  display: block;
}
body.size_large.color_blackonwhite .special_version_controls_group {
  display: inline-block;
  margin-right: 40px;
  margin-bottom: 30px;
}
body.size_large.color_blackonwhite .special_version_controls_label {
  display: inline-block;
  vertical-align: top;
  padding-top: 6px;
  font-size: 20px;
}
body.size_large.color_blackonwhite .special_version_controls_select {
  display: inline-block;
  vertical-align: top;
  margin-left: 10px;
}
body.size_large.color_blackonwhite .special_version_controls_point {
  display: block;
  vertical-align: top;
  position: relative;
  background: #fff;
  border: 1px solid #000;
  margin-right: -1px;
  cursor: pointer;
  height: 41px;
  width: 41px;
  text-align: center;
  line-height: 1;
  padding: 10px 12px 6px;
  font-size: 20px;
  float: left;
}
body.size_large.color_blackonwhite .special_version_controls_point:first-child {
  border-radius: 4px 0 0 4px;
}
body.size_large.color_blackonwhite .special_version_controls_point:last-child {
  border-radius: 0 4px 4px 0;
}
body.size_large.color_blackonwhite .special_version_controls_point.active:before {
  content: ' ';
  display: block;
  position: absolute;
  width: 100%;
  height: 1px;
  border-bottom: 2px dashed #000;
  left: 0;
  bottom: -7px;
}
body.size_large.color_blackonwhite .special_version_controls_select[data-parameter="size"] .special_version_controls_point {
  color: #000;
  background-color: #fff;
}
body.size_large.color_blackonwhite .special_version_controls_select[data-parameter="size"] .special_version_controls_point[data-value="medium"] {
  font-size: 30px;
  padding: 4px 0 0;
}
body.size_large.color_blackonwhite .special_version_controls_select[data-parameter="size"] .special_version_controls_point[data-value="large"] {
  font-size: 40px;
  padding: 0;
}
body.size_large.color_blackonwhite .special_version_controls_select[data-parameter="color"] .special_version_controls_point[data-value="blackonwhite"] {
  color: #000;
  background: #fff;
}
body.size_large.color_blackonwhite .special_version_controls_select[data-parameter="color"] .special_version_controls_point[data-value="whiteonblack"] {
  color: #fff;
  background: #000;
}
body.size_large.color_blackonwhite .special_version_controls_select[data-parameter="color"] .special_version_controls_point[data-value="blue"] {
  background: #9dd1ff;
  color: #195183;
}
body.size_large.color_blackonwhite .special_version_controls_select[data-parameter="graphics"] .special_version_controls_point[data-value="normal"] {
  background: #0000f3;
  color: #fff;
}
body.size_large.color_blackonwhite .special_version_controls_select[data-parameter="graphics"] .special_version_controls_point[data-value="mono"] {
  background: #ddd;
}
body.size_large.color_blackonwhite .special_version_controls_select[data-parameter="graphics"] .special_version_controls_point[data-value="disabled"] {
  color: #fff;
}
body.size_large.color_blackonwhite a.menu__item.link.link_blue:first-of-type {
  margin-right: 0;
}
body.size_large.color_blackonwhite .header__menu .menu__item {
  margin-right: 25px;
}
body.size_large.color_blackonwhite .layout-wrapper {
  color: #000;
  background-color: #fff;
}
body.size_large.color_blackonwhite .infobar {
  height: auto;
  font-size: 40px;
  background: #fff;
}
body.size_large.color_blackonwhite .infobar:after {
  content: ' ';
  display: block;
  clear: both;
}
body.size_large.color_blackonwhite .infobar__link,
body.size_large.color_blackonwhite .infobar__link_academy {
  color: #000;
  font-size: 36px;
}
body.size_large.color_blackonwhite a.infobar__link_academy:before {
  display: none;
}
body.size_large.color_blackonwhite a.menu__link {
  font-size: 40px;
  color: #000;
}
body.size_large.color_blackonwhite a {
  color: #000 !important;
  text-decoration: underline !important;
}
body.size_large.color_blackonwhite .become-member {
  font-size: 40px;
}
body.size_large.color_blackonwhite #nav {
  border: none;
  margin: 40px 0;
}
body.size_large.color_blackonwhite .full-index {
  font-size: 40px;
}
body.size_large.color_blackonwhite .full-index:after {
  display: none;
}
body.size_large.color_blackonwhite .search_switch {
  font-size: 40px;
  color: #000;
}
body.size_large.color_blackonwhite .quick-links {
  margin-left: 50px;
  width: 350px;
}
body.size_large.color_blackonwhite a.quick-links__item,
body.size_large.color_blackonwhite a.closing-time {
  font-size: 40px;
}
body.size_large.color_blackonwhite a.closing-time:after {
  display: none;
}
body.size_large.color_blackonwhite .wisemen_block {
  display: none !important;
}
body.size_large.color_blackonwhite .vf_search_wrapper {
  width: 540px;
}
body.size_large.color_blackonwhite input#index_search,
body.size_large.color_blackonwhite input#search {
  font-size: 40px;
}
body.size_large.color_blackonwhite .index_header {
  font-size: 50px;
}
body.size_large.color_blackonwhite .index_subscribe_block {
  height: auto;
  margin-bottom: 20px;
}
body.size_large.color_blackonwhite .index_subscribe_block .subscribe-brief {
  color: #000;
  font-size: 36px;
}
body.size_large.color_blackonwhite .index_subscribe_block .subscribe-brief strong {
  font-size: 40px;
  color: #000;
  display: block;
  text-decoration: underline;
}
body.size_large.color_blackonwhite .departments_block {
  background: #fff;
  padding: 40px;
  box-shadow: none;
}
body.size_large.color_blackonwhite .departments_block .departments_block__wrapper .departments_block__subheader {
  color: #000;
  font-size: 50px;
}
body.size_large.color_blackonwhite .departments_block .departments_block__wrapper .departments_block__part a.departments_block__item {
  border: none;
  margin-bottom: 10px;
}
body.size_large.color_blackonwhite .departments_block .departments_block__wrapper .departments_block__part a.departments_block__item span {
  color: #000;
  font-size: 40px;
  line-height: 1.35;
}
body.size_large.color_blackonwhite .departments_block__decoration {
  display: none;
}
body.size_large.color_blackonwhite .fall_through_link {
  font-size: 40px;
}
body.size_large.color_blackonwhite .fall_through_link:after {
  display: none;
}
body.size_large.color_blackonwhite .nouvelle_desc a,
body.size_large.color_blackonwhite .nouvelle_desc small {
  color: #000;
  font-size: 36px;
}
body.size_large.color_blackonwhite .nouvelle_small_text,
body.size_large.color_blackonwhite .nouvelle_title {
  font-size: 40px;
}
body.size_large.color_blackonwhite .index_nouvelle_main .nouvelle_title {
  font-size: 46px;
}
body.size_large.color_blackonwhite .index_pro_block .index_pro_content {
  background-color: #fff;
}
body.size_large.color_blackonwhite .index_pro_block .index_pro_content .index_pro_content_title,
body.size_large.color_blackonwhite .index_pro_block .index_pro_content .index_pro_content_text {
  font-size: 40px;
}
body.size_large.color_blackonwhite .index_pro_block .index_pro_content small {
  color: #000;
  font-size: 36px;
}
body.size_large.color_blackonwhite .index_news_wrapper * {
  line-height: 1.35 !important;
}
body.size_large.color_blackonwhite .collection_links a {
  font-size: 36px;
}
body.size_large.color_blackonwhite .collection_info a {
  font-size: 36px;
}
body.size_large.color_blackonwhite .index_collections_wrapper .collection_title {
  font-size: 46px;
}
body.size_large.color_blackonwhite .index_collections_wrapper .collection_about small {
  font-size: 36px;
  color: #000 !important;
}
body.size_large.color_blackonwhite .header__menu .menu__item {
  font-size: 40px;
}
body.size_large.color_blackonwhite .menu__item_active {
  border-bottom: 2px dashed #000;
}
body.size_large.color_blackonwhite #footer {
  color: #000;
  background: #fff;
  font-size: 36px;
}
body.size_large.color_blackonwhite .footer_social_header {
  color: #000;
  font-size: 40px;
}
body.size_large.color_blackonwhite a.menu__link_footer {
  font-size: 40px;
}
body.size_large.color_blackonwhite .footer_header {
  font-size: 40px;
  color: #000;
}
body.size_large.color_blackonwhite .work_time p {
  font-size: 36px;
}
body.size_large.color_blackonwhite .footer_column_left {
  max-width: 50%;
}
body.size_large.color_blackonwhite .footer_copy {
  border-top-color: #fff;
  color: #000;
  font-size: 36px;
}
body.size_large.color_blackonwhite .footer_banner img {
  display: none;
}
body.size_large.color_blackonwhite .footer_banner a {
  font-size: 40px;
}
body.size_large.color_blackonwhite .events-item__image {
  background: #fff !important;
}
body.size_large.color_blackonwhite .event-type a,
body.size_large.color_blackonwhite .events_cat a,
body.size_large.color_blackonwhite .inner-event-type a,
body.size_large.color_blackonwhite .new-type a {
  font-size: 36px;
}
body.size_large.color_blackonwhite .events-item__place {
  font-size: 40px;
}
body.size_large.color_blackonwhite .events-item__header {
  font-size: 46px;
}
body.size_large.color_blackonwhite .events-item__time {
  font-size: 50px;
}
body.size_large.color_blackonwhite .event-type a,
body.size_large.color_blackonwhite .events_cat a,
body.size_large.color_blackonwhite .inner-event-type a,
body.size_large.color_blackonwhite .new-type a,
body.size_large.color_blackonwhite .events-item__place,
body.size_large.color_blackonwhite .events-item__header,
body.size_large.color_blackonwhite .events-item__time {
  color: #000;
}
body.size_large.color_blackonwhite .long-term-events {
  background: #fff;
}
body.size_large.color_blackonwhite .long-term-events__title,
body.size_large.color_blackonwhite a.long-term-events__item-header {
  color: #000;
  font-size: 40px;
}
body.size_large.color_blackonwhite .event-date__day-of-the-month {
  font-size: 46px;
}
body.size_large.color_blackonwhite .event-date__day-of-the-month small {
  font-size: 40px;
}
body.size_large.color_blackonwhite .event-date_weekday {
  font-size: 40px;
  color: #000;
}
body.size_large.color_blackonwhite .event-date:before {
  display: none;
}
body.size_large.color_blackonwhite .btn__show-more {
  border-color: #000;
  color: #000;
  font-size: 46px;
}
body.size_large.color_blackonwhite .subscribe-header {
  font-size: 46px;
  color: #000;
}
body.size_large.color_blackonwhite .subscribe-brief {
  color: #000;
  font-size: 40px;
}
body.size_large.color_blackonwhite .subscribe_block .subscribe-btn {
  font-size: 40px;
  width: 240px;
  color: #000;
  background: transparent;
  border: 1px solid #000;
  padding: 0;
  line-height: 85px;
}
body.size_large.color_blackonwhite .events-by-date * {
  line-height: 1.35 !important;
}
body.size_large.color_blackonwhite .events_date_btn,
body.size_large.color_blackonwhite .ui-selectmenu-button,
body.size_large.color_blackonwhite .ui-state-focus.ui-selectmenu-button,
body.size_large.color_blackonwhite .ui-state-hover.ui-selectmenu-button,
body.size_large.color_blackonwhite label.checkbox,
body.size_large.color_blackonwhite .ui-menu .ui-menu-item {
  font-size: 40px;
  color: #000;
  background: #fff;
}
body.size_large.color_blackonwhite .catalogue-search_background {
  background: none;
}
body.size_large.color_blackonwhite .catalogue-search_title {
  font-size: 50px;
}
body.size_large.color_blackonwhite .catalogue-search_title span {
  font-size: 46px;
}
body.size_large.color_blackonwhite .catalogue_search_form {
  box-shadow: none;
  height: 65px;
  border: 2px solid #000;
  font-size: 40px;
}
body.size_large.color_blackonwhite .index_search_option,
body.size_large.color_blackonwhite .vf_search_option {
  font-size: 40px;
}
body.size_large.color_blackonwhite .button__filled {
  border-radius: 0;
  border: 1px solid #000;
  font-size: 40px;
  background: #fff;
}
body.size_large.color_blackonwhite .catalogue-item {
  border: 2px solid #000;
  background: none !important;
}
body.size_large.color_blackonwhite .catalogue-item_title {
  font-size: 50px;
}
body.size_large.color_blackonwhite .catalogue-item_links a {
  font-size: 40px;
}
body.size_large.color_blackonwhite a.button {
  font-size: 40px;
}
body.size_large.color_blackonwhite .button__label {
  margin: 25px 0 0;
  box-shadow: none;
  border: 1px solid #000;
  background: none;
}
body.size_large.color_blackonwhite .about_block {
  border-color: #000;
  background: none;
}
body.size_large.color_blackonwhite .catalogue_title {
  color: #000;
  font-size: 36px;
}
body.size_large.color_blackonwhite input,
body.size_large.color_blackonwhite textarea {
  border: 2px solid #000 !important;
  background: #fff !important;
  font-size: 40px !important;
  color: #000 !important;
  border-radius: 0px !important;
}
body.size_large.color_blackonwhite #fixed_menu {
  background: #fff;
  box-shadow: none;
  border-bottom: 2px solid #000;
}
body.size_large.color_blackonwhite .fixed_menu_wrapper {
  background: none;
}
body.size_large.color_blackonwhite .news_block {
  box-shadow: none;
}
body.size_large.color_blackonwhite .news_about {
  padding-left: 10px;
  padding-right: 10px;
}
body.size_large.color_blackonwhite .news_tag {
  font-size: 36px;
  color: #000;
}
body.size_large.color_blackonwhite .news_title {
  font-size: 40px;
}
body.size_large.color_blackonwhite .visitors_block,
body.size_large.color_blackonwhite .info_block,
body.size_large.color_blackonwhite .digest_block,
body.size_large.color_blackonwhite .libfl_block {
  background: none;
  border: 2px solid #000;
  box-shadow: none;
}
body.size_large.color_blackonwhite .libfl_block {
  padding-left: 30px;
  padding-right: 40px;
}
body.size_large.color_blackonwhite .libfl_block .libfl_block__part a:before {
  display: none;
}
body.size_large.color_blackonwhite .libfl_block .libfl_block__part a {
  font-size: 46px;
}
body.size_large.color_blackonwhite .libfl_block .libfl_block__description span {
  color: #000;
  font-size: 36px;
}
body.size_large.color_blackonwhite .info_block .info_block__address {
  color: #000;
  font-size: 50px;
}
body.size_large.color_blackonwhite .info_block .info_block__address span {
  color: #000;
  font-size: 36px;
}
body.size_large.color_blackonwhite .info_block a {
  font-size: 40px;
}
body.size_large.color_blackonwhite .info_block a.info_block__map {
  height: auto;
  background: none;
}
body.size_large.color_blackonwhite .visitors_block .visitors_block__title {
  color: #000;
  font-size: 46px;
  margin-bottom: 20px;
}
body.size_large.color_blackonwhite .visitors_block a {
  font-size: 40px;
  font-weight: normal;
}
body.size_large.color_blackonwhite .digest_block .digest_block__title {
  color: #000;
  font-size: 46px;
  margin-bottom: 20px;
}
body.size_large.color_blackonwhite .digest_block a {
  font-size: 40px;
}
body.size_large.color_blackonwhite #popup_wrapper {
  background: rgba(0, 0, 0, 0.8);
}
body.size_large.color_blackonwhite .popup_box {
  background: #fff !important;
  color: #000 !important;
  font-size: 40px;
}
body.size_large.color_blackonwhite .popup_box * {
  color: #000 !important;
  line-height: 1.35 !important;
}
body.size_large.color_blackonwhite .modal_parts .modal_part .modal_title {
  font-size: 46px;
}
body.size_large.color_blackonwhite .modal_parts .modal_part .modal_subtitle {
  font-size: 40px;
  height: auto;
}
body.size_large.color_blackonwhite .modal_parts ul.modal_list li,
body.size_large.color_blackonwhite .modal_parts ol.modal_list li {
  font-size: 40px;
}
body.size_large.color_blackonwhite .modal_parts ul.modal_list li:before,
body.size_large.color_blackonwhite .modal_parts ol.modal_list li:before {
  color: #000;
}
body.size_large.color_blackonwhite .modal_cta {
  background: none;
  font-size: 50px;
}
body.size_large.color_blackonwhite .modal_welcome span {
  font-size: 50px;
}
body.size_large.color_blackonwhite .modal_welcome:before {
  display: none;
}
body.size_large.color_blackonwhite .modal_welcome a.btn {
  background: none;
  border-radius: 0;
  border: 2px solid #000;
  font-size: 40px;
  width: auto;
  height: auto;
  line-height: 1 !important;
}
body.size_large.color_blackonwhite .modal_rules a.link {
  font-size: 36px;
}
body.size_large.color_blackonwhite #popup_reader.popup_box .decoration_left,
body.size_large.color_blackonwhite #popup_reader.popup_box .decoration_right {
  display: none;
}
body.size_large.color_blackonwhite .event-info {
  width: 600px;
  height: auto;
  background: #fff;
  color: #000;
}
body.size_large.color_blackonwhite .inner-event-type span {
  font-size: 36px;
}
body.size_large.color_blackonwhite .event-info__title,
body.size_large.color_blackonwhite .new-info__title {
  font-size: 46px;
}
body.size_large.color_blackonwhite .event-info__place {
  font-size: 36px;
}
body.size_large.color_blackonwhite .event-info__shedule,
body.size_large.color_blackonwhite .new-info__shedule {
  position: relative;
  width: 100%;
  bottom: auto;
  line-height: 1.35;
  margin-top: 20px;
}
body.size_large.color_blackonwhite .event-info__date {
  font-size: 36px;
}
body.size_large.color_blackonwhite .event-info__time {
  font-size: 46px;
  color: #000;
}
body.size_large.color_blackonwhite .event-info__admission {
  width: 250px;
}
body.size_large.color_blackonwhite .event-info__admission * {
  color: #000;
}
body.size_large.color_blackonwhite .event-info__admission--docs {
  font-size: 36px;
  color: #000;
}
body.size_large.color_blackonwhite .block_wrapper * {
  font-size: 40px;
  color: #000 !important;
}
body.size_large.color_blackonwhite .block_wrapper,
body.size_large.color_blackonwhite .cutted_block,
body.size_large.color_blackonwhite .image_left_block,
body.size_large.color_blackonwhite .image_right_block,
body.size_large.color_blackonwhite .old_block p,
body.size_large.color_blackonwhite .text_block {
  font-size: 40px;
}
body.size_large.color_blackonwhite #news .block_wrapper:first-child .text_block {
  font-size: 46px;
}
body.size_large.color_blackonwhite .block_wrapper h2 {
  font-size: 50px;
  line-height: 1.4;
}
body.size_large.color_blackonwhite .block_wrapper h3 {
  font-size: 46px;
  line-height: 1.4;
}
body.size_large.color_blackonwhite .block_wrapper h4 {
  font-size: 40px;
  line-height: 1.4;
}
body.size_large.color_blackonwhite .image_block span.set,
body.size_large.color_blackonwhite .image_left_block span.set,
body.size_large.color_blackonwhite .image_right_block span.set,
body.size_large.color_blackonwhite .image_block .image_desc,
body.size_large.color_blackonwhite .image_left_block .image_desc,
body.size_large.color_blackonwhite .image_right_block .image_desc {
  font-size: 36px;
}
body.size_large.color_blackonwhite .button_link,
body.size_large.color_blackonwhite .registration_link,
body.size_large.color_blackonwhite .registration_link_instruction {
  font-size: 36px;
}
body.size_large.color_blackonwhite .button_link .button_with_link,
body.size_large.color_blackonwhite .registration_link .button_with_link,
body.size_large.color_blackonwhite .registration_link_instruction .button_with_link {
  font-size: 36px;
  background: none;
  border: 2px solid #000;
  height: auto;
  line-height: 1;
  padding: 20px;
  width: auto;
}
body.size_large.color_blackonwhite .registration_link {
  border-color: #000;
}
body.size_large.color_blackonwhite .list_ul ul {
  font-size: 40px;
}
body.size_large.color_blackonwhite .list_ol ol {
  font-size: 40px;
}
body.size_large.color_blackonwhite .cite_block .cite {
  font-size: 50px;
}
body.size_large.color_blackonwhite .cite_block .cite_by {
  font-size: 40px;
}
body.size_large.color_blackonwhite .collections_block .collection_about a {
  font-size: 46px;
}
body.size_large.color_blackonwhite .collections_block .collection_title {
  font-size: 50px;
}
body.size_large.color_blackonwhite .collections_block .collection_info {
  font-size: 36px;
}
body.size_large.color_blackonwhite .collections_block .collection_info a {
  font-size: 36px;
}
body.size_large.color_blackonwhite .cutted_block .cut_block_button {
  font-size: 36px;
}
body.size_large.color_blackonwhite .event_organizer,
body.size_large.color_blackonwhite .event_additional_wrapper > span {
  font-size: 36px;
}
body.size_large.color_blackonwhite .btn_social {
  font-size: 36px;
  background-color: #fff !important;
  color: #000 !important;
  border: 1px solid #000;
  height: auto;
  width: auto;
  padding: 5px 10px;
}
body.size_large.color_blackonwhite .bottom_block {
  border-color: #000;
}
body.size_large.color_blackonwhite .tagline {
  font-size: 40px;
}
body.size_large.color_blackonwhite .tagline small {
  font-size: 36px;
  color: #000;
}
body.size_large.color_blackonwhite .page-views {
  font-size: 36px;
  color: #000;
}
body.size_large.color_blackonwhite .article-description__header {
  font-size: 50px;
}
body.size_large.color_blackonwhite .facilities_banner a.facilities_banner__description {
  font-size: 46px;
}
body.size_large.color_blackonwhite .accordion dt a {
  font-size: 40px;
}
body.size_large.color_blackonwhite .accordion dd .dd-row .dd-head {
  color: #000;
}
body.size_large.color_blackonwhite .accordion dd .dd-row .dd-cell,
body.size_large.color_blackonwhite .accordion dd .dd-row .dd-head {
  font-size: 36px;
  color: #000;
}
body.size_large.color_blackonwhite .accordion span {
  font-size: 36px;
  color: #000;
}
body.size_large.color_blackonwhite .article-nav-childs {
  border: 2px solid #000;
  background: none;
  font-size: 36px;
}
body.size_large.color_blackonwhite .article-nav-childs__header {
  background: #fff;
  border-bottom: 2px solid #000;
  font-size: inherit;
  color: #000;
}
body.size_large.color_blackonwhite .inner_pages_breadcrumbs {
  font-size: 36px;
  color: #000;
}
body.size_large.color_blackonwhite .contact_wrapper {
  background: none;
  border: 2px solid #000;
}
body.size_large.color_blackonwhite .contact_text_wrapper,
body.size_large.color_blackonwhite .contact_text_wrapper {
  color: #000;
  font-size: 40px;
}
body.size_large.color_blackonwhite .contact_btn {
  background: #fff;
  color: #000 !important;
  border: 1px solid #000;
  box-shadow: none;
  font-size: 36px;
}
body.size_large.color_blackonwhite .note {
  font-size: 36px;
}
body.size_large.color_blackonwhite .scheme_header {
  font-size: 46px;
  line-height: 1.4;
}
body.size_large.color_blackonwhite .section_switch_block {
  font-size: 40px;
  color: #000;
  text-decoration: underline;
}
body.size_large.color_blackonwhite .section_info_header {
  font-size: 46px;
}
body.size_large.color_blackonwhite .section_info_text {
  font-size: 40px;
}
body.size_large.color_blackonwhite .section_info_print {
  font-size: 40px;
}
body.size_large.color_blackonwhite .event_content,
body.size_large.color_blackonwhite .page_content {
  box-shadow: none;
}
body.size_large.color_blackonwhite .scheme_switch_block {
  padding: 0;
}
body.size_large.color_blackonwhite a.departments__item {
  font-size: 40px;
  height: auto;
  margin-bottom: 20px;
}
body.size_large.color_blackonwhite .contact_cell span {
  font-size: 36px !important;
}
body.size_large.color_blackonwhite .logo_wrapper {
  background: url('/images/svg/accent-logo-black.svg') top center no-repeat;
}
body.size_large.color_blackonwhite .logo_wrapper img {
  opacity: 0;
  visibility: hidden;
}
body.size_large.color_blackonwhite .fall_through_link {
  color: #000;
}
body.size_large.color_blackonwhite .events-filters,
body.size_large.color_blackonwhite .events-filters__wrapper {
  background: #fff;
  border: none;
}
body.size_large.color_blackonwhite .icheckbox_minimal {
  border-color: #000;
}
body.size_large.color_blackonwhite .news_block {
  background-color: none;
}
body.size_large.color_blackonwhite .new-info {
  background: #fff;
}
body.size_large.color_blackonwhite .parking_note {
  float: none;
  margin-top: 100px;
  text-align: left;
}
body.size_large.color_blackonwhite .parking_note:before {
  content: ' ';
  display: block;
  clear: both;
}
body.size_large.color_blackonwhite .subway_label {
  background-color: #fff;
  border: 1px solid #000;
}
body.size_large.color_blackonwhite .event_content,
body.size_large.color_blackonwhite .page_content {
  background: none;
}
body.size_large.color_blackonwhite .scheme_header,
body.size_large.color_blackonwhite .scheme_switch_block,
body.size_large.color_blackonwhite ol.sections_switcher li:before {
  color: #000;
}
body.size_large.color_blackonwhite .scheme_switch_block.active {
  background: #000;
  color: #fff;
}
body.size_large.color_blackonwhite .section_switch_block.active {
  background: #000;
  color: #fff;
}
body.size_large.color_blackonwhite .section_info.active {
  background: #fff;
}
body.size_large.color_blackonwhite .section_info_print {
  color: #000;
  text-decoration: underline;
}
body.size_large.color_blackonwhite .images_gallery .gallery_image {
  background: #fff;
}
body.size_large.color_blackonwhite .list_ul ul li:before {
  background: #000;
}
body.size_large.color_blackonwhite .catalogue_search_form,
body.size_large.color_blackonwhite .vf_search_form {
  border: none;
  background: none;
}
body.size_large.color_blackonwhite .work_time a,
body.size_large.color_blackonwhite a.work_time {
  border: none;
}
body.size_large.color_blackonwhite .long-term-events__item {
  border-color: #000;
}
body.size_large.color_blackonwhite .news_block {
  background: #fff;
  border: 1px solid #000;
  overflow: hidden;
}
body.size_large.color_blackonwhite .inner_pages_breadcrumbs {
  border-color: #000;
}
body.size_large.color_blackonwhite .contact_table .contact_row .contact_cell {
  border-color: #000;
}
body.size_large.color_blackonwhite .event_organizer:after {
  border-color: #000;
}
body.size_large.color_blackonwhite .vf_search_select {
  box-shadow: none;
  background: #fff;
  border: 1px solid #000;
  width: 300px;
}
body.size_large.color_blackonwhite .index_search_option,
body.size_large.color_blackonwhite .vf_search_option {
  border-color: #000;
  color: #000;
}
body.size_large.color_blackonwhite .header {
  height: auto;
}
body.size_large.color_blackonwhite .index_subscribe_block .subscribe-brief {
  line-height: 1.35;
}
body.size_large.color_blackonwhite #footer {
  line-height: 1.35;
}
body.size_large.color_blackonwhite .footer_copy_text {
  line-height: 1.35;
}
body.size_large.color_blackonwhite .footer_social_wrapper .footer_list {
  line-height: 1.35;
}
body.size_large.color_blackonwhite .nouvelle_small_text {
  color: #000;
}
body.size_large.color_blackonwhite .top-banner {
  background-color: #fff;
  border: 2px solid #000;
  font-size: 40px;
}
body.size_large.color_blackonwhite .top-banner__close {
  background-size: 35px;
}
body.size_large.color_blackonwhite .flex-direction-nav a {
  width: 65px;
  height: 65px;
  background-size: 35px;
  background-color: #fff;
  border: 1px solid #000;
}
body.size_large.color_blackonwhite .flex-nav-prev {
  margin-right: 15px;
}
body.size_large.color_blackonwhite .flex-control-paging li a {
  width: 15px;
  height: 15px;
  background-color: transparent;
  border: 1px solid #000;
}
body.size_large.color_blackonwhite .flex-control-paging li a.flex-active {
  background-color: #000;
}
body.size_large.color_blackonwhite .departments_block .departments_block__wrapper .departments_block__part a.departments_block__item img {
  height: auto;
  width: 65px;
}
body.size_large.color_blackonwhite a.menu__link_footer img {
  width: 30px !important;
}
body.size_large.color_blackonwhite .social-btn_vk {
  background-size: 30px;
}
body.size_large.color_blackonwhite .social-btn_fb {
  background-size: 20px;
}
body.size_large.color_blackonwhite .social-btn_ig {
  background-size: 22px;
}
body.size_large.color_blackonwhite .social-btn_yt {
  background-size: 28px;
}
body.size_large.color_blackonwhite .pro_column {
  width: 33%;
}
body.size_large.color_blackonwhite .index_news {
  width: 65%;
}
body.size_large.color_blackonwhite .index_pro_block .index_pro_content {
  padding-left: 0;
}
body.size_large.color_blackonwhite .events-item_background .event-cycle {
  background-color: #fff;
  font-size: 40px;
}
body.size_large.color_blackonwhite .news_about .centenary_tag,
body.size_large.color_blackonwhite .event-tag {
  color: #000;
  background-color: #fff;
  border: 1px solid #000;
  font-size: 36px;
}
body.size_large.color_blackonwhite .side_social_wrapper > ul > li {
  width: 48px;
  height: 48px;
  border-color: #000;
}
body.size_large.color_blackonwhite .side_social_wrapper > ul > li > a > svg {
  fill: #000;
  width: 22px;
  height: 22px;
}
body.size_large.color_blackonwhite .partners_column a.partners_block {
  border: 2px solid #000;
  padding: 10px 15px;
}
body.size_large.color_blackonwhite .partners_column a.partners_block .partners_block_title {
  font-size: 40px;
  color: #000;
}
body.size_large.color_blackonwhite .test-wrapper {
  grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
}
body.size_large.color_blackonwhite .long-term-events {
  width: 100%;
}
body.size_large.color_blackonwhite .news_wrapper.listing_block {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
  grid-gap: 23px;
}
body.size_large.color_blackonwhite .news_block {
  float: none;
  margin: 0 !important;
  width: auto;
}
body.size_large.color_blackonwhite .news_image {
  width: 100%;
  background-position: center;
}
body.size_large.color_blackonwhite .news_title {
  line-height: 1.2;
}
body.size_large.color_whiteonblack {
  font-size: 40px;
  color: #fff;
  background-color: #000;
  padding-top: 130px;
}
body.size_large.color_whiteonblack .fixed_menu_wrapper .js-login-screen,
body.size_large.color_whiteonblack .fixed_menu_wrapper .js-become-member {
  display: none;
}
body.size_large.color_whiteonblack #special_version_controls {
  border: 1px solid #fff;
  background: #000;
  margin-bottom: 6px;
  border-radius: 0 0 4px 4px;
  border-top: 0;
  width: 90%;
  max-width: 1200px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99999;
  padding: 20px;
  text-align: center;
  display: block;
}
body.size_large.color_whiteonblack .special_version_controls_group {
  display: inline-block;
  margin-right: 40px;
  margin-bottom: 30px;
}
body.size_large.color_whiteonblack .special_version_controls_label {
  display: inline-block;
  vertical-align: top;
  padding-top: 6px;
  font-size: 20px;
}
body.size_large.color_whiteonblack .special_version_controls_select {
  display: inline-block;
  vertical-align: top;
  margin-left: 10px;
}
body.size_large.color_whiteonblack .special_version_controls_point {
  display: block;
  vertical-align: top;
  position: relative;
  background: #fff;
  border: 1px solid #000;
  margin-right: -1px;
  cursor: pointer;
  height: 41px;
  width: 41px;
  text-align: center;
  line-height: 1;
  padding: 10px 12px 6px;
  font-size: 20px;
  float: left;
}
body.size_large.color_whiteonblack .special_version_controls_point:first-child {
  border-radius: 4px 0 0 4px;
}
body.size_large.color_whiteonblack .special_version_controls_point:last-child {
  border-radius: 0 4px 4px 0;
}
body.size_large.color_whiteonblack .special_version_controls_point.active:before {
  content: ' ';
  display: block;
  position: absolute;
  width: 100%;
  height: 1px;
  border-bottom: 2px dashed #fff;
  left: 0;
  bottom: -7px;
}
body.size_large.color_whiteonblack .special_version_controls_select[data-parameter="size"] .special_version_controls_point {
  color: #fff;
  background-color: #000;
}
body.size_large.color_whiteonblack .special_version_controls_select[data-parameter="size"] .special_version_controls_point[data-value="medium"] {
  font-size: 30px;
  padding: 4px 0 0;
}
body.size_large.color_whiteonblack .special_version_controls_select[data-parameter="size"] .special_version_controls_point[data-value="large"] {
  font-size: 40px;
  padding: 0;
}
body.size_large.color_whiteonblack .special_version_controls_select[data-parameter="color"] .special_version_controls_point[data-value="blackonwhite"] {
  color: #000;
  background: #fff;
}
body.size_large.color_whiteonblack .special_version_controls_select[data-parameter="color"] .special_version_controls_point[data-value="whiteonblack"] {
  color: #fff;
  background: #000;
}
body.size_large.color_whiteonblack .special_version_controls_select[data-parameter="color"] .special_version_controls_point[data-value="blue"] {
  background: #9dd1ff;
  color: #195183;
}
body.size_large.color_whiteonblack .special_version_controls_select[data-parameter="graphics"] .special_version_controls_point[data-value="normal"] {
  background: #0000f3;
  color: #fff;
}
body.size_large.color_whiteonblack .special_version_controls_select[data-parameter="graphics"] .special_version_controls_point[data-value="mono"] {
  background: #ddd;
}
body.size_large.color_whiteonblack .special_version_controls_select[data-parameter="graphics"] .special_version_controls_point[data-value="disabled"] {
  color: #fff;
}
body.size_large.color_whiteonblack a.menu__item.link.link_blue:first-of-type {
  margin-right: 0;
}
body.size_large.color_whiteonblack .header__menu .menu__item {
  margin-right: 25px;
}
body.size_large.color_whiteonblack .layout-wrapper {
  color: #fff;
  background-color: #000;
}
body.size_large.color_whiteonblack .infobar {
  height: auto;
  font-size: 40px;
  background: #000;
}
body.size_large.color_whiteonblack .infobar:after {
  content: ' ';
  display: block;
  clear: both;
}
body.size_large.color_whiteonblack .infobar__link,
body.size_large.color_whiteonblack .infobar__link_academy {
  color: #fff;
  font-size: 36px;
}
body.size_large.color_whiteonblack a.infobar__link_academy:before {
  display: none;
}
body.size_large.color_whiteonblack a.menu__link {
  font-size: 40px;
  color: #fff;
}
body.size_large.color_whiteonblack a {
  color: #fff !important;
  text-decoration: underline !important;
}
body.size_large.color_whiteonblack .become-member {
  font-size: 40px;
}
body.size_large.color_whiteonblack #nav {
  border: none;
  margin: 40px 0;
}
body.size_large.color_whiteonblack .full-index {
  font-size: 40px;
}
body.size_large.color_whiteonblack .full-index:after {
  display: none;
}
body.size_large.color_whiteonblack .search_switch {
  font-size: 40px;
  color: #fff;
}
body.size_large.color_whiteonblack .quick-links {
  margin-left: 50px;
  width: 350px;
}
body.size_large.color_whiteonblack a.quick-links__item,
body.size_large.color_whiteonblack a.closing-time {
  font-size: 40px;
}
body.size_large.color_whiteonblack a.closing-time:after {
  display: none;
}
body.size_large.color_whiteonblack .wisemen_block {
  display: none !important;
}
body.size_large.color_whiteonblack .vf_search_wrapper {
  width: 540px;
}
body.size_large.color_whiteonblack input#index_search,
body.size_large.color_whiteonblack input#search {
  font-size: 40px;
}
body.size_large.color_whiteonblack .index_header {
  font-size: 50px;
}
body.size_large.color_whiteonblack .index_subscribe_block {
  height: auto;
  margin-bottom: 20px;
}
body.size_large.color_whiteonblack .index_subscribe_block .subscribe-brief {
  color: #fff;
  font-size: 36px;
}
body.size_large.color_whiteonblack .index_subscribe_block .subscribe-brief strong {
  font-size: 40px;
  color: #fff;
  display: block;
  text-decoration: underline;
}
body.size_large.color_whiteonblack .departments_block {
  background: #000;
  padding: 40px;
  box-shadow: none;
}
body.size_large.color_whiteonblack .departments_block .departments_block__wrapper .departments_block__subheader {
  color: #fff;
  font-size: 50px;
}
body.size_large.color_whiteonblack .departments_block .departments_block__wrapper .departments_block__part a.departments_block__item {
  border: none;
  margin-bottom: 10px;
}
body.size_large.color_whiteonblack .departments_block .departments_block__wrapper .departments_block__part a.departments_block__item span {
  color: #fff;
  font-size: 40px;
  line-height: 1.35;
}
body.size_large.color_whiteonblack .departments_block__decoration {
  display: none;
}
body.size_large.color_whiteonblack .fall_through_link {
  font-size: 40px;
}
body.size_large.color_whiteonblack .fall_through_link:after {
  display: none;
}
body.size_large.color_whiteonblack .nouvelle_desc a,
body.size_large.color_whiteonblack .nouvelle_desc small {
  color: #fff;
  font-size: 36px;
}
body.size_large.color_whiteonblack .nouvelle_small_text,
body.size_large.color_whiteonblack .nouvelle_title {
  font-size: 40px;
}
body.size_large.color_whiteonblack .index_nouvelle_main .nouvelle_title {
  font-size: 46px;
}
body.size_large.color_whiteonblack .index_pro_block .index_pro_content {
  background-color: #000;
}
body.size_large.color_whiteonblack .index_pro_block .index_pro_content .index_pro_content_title,
body.size_large.color_whiteonblack .index_pro_block .index_pro_content .index_pro_content_text {
  font-size: 40px;
}
body.size_large.color_whiteonblack .index_pro_block .index_pro_content small {
  color: #fff;
  font-size: 36px;
}
body.size_large.color_whiteonblack .index_news_wrapper * {
  line-height: 1.35 !important;
}
body.size_large.color_whiteonblack .collection_links a {
  font-size: 36px;
}
body.size_large.color_whiteonblack .collection_info a {
  font-size: 36px;
}
body.size_large.color_whiteonblack .index_collections_wrapper .collection_title {
  font-size: 46px;
}
body.size_large.color_whiteonblack .index_collections_wrapper .collection_about small {
  font-size: 36px;
  color: #fff !important;
}
body.size_large.color_whiteonblack .header__menu .menu__item {
  font-size: 40px;
}
body.size_large.color_whiteonblack .menu__item_active {
  border-bottom: 2px dashed #fff;
}
body.size_large.color_whiteonblack #footer {
  color: #fff;
  background: #000;
  font-size: 36px;
}
body.size_large.color_whiteonblack .footer_social_header {
  color: #fff;
  font-size: 40px;
}
body.size_large.color_whiteonblack a.menu__link_footer {
  font-size: 40px;
}
body.size_large.color_whiteonblack .footer_header {
  font-size: 40px;
  color: #fff;
}
body.size_large.color_whiteonblack .work_time p {
  font-size: 36px;
}
body.size_large.color_whiteonblack .footer_column_left {
  max-width: 50%;
}
body.size_large.color_whiteonblack .footer_copy {
  border-top-color: #000;
  color: #fff;
  font-size: 36px;
}
body.size_large.color_whiteonblack .footer_banner img {
  display: none;
}
body.size_large.color_whiteonblack .footer_banner a {
  font-size: 40px;
}
body.size_large.color_whiteonblack .events-item__image {
  background: #000 !important;
}
body.size_large.color_whiteonblack .event-type a,
body.size_large.color_whiteonblack .events_cat a,
body.size_large.color_whiteonblack .inner-event-type a,
body.size_large.color_whiteonblack .new-type a {
  font-size: 36px;
}
body.size_large.color_whiteonblack .events-item__place {
  font-size: 40px;
}
body.size_large.color_whiteonblack .events-item__header {
  font-size: 46px;
}
body.size_large.color_whiteonblack .events-item__time {
  font-size: 50px;
}
body.size_large.color_whiteonblack .event-type a,
body.size_large.color_whiteonblack .events_cat a,
body.size_large.color_whiteonblack .inner-event-type a,
body.size_large.color_whiteonblack .new-type a,
body.size_large.color_whiteonblack .events-item__place,
body.size_large.color_whiteonblack .events-item__header,
body.size_large.color_whiteonblack .events-item__time {
  color: #fff;
}
body.size_large.color_whiteonblack .long-term-events {
  background: #000;
}
body.size_large.color_whiteonblack .long-term-events__title,
body.size_large.color_whiteonblack a.long-term-events__item-header {
  color: #fff;
  font-size: 40px;
}
body.size_large.color_whiteonblack .event-date__day-of-the-month {
  font-size: 46px;
}
body.size_large.color_whiteonblack .event-date__day-of-the-month small {
  font-size: 40px;
}
body.size_large.color_whiteonblack .event-date_weekday {
  font-size: 40px;
  color: #fff;
}
body.size_large.color_whiteonblack .event-date:before {
  display: none;
}
body.size_large.color_whiteonblack .btn__show-more {
  border-color: #fff;
  color: #fff;
  font-size: 46px;
}
body.size_large.color_whiteonblack .subscribe-header {
  font-size: 46px;
  color: #fff;
}
body.size_large.color_whiteonblack .subscribe-brief {
  color: #fff;
  font-size: 40px;
}
body.size_large.color_whiteonblack .subscribe_block .subscribe-btn {
  font-size: 40px;
  width: 240px;
  color: #fff;
  background: transparent;
  border: 1px solid #fff;
  padding: 0;
  line-height: 85px;
}
body.size_large.color_whiteonblack .events-by-date * {
  line-height: 1.35 !important;
}
body.size_large.color_whiteonblack .events_date_btn,
body.size_large.color_whiteonblack .ui-selectmenu-button,
body.size_large.color_whiteonblack .ui-state-focus.ui-selectmenu-button,
body.size_large.color_whiteonblack .ui-state-hover.ui-selectmenu-button,
body.size_large.color_whiteonblack label.checkbox,
body.size_large.color_whiteonblack .ui-menu .ui-menu-item {
  font-size: 40px;
  color: #fff;
  background: #000;
}
body.size_large.color_whiteonblack .catalogue-search_background {
  background: none;
}
body.size_large.color_whiteonblack .catalogue-search_title {
  font-size: 50px;
}
body.size_large.color_whiteonblack .catalogue-search_title span {
  font-size: 46px;
}
body.size_large.color_whiteonblack .catalogue_search_form {
  box-shadow: none;
  height: 65px;
  border: 2px solid #fff;
  font-size: 40px;
}
body.size_large.color_whiteonblack .index_search_option,
body.size_large.color_whiteonblack .vf_search_option {
  font-size: 40px;
}
body.size_large.color_whiteonblack .button__filled {
  border-radius: 0;
  border: 1px solid #fff;
  font-size: 40px;
  background: #000;
}
body.size_large.color_whiteonblack .catalogue-item {
  border: 2px solid #fff;
  background: none !important;
}
body.size_large.color_whiteonblack .catalogue-item_title {
  font-size: 50px;
}
body.size_large.color_whiteonblack .catalogue-item_links a {
  font-size: 40px;
}
body.size_large.color_whiteonblack a.button {
  font-size: 40px;
}
body.size_large.color_whiteonblack .button__label {
  margin: 25px 0 0;
  box-shadow: none;
  border: 1px solid #fff;
  background: none;
}
body.size_large.color_whiteonblack .about_block {
  border-color: #fff;
  background: none;
}
body.size_large.color_whiteonblack .catalogue_title {
  color: #fff;
  font-size: 36px;
}
body.size_large.color_whiteonblack input,
body.size_large.color_whiteonblack textarea {
  border: 2px solid #fff !important;
  background: #000 !important;
  font-size: 40px !important;
  color: #fff !important;
  border-radius: 0px !important;
}
body.size_large.color_whiteonblack #fixed_menu {
  background: #000;
  box-shadow: none;
  border-bottom: 2px solid #fff;
}
body.size_large.color_whiteonblack .fixed_menu_wrapper {
  background: none;
}
body.size_large.color_whiteonblack .news_block {
  box-shadow: none;
}
body.size_large.color_whiteonblack .news_about {
  padding-left: 10px;
  padding-right: 10px;
}
body.size_large.color_whiteonblack .news_tag {
  font-size: 36px;
  color: #fff;
}
body.size_large.color_whiteonblack .news_title {
  font-size: 40px;
}
body.size_large.color_whiteonblack .visitors_block,
body.size_large.color_whiteonblack .info_block,
body.size_large.color_whiteonblack .digest_block,
body.size_large.color_whiteonblack .libfl_block {
  background: none;
  border: 2px solid #fff;
  box-shadow: none;
}
body.size_large.color_whiteonblack .libfl_block {
  padding-left: 30px;
  padding-right: 40px;
}
body.size_large.color_whiteonblack .libfl_block .libfl_block__part a:before {
  display: none;
}
body.size_large.color_whiteonblack .libfl_block .libfl_block__part a {
  font-size: 46px;
}
body.size_large.color_whiteonblack .libfl_block .libfl_block__description span {
  color: #fff;
  font-size: 36px;
}
body.size_large.color_whiteonblack .info_block .info_block__address {
  color: #fff;
  font-size: 50px;
}
body.size_large.color_whiteonblack .info_block .info_block__address span {
  color: #fff;
  font-size: 36px;
}
body.size_large.color_whiteonblack .info_block a {
  font-size: 40px;
}
body.size_large.color_whiteonblack .info_block a.info_block__map {
  height: auto;
  background: none;
}
body.size_large.color_whiteonblack .visitors_block .visitors_block__title {
  color: #fff;
  font-size: 46px;
  margin-bottom: 20px;
}
body.size_large.color_whiteonblack .visitors_block a {
  font-size: 40px;
  font-weight: normal;
}
body.size_large.color_whiteonblack .digest_block .digest_block__title {
  color: #fff;
  font-size: 46px;
  margin-bottom: 20px;
}
body.size_large.color_whiteonblack .digest_block a {
  font-size: 40px;
}
body.size_large.color_whiteonblack #popup_wrapper {
  background: rgba(0, 0, 0, 0.8);
}
body.size_large.color_whiteonblack .popup_box {
  background: #000 !important;
  color: #fff !important;
  font-size: 40px;
}
body.size_large.color_whiteonblack .popup_box * {
  color: #fff !important;
  line-height: 1.35 !important;
}
body.size_large.color_whiteonblack .modal_parts .modal_part .modal_title {
  font-size: 46px;
}
body.size_large.color_whiteonblack .modal_parts .modal_part .modal_subtitle {
  font-size: 40px;
  height: auto;
}
body.size_large.color_whiteonblack .modal_parts ul.modal_list li,
body.size_large.color_whiteonblack .modal_parts ol.modal_list li {
  font-size: 40px;
}
body.size_large.color_whiteonblack .modal_parts ul.modal_list li:before,
body.size_large.color_whiteonblack .modal_parts ol.modal_list li:before {
  color: #fff;
}
body.size_large.color_whiteonblack .modal_cta {
  background: none;
  font-size: 50px;
}
body.size_large.color_whiteonblack .modal_welcome span {
  font-size: 50px;
}
body.size_large.color_whiteonblack .modal_welcome:before {
  display: none;
}
body.size_large.color_whiteonblack .modal_welcome a.btn {
  background: none;
  border-radius: 0;
  border: 2px solid #fff;
  font-size: 40px;
  width: auto;
  height: auto;
  line-height: 1 !important;
}
body.size_large.color_whiteonblack .modal_rules a.link {
  font-size: 36px;
}
body.size_large.color_whiteonblack #popup_reader.popup_box .decoration_left,
body.size_large.color_whiteonblack #popup_reader.popup_box .decoration_right {
  display: none;
}
body.size_large.color_whiteonblack .event-info {
  width: 600px;
  height: auto;
  background: #000;
  color: #fff;
}
body.size_large.color_whiteonblack .inner-event-type span {
  font-size: 36px;
}
body.size_large.color_whiteonblack .event-info__title,
body.size_large.color_whiteonblack .new-info__title {
  font-size: 46px;
}
body.size_large.color_whiteonblack .event-info__place {
  font-size: 36px;
}
body.size_large.color_whiteonblack .event-info__shedule,
body.size_large.color_whiteonblack .new-info__shedule {
  position: relative;
  width: 100%;
  bottom: auto;
  line-height: 1.35;
  margin-top: 20px;
}
body.size_large.color_whiteonblack .event-info__date {
  font-size: 36px;
}
body.size_large.color_whiteonblack .event-info__time {
  font-size: 46px;
  color: #fff;
}
body.size_large.color_whiteonblack .event-info__admission {
  width: 250px;
}
body.size_large.color_whiteonblack .event-info__admission * {
  color: #fff;
}
body.size_large.color_whiteonblack .event-info__admission--docs {
  font-size: 36px;
  color: #fff;
}
body.size_large.color_whiteonblack .block_wrapper * {
  font-size: 40px;
  color: #fff !important;
}
body.size_large.color_whiteonblack .block_wrapper,
body.size_large.color_whiteonblack .cutted_block,
body.size_large.color_whiteonblack .image_left_block,
body.size_large.color_whiteonblack .image_right_block,
body.size_large.color_whiteonblack .old_block p,
body.size_large.color_whiteonblack .text_block {
  font-size: 40px;
}
body.size_large.color_whiteonblack #news .block_wrapper:first-child .text_block {
  font-size: 46px;
}
body.size_large.color_whiteonblack .block_wrapper h2 {
  font-size: 50px;
  line-height: 1.4;
}
body.size_large.color_whiteonblack .block_wrapper h3 {
  font-size: 46px;
  line-height: 1.4;
}
body.size_large.color_whiteonblack .block_wrapper h4 {
  font-size: 40px;
  line-height: 1.4;
}
body.size_large.color_whiteonblack .image_block span.set,
body.size_large.color_whiteonblack .image_left_block span.set,
body.size_large.color_whiteonblack .image_right_block span.set,
body.size_large.color_whiteonblack .image_block .image_desc,
body.size_large.color_whiteonblack .image_left_block .image_desc,
body.size_large.color_whiteonblack .image_right_block .image_desc {
  font-size: 36px;
}
body.size_large.color_whiteonblack .button_link,
body.size_large.color_whiteonblack .registration_link,
body.size_large.color_whiteonblack .registration_link_instruction {
  font-size: 36px;
}
body.size_large.color_whiteonblack .button_link .button_with_link,
body.size_large.color_whiteonblack .registration_link .button_with_link,
body.size_large.color_whiteonblack .registration_link_instruction .button_with_link {
  font-size: 36px;
  background: none;
  border: 2px solid #fff;
  height: auto;
  line-height: 1;
  padding: 20px;
  width: auto;
}
body.size_large.color_whiteonblack .registration_link {
  border-color: #fff;
}
body.size_large.color_whiteonblack .list_ul ul {
  font-size: 40px;
}
body.size_large.color_whiteonblack .list_ol ol {
  font-size: 40px;
}
body.size_large.color_whiteonblack .cite_block .cite {
  font-size: 50px;
}
body.size_large.color_whiteonblack .cite_block .cite_by {
  font-size: 40px;
}
body.size_large.color_whiteonblack .collections_block .collection_about a {
  font-size: 46px;
}
body.size_large.color_whiteonblack .collections_block .collection_title {
  font-size: 50px;
}
body.size_large.color_whiteonblack .collections_block .collection_info {
  font-size: 36px;
}
body.size_large.color_whiteonblack .collections_block .collection_info a {
  font-size: 36px;
}
body.size_large.color_whiteonblack .cutted_block .cut_block_button {
  font-size: 36px;
}
body.size_large.color_whiteonblack .event_organizer,
body.size_large.color_whiteonblack .event_additional_wrapper > span {
  font-size: 36px;
}
body.size_large.color_whiteonblack .btn_social {
  font-size: 36px;
  background-color: #000 !important;
  color: #fff !important;
  border: 1px solid #fff;
  height: auto;
  width: auto;
  padding: 5px 10px;
}
body.size_large.color_whiteonblack .bottom_block {
  border-color: #fff;
}
body.size_large.color_whiteonblack .tagline {
  font-size: 40px;
}
body.size_large.color_whiteonblack .tagline small {
  font-size: 36px;
  color: #fff;
}
body.size_large.color_whiteonblack .page-views {
  font-size: 36px;
  color: #fff;
}
body.size_large.color_whiteonblack .article-description__header {
  font-size: 50px;
}
body.size_large.color_whiteonblack .facilities_banner a.facilities_banner__description {
  font-size: 46px;
}
body.size_large.color_whiteonblack .accordion dt a {
  font-size: 40px;
}
body.size_large.color_whiteonblack .accordion dd .dd-row .dd-head {
  color: #fff;
}
body.size_large.color_whiteonblack .accordion dd .dd-row .dd-cell,
body.size_large.color_whiteonblack .accordion dd .dd-row .dd-head {
  font-size: 36px;
  color: #fff;
}
body.size_large.color_whiteonblack .accordion span {
  font-size: 36px;
  color: #fff;
}
body.size_large.color_whiteonblack .article-nav-childs {
  border: 2px solid #fff;
  background: none;
  font-size: 36px;
}
body.size_large.color_whiteonblack .article-nav-childs__header {
  background: #000;
  border-bottom: 2px solid #fff;
  font-size: inherit;
  color: #fff;
}
body.size_large.color_whiteonblack .inner_pages_breadcrumbs {
  font-size: 36px;
  color: #fff;
}
body.size_large.color_whiteonblack .contact_wrapper {
  background: none;
  border: 2px solid #fff;
}
body.size_large.color_whiteonblack .contact_text_wrapper,
body.size_large.color_whiteonblack .contact_text_wrapper {
  color: #fff;
  font-size: 40px;
}
body.size_large.color_whiteonblack .contact_btn {
  background: #000;
  color: #fff !important;
  border: 1px solid #fff;
  box-shadow: none;
  font-size: 36px;
}
body.size_large.color_whiteonblack .note {
  font-size: 36px;
}
body.size_large.color_whiteonblack .scheme_header {
  font-size: 46px;
  line-height: 1.4;
}
body.size_large.color_whiteonblack .section_switch_block {
  font-size: 40px;
  color: #fff;
  text-decoration: underline;
}
body.size_large.color_whiteonblack .section_info_header {
  font-size: 46px;
}
body.size_large.color_whiteonblack .section_info_text {
  font-size: 40px;
}
body.size_large.color_whiteonblack .section_info_print {
  font-size: 40px;
}
body.size_large.color_whiteonblack .event_content,
body.size_large.color_whiteonblack .page_content {
  box-shadow: none;
}
body.size_large.color_whiteonblack .scheme_switch_block {
  padding: 0;
}
body.size_large.color_whiteonblack a.departments__item {
  font-size: 40px;
  height: auto;
  margin-bottom: 20px;
}
body.size_large.color_whiteonblack .contact_cell span {
  font-size: 36px !important;
}
body.size_large.color_whiteonblack .logo_wrapper {
  background: url('/images/svg/accent-logo-white.svg') top center no-repeat;
}
body.size_large.color_whiteonblack .logo_wrapper img {
  opacity: 0;
  visibility: hidden;
}
body.size_large.color_whiteonblack .fall_through_link {
  color: #fff;
}
body.size_large.color_whiteonblack .events-filters,
body.size_large.color_whiteonblack .events-filters__wrapper {
  background: #000;
  border: none;
}
body.size_large.color_whiteonblack .icheckbox_minimal {
  border-color: #fff;
}
body.size_large.color_whiteonblack .news_block {
  background-color: none;
}
body.size_large.color_whiteonblack .new-info {
  background: #000;
}
body.size_large.color_whiteonblack .parking_note {
  float: none;
  margin-top: 100px;
  text-align: left;
}
body.size_large.color_whiteonblack .parking_note:before {
  content: ' ';
  display: block;
  clear: both;
}
body.size_large.color_whiteonblack .subway_label {
  background-color: #000;
  border: 1px solid #fff;
}
body.size_large.color_whiteonblack .event_content,
body.size_large.color_whiteonblack .page_content {
  background: none;
}
body.size_large.color_whiteonblack .scheme_header,
body.size_large.color_whiteonblack .scheme_switch_block,
body.size_large.color_whiteonblack ol.sections_switcher li:before {
  color: #fff;
}
body.size_large.color_whiteonblack .scheme_switch_block.active {
  background: #fff;
  color: #000;
}
body.size_large.color_whiteonblack .section_switch_block.active {
  background: #fff;
  color: #000;
}
body.size_large.color_whiteonblack .section_info.active {
  background: #000;
}
body.size_large.color_whiteonblack .section_info_print {
  color: #fff;
  text-decoration: underline;
}
body.size_large.color_whiteonblack .images_gallery .gallery_image {
  background: #000;
}
body.size_large.color_whiteonblack .list_ul ul li:before {
  background: #fff;
}
body.size_large.color_whiteonblack .catalogue_search_form,
body.size_large.color_whiteonblack .vf_search_form {
  border: none;
  background: none;
}
body.size_large.color_whiteonblack .work_time a,
body.size_large.color_whiteonblack a.work_time {
  border: none;
}
body.size_large.color_whiteonblack .long-term-events__item {
  border-color: #fff;
}
body.size_large.color_whiteonblack .news_block {
  background: #000;
  border: 1px solid #fff;
  overflow: hidden;
}
body.size_large.color_whiteonblack .inner_pages_breadcrumbs {
  border-color: #fff;
}
body.size_large.color_whiteonblack .contact_table .contact_row .contact_cell {
  border-color: #fff;
}
body.size_large.color_whiteonblack .event_organizer:after {
  border-color: #fff;
}
body.size_large.color_whiteonblack .vf_search_select {
  box-shadow: none;
  background: #000;
  border: 1px solid #fff;
  width: 300px;
}
body.size_large.color_whiteonblack .index_search_option,
body.size_large.color_whiteonblack .vf_search_option {
  border-color: #fff;
  color: #fff;
}
body.size_large.color_whiteonblack .header {
  height: auto;
}
body.size_large.color_whiteonblack .index_subscribe_block .subscribe-brief {
  line-height: 1.35;
}
body.size_large.color_whiteonblack #footer {
  line-height: 1.35;
}
body.size_large.color_whiteonblack .footer_copy_text {
  line-height: 1.35;
}
body.size_large.color_whiteonblack .footer_social_wrapper .footer_list {
  line-height: 1.35;
}
body.size_large.color_whiteonblack .nouvelle_small_text {
  color: #fff;
}
body.size_large.color_whiteonblack .top-banner {
  background-color: #000;
  border: 2px solid #fff;
  font-size: 40px;
}
body.size_large.color_whiteonblack .top-banner__close {
  background-size: 35px;
}
body.size_large.color_whiteonblack .flex-direction-nav a {
  width: 65px;
  height: 65px;
  background-size: 35px;
  background-color: #000;
  border: 1px solid #fff;
}
body.size_large.color_whiteonblack .flex-nav-prev {
  margin-right: 15px;
}
body.size_large.color_whiteonblack .flex-control-paging li a {
  width: 15px;
  height: 15px;
  background-color: transparent;
  border: 1px solid #fff;
}
body.size_large.color_whiteonblack .flex-control-paging li a.flex-active {
  background-color: #fff;
}
body.size_large.color_whiteonblack .departments_block .departments_block__wrapper .departments_block__part a.departments_block__item img {
  height: auto;
  width: 65px;
}
body.size_large.color_whiteonblack a.menu__link_footer img {
  width: 30px !important;
}
body.size_large.color_whiteonblack .social-btn_vk {
  background-size: 30px;
}
body.size_large.color_whiteonblack .social-btn_fb {
  background-size: 20px;
}
body.size_large.color_whiteonblack .social-btn_ig {
  background-size: 22px;
}
body.size_large.color_whiteonblack .social-btn_yt {
  background-size: 28px;
}
body.size_large.color_whiteonblack .pro_column {
  width: 33%;
}
body.size_large.color_whiteonblack .index_news {
  width: 65%;
}
body.size_large.color_whiteonblack .index_pro_block .index_pro_content {
  padding-left: 0;
}
body.size_large.color_whiteonblack .events-item_background .event-cycle {
  background-color: #000;
  font-size: 40px;
}
body.size_large.color_whiteonblack .news_about .centenary_tag,
body.size_large.color_whiteonblack .event-tag {
  color: #fff;
  background-color: #000;
  border: 1px solid #fff;
  font-size: 36px;
}
body.size_large.color_whiteonblack .side_social_wrapper > ul > li {
  width: 48px;
  height: 48px;
  border-color: #fff;
}
body.size_large.color_whiteonblack .side_social_wrapper > ul > li > a > svg {
  fill: #fff;
  width: 22px;
  height: 22px;
}
body.size_large.color_whiteonblack .partners_column a.partners_block {
  border: 2px solid #fff;
  padding: 10px 15px;
}
body.size_large.color_whiteonblack .partners_column a.partners_block .partners_block_title {
  font-size: 40px;
  color: #fff;
}
body.size_large.color_whiteonblack .test-wrapper {
  grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
}
body.size_large.color_whiteonblack .long-term-events {
  width: 100%;
}
body.size_large.color_whiteonblack .news_wrapper.listing_block {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
  grid-gap: 23px;
}
body.size_large.color_whiteonblack .news_block {
  float: none;
  margin: 0 !important;
  width: auto;
}
body.size_large.color_whiteonblack .news_image {
  width: 100%;
  background-position: center;
}
body.size_large.color_whiteonblack .news_title {
  line-height: 1.2;
}
body.size_large.color_blue {
  font-size: 40px;
  color: #195183;
  background-color: #9dd1ff;
  padding-top: 130px;
}
body.size_large.color_blue .fixed_menu_wrapper .js-login-screen,
body.size_large.color_blue .fixed_menu_wrapper .js-become-member {
  display: none;
}
body.size_large.color_blue #special_version_controls {
  border: 1px solid #195183;
  background: #9dd1ff;
  margin-bottom: 6px;
  border-radius: 0 0 4px 4px;
  border-top: 0;
  width: 90%;
  max-width: 1200px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99999;
  padding: 20px;
  text-align: center;
  display: block;
}
body.size_large.color_blue .special_version_controls_group {
  display: inline-block;
  margin-right: 40px;
  margin-bottom: 30px;
}
body.size_large.color_blue .special_version_controls_label {
  display: inline-block;
  vertical-align: top;
  padding-top: 6px;
  font-size: 20px;
}
body.size_large.color_blue .special_version_controls_select {
  display: inline-block;
  vertical-align: top;
  margin-left: 10px;
}
body.size_large.color_blue .special_version_controls_point {
  display: block;
  vertical-align: top;
  position: relative;
  background: #fff;
  border: 1px solid #000;
  margin-right: -1px;
  cursor: pointer;
  height: 41px;
  width: 41px;
  text-align: center;
  line-height: 1;
  padding: 10px 12px 6px;
  font-size: 20px;
  float: left;
}
body.size_large.color_blue .special_version_controls_point:first-child {
  border-radius: 4px 0 0 4px;
}
body.size_large.color_blue .special_version_controls_point:last-child {
  border-radius: 0 4px 4px 0;
}
body.size_large.color_blue .special_version_controls_point.active:before {
  content: ' ';
  display: block;
  position: absolute;
  width: 100%;
  height: 1px;
  border-bottom: 2px dashed #195183;
  left: 0;
  bottom: -7px;
}
body.size_large.color_blue .special_version_controls_select[data-parameter="size"] .special_version_controls_point {
  color: #195183;
  background-color: #9dd1ff;
}
body.size_large.color_blue .special_version_controls_select[data-parameter="size"] .special_version_controls_point[data-value="medium"] {
  font-size: 30px;
  padding: 4px 0 0;
}
body.size_large.color_blue .special_version_controls_select[data-parameter="size"] .special_version_controls_point[data-value="large"] {
  font-size: 40px;
  padding: 0;
}
body.size_large.color_blue .special_version_controls_select[data-parameter="color"] .special_version_controls_point[data-value="blackonwhite"] {
  color: #000;
  background: #fff;
}
body.size_large.color_blue .special_version_controls_select[data-parameter="color"] .special_version_controls_point[data-value="whiteonblack"] {
  color: #fff;
  background: #000;
}
body.size_large.color_blue .special_version_controls_select[data-parameter="color"] .special_version_controls_point[data-value="blue"] {
  background: #9dd1ff;
  color: #195183;
}
body.size_large.color_blue .special_version_controls_select[data-parameter="graphics"] .special_version_controls_point[data-value="normal"] {
  background: #0000f3;
  color: #fff;
}
body.size_large.color_blue .special_version_controls_select[data-parameter="graphics"] .special_version_controls_point[data-value="mono"] {
  background: #ddd;
}
body.size_large.color_blue .special_version_controls_select[data-parameter="graphics"] .special_version_controls_point[data-value="disabled"] {
  color: #fff;
}
body.size_large.color_blue a.menu__item.link.link_blue:first-of-type {
  margin-right: 0;
}
body.size_large.color_blue .header__menu .menu__item {
  margin-right: 25px;
}
body.size_large.color_blue .layout-wrapper {
  color: #195183;
  background-color: #9dd1ff;
}
body.size_large.color_blue .infobar {
  height: auto;
  font-size: 40px;
  background: #9dd1ff;
}
body.size_large.color_blue .infobar:after {
  content: ' ';
  display: block;
  clear: both;
}
body.size_large.color_blue .infobar__link,
body.size_large.color_blue .infobar__link_academy {
  color: #195183;
  font-size: 36px;
}
body.size_large.color_blue a.infobar__link_academy:before {
  display: none;
}
body.size_large.color_blue a.menu__link {
  font-size: 40px;
  color: #195183;
}
body.size_large.color_blue a {
  color: #195183 !important;
  text-decoration: underline !important;
}
body.size_large.color_blue .become-member {
  font-size: 40px;
}
body.size_large.color_blue #nav {
  border: none;
  margin: 40px 0;
}
body.size_large.color_blue .full-index {
  font-size: 40px;
}
body.size_large.color_blue .full-index:after {
  display: none;
}
body.size_large.color_blue .search_switch {
  font-size: 40px;
  color: #195183;
}
body.size_large.color_blue .quick-links {
  margin-left: 50px;
  width: 350px;
}
body.size_large.color_blue a.quick-links__item,
body.size_large.color_blue a.closing-time {
  font-size: 40px;
}
body.size_large.color_blue a.closing-time:after {
  display: none;
}
body.size_large.color_blue .wisemen_block {
  display: none !important;
}
body.size_large.color_blue .vf_search_wrapper {
  width: 540px;
}
body.size_large.color_blue input#index_search,
body.size_large.color_blue input#search {
  font-size: 40px;
}
body.size_large.color_blue .index_header {
  font-size: 50px;
}
body.size_large.color_blue .index_subscribe_block {
  height: auto;
  margin-bottom: 20px;
}
body.size_large.color_blue .index_subscribe_block .subscribe-brief {
  color: #195183;
  font-size: 36px;
}
body.size_large.color_blue .index_subscribe_block .subscribe-brief strong {
  font-size: 40px;
  color: #195183;
  display: block;
  text-decoration: underline;
}
body.size_large.color_blue .departments_block {
  background: #9dd1ff;
  padding: 40px;
  box-shadow: none;
}
body.size_large.color_blue .departments_block .departments_block__wrapper .departments_block__subheader {
  color: #195183;
  font-size: 50px;
}
body.size_large.color_blue .departments_block .departments_block__wrapper .departments_block__part a.departments_block__item {
  border: none;
  margin-bottom: 10px;
}
body.size_large.color_blue .departments_block .departments_block__wrapper .departments_block__part a.departments_block__item span {
  color: #195183;
  font-size: 40px;
  line-height: 1.35;
}
body.size_large.color_blue .departments_block__decoration {
  display: none;
}
body.size_large.color_blue .fall_through_link {
  font-size: 40px;
}
body.size_large.color_blue .fall_through_link:after {
  display: none;
}
body.size_large.color_blue .nouvelle_desc a,
body.size_large.color_blue .nouvelle_desc small {
  color: #195183;
  font-size: 36px;
}
body.size_large.color_blue .nouvelle_small_text,
body.size_large.color_blue .nouvelle_title {
  font-size: 40px;
}
body.size_large.color_blue .index_nouvelle_main .nouvelle_title {
  font-size: 46px;
}
body.size_large.color_blue .index_pro_block .index_pro_content {
  background-color: #9dd1ff;
}
body.size_large.color_blue .index_pro_block .index_pro_content .index_pro_content_title,
body.size_large.color_blue .index_pro_block .index_pro_content .index_pro_content_text {
  font-size: 40px;
}
body.size_large.color_blue .index_pro_block .index_pro_content small {
  color: #195183;
  font-size: 36px;
}
body.size_large.color_blue .index_news_wrapper * {
  line-height: 1.35 !important;
}
body.size_large.color_blue .collection_links a {
  font-size: 36px;
}
body.size_large.color_blue .collection_info a {
  font-size: 36px;
}
body.size_large.color_blue .index_collections_wrapper .collection_title {
  font-size: 46px;
}
body.size_large.color_blue .index_collections_wrapper .collection_about small {
  font-size: 36px;
  color: #195183 !important;
}
body.size_large.color_blue .header__menu .menu__item {
  font-size: 40px;
}
body.size_large.color_blue .menu__item_active {
  border-bottom: 2px dashed #195183;
}
body.size_large.color_blue #footer {
  color: #195183;
  background: #9dd1ff;
  font-size: 36px;
}
body.size_large.color_blue .footer_social_header {
  color: #195183;
  font-size: 40px;
}
body.size_large.color_blue a.menu__link_footer {
  font-size: 40px;
}
body.size_large.color_blue .footer_header {
  font-size: 40px;
  color: #195183;
}
body.size_large.color_blue .work_time p {
  font-size: 36px;
}
body.size_large.color_blue .footer_column_left {
  max-width: 50%;
}
body.size_large.color_blue .footer_copy {
  border-top-color: #9dd1ff;
  color: #195183;
  font-size: 36px;
}
body.size_large.color_blue .footer_banner img {
  display: none;
}
body.size_large.color_blue .footer_banner a {
  font-size: 40px;
}
body.size_large.color_blue .events-item__image {
  background: #9dd1ff !important;
}
body.size_large.color_blue .event-type a,
body.size_large.color_blue .events_cat a,
body.size_large.color_blue .inner-event-type a,
body.size_large.color_blue .new-type a {
  font-size: 36px;
}
body.size_large.color_blue .events-item__place {
  font-size: 40px;
}
body.size_large.color_blue .events-item__header {
  font-size: 46px;
}
body.size_large.color_blue .events-item__time {
  font-size: 50px;
}
body.size_large.color_blue .event-type a,
body.size_large.color_blue .events_cat a,
body.size_large.color_blue .inner-event-type a,
body.size_large.color_blue .new-type a,
body.size_large.color_blue .events-item__place,
body.size_large.color_blue .events-item__header,
body.size_large.color_blue .events-item__time {
  color: #195183;
}
body.size_large.color_blue .long-term-events {
  background: #9dd1ff;
}
body.size_large.color_blue .long-term-events__title,
body.size_large.color_blue a.long-term-events__item-header {
  color: #195183;
  font-size: 40px;
}
body.size_large.color_blue .event-date__day-of-the-month {
  font-size: 46px;
}
body.size_large.color_blue .event-date__day-of-the-month small {
  font-size: 40px;
}
body.size_large.color_blue .event-date_weekday {
  font-size: 40px;
  color: #195183;
}
body.size_large.color_blue .event-date:before {
  display: none;
}
body.size_large.color_blue .btn__show-more {
  border-color: #195183;
  color: #195183;
  font-size: 46px;
}
body.size_large.color_blue .subscribe-header {
  font-size: 46px;
  color: #195183;
}
body.size_large.color_blue .subscribe-brief {
  color: #195183;
  font-size: 40px;
}
body.size_large.color_blue .subscribe_block .subscribe-btn {
  font-size: 40px;
  width: 240px;
  color: #195183;
  background: transparent;
  border: 1px solid #195183;
  padding: 0;
  line-height: 85px;
}
body.size_large.color_blue .events-by-date * {
  line-height: 1.35 !important;
}
body.size_large.color_blue .events_date_btn,
body.size_large.color_blue .ui-selectmenu-button,
body.size_large.color_blue .ui-state-focus.ui-selectmenu-button,
body.size_large.color_blue .ui-state-hover.ui-selectmenu-button,
body.size_large.color_blue label.checkbox,
body.size_large.color_blue .ui-menu .ui-menu-item {
  font-size: 40px;
  color: #195183;
  background: #9dd1ff;
}
body.size_large.color_blue .catalogue-search_background {
  background: none;
}
body.size_large.color_blue .catalogue-search_title {
  font-size: 50px;
}
body.size_large.color_blue .catalogue-search_title span {
  font-size: 46px;
}
body.size_large.color_blue .catalogue_search_form {
  box-shadow: none;
  height: 65px;
  border: 2px solid #195183;
  font-size: 40px;
}
body.size_large.color_blue .index_search_option,
body.size_large.color_blue .vf_search_option {
  font-size: 40px;
}
body.size_large.color_blue .button__filled {
  border-radius: 0;
  border: 1px solid #195183;
  font-size: 40px;
  background: #9dd1ff;
}
body.size_large.color_blue .catalogue-item {
  border: 2px solid #195183;
  background: none !important;
}
body.size_large.color_blue .catalogue-item_title {
  font-size: 50px;
}
body.size_large.color_blue .catalogue-item_links a {
  font-size: 40px;
}
body.size_large.color_blue a.button {
  font-size: 40px;
}
body.size_large.color_blue .button__label {
  margin: 25px 0 0;
  box-shadow: none;
  border: 1px solid #195183;
  background: none;
}
body.size_large.color_blue .about_block {
  border-color: #195183;
  background: none;
}
body.size_large.color_blue .catalogue_title {
  color: #195183;
  font-size: 36px;
}
body.size_large.color_blue input,
body.size_large.color_blue textarea {
  border: 2px solid #195183 !important;
  background: #9dd1ff !important;
  font-size: 40px !important;
  color: #195183 !important;
  border-radius: 0px !important;
}
body.size_large.color_blue #fixed_menu {
  background: #9dd1ff;
  box-shadow: none;
  border-bottom: 2px solid #195183;
}
body.size_large.color_blue .fixed_menu_wrapper {
  background: none;
}
body.size_large.color_blue .news_block {
  box-shadow: none;
}
body.size_large.color_blue .news_about {
  padding-left: 10px;
  padding-right: 10px;
}
body.size_large.color_blue .news_tag {
  font-size: 36px;
  color: #195183;
}
body.size_large.color_blue .news_title {
  font-size: 40px;
}
body.size_large.color_blue .visitors_block,
body.size_large.color_blue .info_block,
body.size_large.color_blue .digest_block,
body.size_large.color_blue .libfl_block {
  background: none;
  border: 2px solid #195183;
  box-shadow: none;
}
body.size_large.color_blue .libfl_block {
  padding-left: 30px;
  padding-right: 40px;
}
body.size_large.color_blue .libfl_block .libfl_block__part a:before {
  display: none;
}
body.size_large.color_blue .libfl_block .libfl_block__part a {
  font-size: 46px;
}
body.size_large.color_blue .libfl_block .libfl_block__description span {
  color: #195183;
  font-size: 36px;
}
body.size_large.color_blue .info_block .info_block__address {
  color: #195183;
  font-size: 50px;
}
body.size_large.color_blue .info_block .info_block__address span {
  color: #195183;
  font-size: 36px;
}
body.size_large.color_blue .info_block a {
  font-size: 40px;
}
body.size_large.color_blue .info_block a.info_block__map {
  height: auto;
  background: none;
}
body.size_large.color_blue .visitors_block .visitors_block__title {
  color: #195183;
  font-size: 46px;
  margin-bottom: 20px;
}
body.size_large.color_blue .visitors_block a {
  font-size: 40px;
  font-weight: normal;
}
body.size_large.color_blue .digest_block .digest_block__title {
  color: #195183;
  font-size: 46px;
  margin-bottom: 20px;
}
body.size_large.color_blue .digest_block a {
  font-size: 40px;
}
body.size_large.color_blue #popup_wrapper {
  background: rgba(0, 0, 0, 0.8);
}
body.size_large.color_blue .popup_box {
  background: #9dd1ff !important;
  color: #195183 !important;
  font-size: 40px;
}
body.size_large.color_blue .popup_box * {
  color: #195183 !important;
  line-height: 1.35 !important;
}
body.size_large.color_blue .modal_parts .modal_part .modal_title {
  font-size: 46px;
}
body.size_large.color_blue .modal_parts .modal_part .modal_subtitle {
  font-size: 40px;
  height: auto;
}
body.size_large.color_blue .modal_parts ul.modal_list li,
body.size_large.color_blue .modal_parts ol.modal_list li {
  font-size: 40px;
}
body.size_large.color_blue .modal_parts ul.modal_list li:before,
body.size_large.color_blue .modal_parts ol.modal_list li:before {
  color: #195183;
}
body.size_large.color_blue .modal_cta {
  background: none;
  font-size: 50px;
}
body.size_large.color_blue .modal_welcome span {
  font-size: 50px;
}
body.size_large.color_blue .modal_welcome:before {
  display: none;
}
body.size_large.color_blue .modal_welcome a.btn {
  background: none;
  border-radius: 0;
  border: 2px solid #195183;
  font-size: 40px;
  width: auto;
  height: auto;
  line-height: 1 !important;
}
body.size_large.color_blue .modal_rules a.link {
  font-size: 36px;
}
body.size_large.color_blue #popup_reader.popup_box .decoration_left,
body.size_large.color_blue #popup_reader.popup_box .decoration_right {
  display: none;
}
body.size_large.color_blue .event-info {
  width: 600px;
  height: auto;
  background: #9dd1ff;
  color: #195183;
}
body.size_large.color_blue .inner-event-type span {
  font-size: 36px;
}
body.size_large.color_blue .event-info__title,
body.size_large.color_blue .new-info__title {
  font-size: 46px;
}
body.size_large.color_blue .event-info__place {
  font-size: 36px;
}
body.size_large.color_blue .event-info__shedule,
body.size_large.color_blue .new-info__shedule {
  position: relative;
  width: 100%;
  bottom: auto;
  line-height: 1.35;
  margin-top: 20px;
}
body.size_large.color_blue .event-info__date {
  font-size: 36px;
}
body.size_large.color_blue .event-info__time {
  font-size: 46px;
  color: #195183;
}
body.size_large.color_blue .event-info__admission {
  width: 250px;
}
body.size_large.color_blue .event-info__admission * {
  color: #195183;
}
body.size_large.color_blue .event-info__admission--docs {
  font-size: 36px;
  color: #195183;
}
body.size_large.color_blue .block_wrapper * {
  font-size: 40px;
  color: #195183 !important;
}
body.size_large.color_blue .block_wrapper,
body.size_large.color_blue .cutted_block,
body.size_large.color_blue .image_left_block,
body.size_large.color_blue .image_right_block,
body.size_large.color_blue .old_block p,
body.size_large.color_blue .text_block {
  font-size: 40px;
}
body.size_large.color_blue #news .block_wrapper:first-child .text_block {
  font-size: 46px;
}
body.size_large.color_blue .block_wrapper h2 {
  font-size: 50px;
  line-height: 1.4;
}
body.size_large.color_blue .block_wrapper h3 {
  font-size: 46px;
  line-height: 1.4;
}
body.size_large.color_blue .block_wrapper h4 {
  font-size: 40px;
  line-height: 1.4;
}
body.size_large.color_blue .image_block span.set,
body.size_large.color_blue .image_left_block span.set,
body.size_large.color_blue .image_right_block span.set,
body.size_large.color_blue .image_block .image_desc,
body.size_large.color_blue .image_left_block .image_desc,
body.size_large.color_blue .image_right_block .image_desc {
  font-size: 36px;
}
body.size_large.color_blue .button_link,
body.size_large.color_blue .registration_link,
body.size_large.color_blue .registration_link_instruction {
  font-size: 36px;
}
body.size_large.color_blue .button_link .button_with_link,
body.size_large.color_blue .registration_link .button_with_link,
body.size_large.color_blue .registration_link_instruction .button_with_link {
  font-size: 36px;
  background: none;
  border: 2px solid #195183;
  height: auto;
  line-height: 1;
  padding: 20px;
  width: auto;
}
body.size_large.color_blue .registration_link {
  border-color: #195183;
}
body.size_large.color_blue .list_ul ul {
  font-size: 40px;
}
body.size_large.color_blue .list_ol ol {
  font-size: 40px;
}
body.size_large.color_blue .cite_block .cite {
  font-size: 50px;
}
body.size_large.color_blue .cite_block .cite_by {
  font-size: 40px;
}
body.size_large.color_blue .collections_block .collection_about a {
  font-size: 46px;
}
body.size_large.color_blue .collections_block .collection_title {
  font-size: 50px;
}
body.size_large.color_blue .collections_block .collection_info {
  font-size: 36px;
}
body.size_large.color_blue .collections_block .collection_info a {
  font-size: 36px;
}
body.size_large.color_blue .cutted_block .cut_block_button {
  font-size: 36px;
}
body.size_large.color_blue .event_organizer,
body.size_large.color_blue .event_additional_wrapper > span {
  font-size: 36px;
}
body.size_large.color_blue .btn_social {
  font-size: 36px;
  background-color: #9dd1ff !important;
  color: #195183 !important;
  border: 1px solid #195183;
  height: auto;
  width: auto;
  padding: 5px 10px;
}
body.size_large.color_blue .bottom_block {
  border-color: #195183;
}
body.size_large.color_blue .tagline {
  font-size: 40px;
}
body.size_large.color_blue .tagline small {
  font-size: 36px;
  color: #195183;
}
body.size_large.color_blue .page-views {
  font-size: 36px;
  color: #195183;
}
body.size_large.color_blue .article-description__header {
  font-size: 50px;
}
body.size_large.color_blue .facilities_banner a.facilities_banner__description {
  font-size: 46px;
}
body.size_large.color_blue .accordion dt a {
  font-size: 40px;
}
body.size_large.color_blue .accordion dd .dd-row .dd-head {
  color: #195183;
}
body.size_large.color_blue .accordion dd .dd-row .dd-cell,
body.size_large.color_blue .accordion dd .dd-row .dd-head {
  font-size: 36px;
  color: #195183;
}
body.size_large.color_blue .accordion span {
  font-size: 36px;
  color: #195183;
}
body.size_large.color_blue .article-nav-childs {
  border: 2px solid #195183;
  background: none;
  font-size: 36px;
}
body.size_large.color_blue .article-nav-childs__header {
  background: #9dd1ff;
  border-bottom: 2px solid #195183;
  font-size: inherit;
  color: #195183;
}
body.size_large.color_blue .inner_pages_breadcrumbs {
  font-size: 36px;
  color: #195183;
}
body.size_large.color_blue .contact_wrapper {
  background: none;
  border: 2px solid #195183;
}
body.size_large.color_blue .contact_text_wrapper,
body.size_large.color_blue .contact_text_wrapper {
  color: #195183;
  font-size: 40px;
}
body.size_large.color_blue .contact_btn {
  background: #9dd1ff;
  color: #195183 !important;
  border: 1px solid #195183;
  box-shadow: none;
  font-size: 36px;
}
body.size_large.color_blue .note {
  font-size: 36px;
}
body.size_large.color_blue .scheme_header {
  font-size: 46px;
  line-height: 1.4;
}
body.size_large.color_blue .section_switch_block {
  font-size: 40px;
  color: #195183;
  text-decoration: underline;
}
body.size_large.color_blue .section_info_header {
  font-size: 46px;
}
body.size_large.color_blue .section_info_text {
  font-size: 40px;
}
body.size_large.color_blue .section_info_print {
  font-size: 40px;
}
body.size_large.color_blue .event_content,
body.size_large.color_blue .page_content {
  box-shadow: none;
}
body.size_large.color_blue .scheme_switch_block {
  padding: 0;
}
body.size_large.color_blue a.departments__item {
  font-size: 40px;
  height: auto;
  margin-bottom: 20px;
}
body.size_large.color_blue .contact_cell span {
  font-size: 36px !important;
}
body.size_large.color_blue .logo_wrapper {
  background: url('/images/svg/accent-logo-blue.svg') top center no-repeat;
}
body.size_large.color_blue .logo_wrapper img {
  opacity: 0;
  visibility: hidden;
}
body.size_large.color_blue .fall_through_link {
  color: #195183;
}
body.size_large.color_blue .events-filters,
body.size_large.color_blue .events-filters__wrapper {
  background: #9dd1ff;
  border: none;
}
body.size_large.color_blue .icheckbox_minimal {
  border-color: #195183;
}
body.size_large.color_blue .news_block {
  background-color: none;
}
body.size_large.color_blue .new-info {
  background: #9dd1ff;
}
body.size_large.color_blue .parking_note {
  float: none;
  margin-top: 100px;
  text-align: left;
}
body.size_large.color_blue .parking_note:before {
  content: ' ';
  display: block;
  clear: both;
}
body.size_large.color_blue .subway_label {
  background-color: #9dd1ff;
  border: 1px solid #195183;
}
body.size_large.color_blue .event_content,
body.size_large.color_blue .page_content {
  background: none;
}
body.size_large.color_blue .scheme_header,
body.size_large.color_blue .scheme_switch_block,
body.size_large.color_blue ol.sections_switcher li:before {
  color: #195183;
}
body.size_large.color_blue .scheme_switch_block.active {
  background: #195183;
  color: #9dd1ff;
}
body.size_large.color_blue .section_switch_block.active {
  background: #195183;
  color: #9dd1ff;
}
body.size_large.color_blue .section_info.active {
  background: #9dd1ff;
}
body.size_large.color_blue .section_info_print {
  color: #195183;
  text-decoration: underline;
}
body.size_large.color_blue .images_gallery .gallery_image {
  background: #9dd1ff;
}
body.size_large.color_blue .list_ul ul li:before {
  background: #195183;
}
body.size_large.color_blue .catalogue_search_form,
body.size_large.color_blue .vf_search_form {
  border: none;
  background: none;
}
body.size_large.color_blue .work_time a,
body.size_large.color_blue a.work_time {
  border: none;
}
body.size_large.color_blue .long-term-events__item {
  border-color: #195183;
}
body.size_large.color_blue .news_block {
  background: #9dd1ff;
  border: 1px solid #195183;
  overflow: hidden;
}
body.size_large.color_blue .inner_pages_breadcrumbs {
  border-color: #195183;
}
body.size_large.color_blue .contact_table .contact_row .contact_cell {
  border-color: #195183;
}
body.size_large.color_blue .event_organizer:after {
  border-color: #195183;
}
body.size_large.color_blue .vf_search_select {
  box-shadow: none;
  background: #9dd1ff;
  border: 1px solid #195183;
  width: 300px;
}
body.size_large.color_blue .index_search_option,
body.size_large.color_blue .vf_search_option {
  border-color: #195183;
  color: #195183;
}
body.size_large.color_blue .header {
  height: auto;
}
body.size_large.color_blue .index_subscribe_block .subscribe-brief {
  line-height: 1.35;
}
body.size_large.color_blue #footer {
  line-height: 1.35;
}
body.size_large.color_blue .footer_copy_text {
  line-height: 1.35;
}
body.size_large.color_blue .footer_social_wrapper .footer_list {
  line-height: 1.35;
}
body.size_large.color_blue .nouvelle_small_text {
  color: #195183;
}
body.size_large.color_blue .top-banner {
  background-color: #9dd1ff;
  border: 2px solid #195183;
  font-size: 40px;
}
body.size_large.color_blue .top-banner__close {
  background-size: 35px;
}
body.size_large.color_blue .flex-direction-nav a {
  width: 65px;
  height: 65px;
  background-size: 35px;
  background-color: #9dd1ff;
  border: 1px solid #195183;
}
body.size_large.color_blue .flex-nav-prev {
  margin-right: 15px;
}
body.size_large.color_blue .flex-control-paging li a {
  width: 15px;
  height: 15px;
  background-color: transparent;
  border: 1px solid #195183;
}
body.size_large.color_blue .flex-control-paging li a.flex-active {
  background-color: #195183;
}
body.size_large.color_blue .departments_block .departments_block__wrapper .departments_block__part a.departments_block__item img {
  height: auto;
  width: 65px;
}
body.size_large.color_blue a.menu__link_footer img {
  width: 30px !important;
}
body.size_large.color_blue .social-btn_vk {
  background-size: 30px;
}
body.size_large.color_blue .social-btn_fb {
  background-size: 20px;
}
body.size_large.color_blue .social-btn_ig {
  background-size: 22px;
}
body.size_large.color_blue .social-btn_yt {
  background-size: 28px;
}
body.size_large.color_blue .pro_column {
  width: 33%;
}
body.size_large.color_blue .index_news {
  width: 65%;
}
body.size_large.color_blue .index_pro_block .index_pro_content {
  padding-left: 0;
}
body.size_large.color_blue .events-item_background .event-cycle {
  background-color: #9dd1ff;
  font-size: 40px;
}
body.size_large.color_blue .news_about .centenary_tag,
body.size_large.color_blue .event-tag {
  color: #195183;
  background-color: #9dd1ff;
  border: 1px solid #195183;
  font-size: 36px;
}
body.size_large.color_blue .side_social_wrapper > ul > li {
  width: 48px;
  height: 48px;
  border-color: #195183;
}
body.size_large.color_blue .side_social_wrapper > ul > li > a > svg {
  fill: #195183;
  width: 22px;
  height: 22px;
}
body.size_large.color_blue .partners_column a.partners_block {
  border: 2px solid #195183;
  padding: 10px 15px;
}
body.size_large.color_blue .partners_column a.partners_block .partners_block_title {
  font-size: 40px;
  color: #195183;
}
body.size_large.color_blue .test-wrapper {
  grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
}
body.size_large.color_blue .long-term-events {
  width: 100%;
}
body.size_large.color_blue .news_wrapper.listing_block {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
  grid-gap: 23px;
}
body.size_large.color_blue .news_block {
  float: none;
  margin: 0 !important;
  width: auto;
}
body.size_large.color_blue .news_image {
  width: 100%;
  background-position: center;
}
body.size_large.color_blue .news_title {
  line-height: 1.2;
}
